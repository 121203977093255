// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::InventoryController][InventoryController]]
'use strict';

/**
 * @class InventoryController
 * @description provides access to the list of possible bird matches
 * @memberOf Controllers
 */
module.exports = function ($state, inventoryService) {
  var vm = this;

  vm.state = $state.current.name;
  vm.bestMatch = inventoryService.bestMatch;
  vm.moveToImage = inventoryService.moveToImage;
};
// InventoryController ends here
