// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::idStepsDirective.js][idStepsDirective.js]]
'use strict';

module.exports = function () {
  var directive = {
      restrict: 'EAC',
      controller: 'IdStepsController',
      controllerAs: 'id',
      templateUrl: 'app/modules/components/identify/idSteps/idSteps.directive.html',
  };

  return directive;
};
// idStepsDirective.js ends here
