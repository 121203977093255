'use strict';

module.exports = digestRun;


function digestRun() {

  return filter;

  function filter(text) {
  
    return text ? String(text).replace(/<[^>]+>/gm, '') : '';
      
  }

}
