// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::detail.run][detail.run]]
'use strict';

var angular = require('angular');
var detailRun = require('./detailRun');
var runDepends = [];

runDepends.push('routerHelper');
angular
  .module('app.identify.bestMatch.detail')
  .run(detailRun);

detailRun.$inject = runDepends;
// detail.run ends here
