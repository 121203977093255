// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::congrats.controller][congrats.controller]]
'use strict';
var angular;
var CongratsController;
var controllerDepends;

angular = require('angular');
CongratsController = require('./CongratsController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('$stateParams');
controllerDepends.push('$state');
controllerDepends.push('SIGNUP_STATE');
angular
  .module('app.identify.inspect.congrats')
  .controller('CongratsController', CongratsController);

CongratsController.$inject = controllerDepends;
// congrats.controller ends here
