// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::PlaceSubheadingController][PlaceSubheadingController]]
'use strict';

/**
 * @class PlaceSubheadingController
 * @description bind the collected observation data to a separate view
 * @memberOf Controllers
 */
module.exports = PlaceSubheadingController;

function PlaceSubheadingController(assistantService, PLACE_STATE, WHEN_STATE) {
  var vm;

  vm = this;
  vm.place = assistantService.step(PLACE_STATE).display;
  vm.date = assistantService.step(WHEN_STATE).display;
  vm.hasInputData = !!(vm.place || vm.date);
}
// PlaceSubheadingController ends here
