// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::DetailsSubheadingController][DetailsSubheadingController]]
'use strict';

/**
 * @class DetailsSubheadingController
 * @description bind the collected observation data to a separate view
 * @memberOf Controllers
 */
module.exports = DetailsSubheadingController;

function DetailsSubheadingController(assistantService, $state, $stateParams) {
  var vm;

  vm = this;
  vm.state = $state.current.name;
  vm.plumage = $stateParams.plumage;
  vm.commonName = assistantService.result[$stateParams.plumage].commonName;
}
// DetailsSubheadingController ends here
