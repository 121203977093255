'use strict';

var angular = require('angular');
var appRun = require('./appRun');
var runDepends = [];

runDepends.push('routerHelper');
runDepends.push('sessionService');
angular
  .module('app')
  .run(appRun);

appRun.$inject = runDepends;
