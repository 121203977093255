'use strict';
var angular;
var guideUrlFilter;

guideUrlFilter = require('./guideUrlFilter');
angular = require('angular');
angular
  .module('app.identify.inspect.congrats')
  .filter('guideUrl', guideUrlFilter);

