'use strict';
var angular;
var inspectConfig;
var configDepends;

angular = require('angular');
inspectConfig = require('./inspectConfig');
configDepends = [];
configDepends.push('slickCarouselConfig');
angular
  .module('app.identify.inspect')
  .config(inspectConfig);

inspectConfig.$inject = configDepends;

