'use strict';
var angular;
var SignupController;
var controllerDepends;

angular = require('angular');
SignupController = require('./SignupController');
controllerDepends = [];
controllerDepends.push('appServerService');
controllerDepends.push('sessionService');
controllerDepends.push('THANKS_STATE');
controllerDepends.push('SIGNUP_STATE');
controllerDepends.push('EXPIRED_STATE');
controllerDepends.push('SPINNER_STATE');
controllerDepends.push('HOME_STATE');
angular
  .module('app.dashboard.signup')
  .controller('SignupController', SignupController);

SignupController.$inject = controllerDepends;

