// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::behaviorSubheading.controller][behaviorSubheading.controller]]
'use strict';
var angular;
var BehaviorSubheadingController;
var controllerDepends;

angular = require('angular');
BehaviorSubheadingController = require('./BehaviorSubheadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('PLACE_STATE');
controllerDepends.push('WHEN_STATE');
angular
  .module('app.identify.behavior')
  .controller('BehaviorSubheadingController', BehaviorSubheadingController);

BehaviorSubheadingController.$inject = controllerDepends;
// behaviorSubheading.controller ends here
