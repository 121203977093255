'use strict';

var modDepends = [];
/** @module angular */
var angular = require('angular');

// features
modDepends.push('app.dashboard');
//modDepends.push('app.browse');
modDepends.push('app.identify');
//modDepends.push('app.truncate');
//modDepends.push('app.escapeApostrophe');
// angular core
modDepends.push('ngMessages');
modDepends.push('ngResource');
// 3rd-party
modDepends.push('ui.router');
modDepends.push('ngAutocomplete');
modDepends.push('ngStorage');
// cross-app
modDepends.push('blocks');
modDepends.push('blocks.routerHelper');
// modDepends.push('blocks.user');
/**
 * @module app
 * @description the Merlin Web Client
 * @requires {@link module:angular-messages}
 * @requires {@link module:app/dashboard}
 */
angular
  .module('app', modDepends);

// load the app dependencies
require('./appModule');
