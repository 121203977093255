'use strict';
var angular;
var DetailsSubheadingController;
var controllerDepends;

angular = require('angular');
DetailsSubheadingController = require('./DetailsSubheadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('$state');
controllerDepends.push('$stateParams');
angular
  .module('app.identify.inspect.details')
  .controller('DetailsSubheadingController', DetailsSubheadingController);

DetailsSubheadingController.$inject = controllerDepends;

