// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::behavior.index][behavior.index]]
require('../../../shared/blocks/routerHelper'); // helper functions for configuring the ui-router state
require('../../../shared/blocks/assistant');    // add the location to the series of bird id questions
require('../../../shared/blocks/appServer');    // collection of API endpoints for the app server
require('../inspect');
require('../match');
require('../flight');
require('../spinner');
require('../../dashboard/signup');
require('../../dashboard/expired');

require('./behavior.module');
require('./behavior.constant');
require('./behavior.run');
require('./behavior.controller');
require('./behaviorHeading.controller');
require('./behaviorSubHeading.controller');
require('./behavior.decorator');
// behavior.index ends here
