// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::ColorsHeadingController][ColorsHeadingController]]
'use strict';

/**
 * @class ColorsHeadingController
 * @description bind the colors question title to a separate view
 * @memberOf Controllers
 */
module.exports = ColorsHeadingController;

function ColorsHeadingController(assistantService, COLORS_STATE) {
  var vm;

  vm = this;
  vm.totalSteps = assistantService.totalSteps();
  vm.currentStep = 1 + assistantService.index(COLORS_STATE);
  vm.prior = assistantService.step(COLORS_STATE).prior.value;
}
// ColorsHeadingController ends here
