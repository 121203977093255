// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::inspectSubheading.controller][inspectSubheading.controller]]
'use strict';
var angular;
var InspectSubheadingController;
var controllerDepends;

angular = require('angular');
InspectSubheadingController = require('./InspectSubheadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('PLACE_STATE');
controllerDepends.push('WHEN_STATE');
angular
  .module('app.identify.inspect')
  .controller('InspectSubheadingController', InspectSubheadingController);

InspectSubheadingController.$inject = controllerDepends;
// inspectSubheading.controller ends here
