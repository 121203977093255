// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::BestMatchController][BestMatchController]]
'use strict';

/**
 * @class BestMatchController
 * @description bind common name and navigation controls in the view of best bird matches
 * @memberOf Controllers
 */
module.exports = function ($state, $stateParams, $window) {
  var vm = this;

  vm.commonName = $stateParams.commonName;
  vm.state = $state;
  vm.startOver = startOver;

  function startOver() {
    $state.go('^.place');
    $window.location.reload();
  }

};
// BestMatchController ends here
