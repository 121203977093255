'use strict';

var angular;
var imageByLineDirective;
var imageByLineDirectiveDepends;

angular = require('angular');
imageByLineDirective = require('./imageByLineDirective');
imageByLineDirectiveDepends = ['$filter'];
angular
  .module('blocks.imageCarousel')
  .directive('imageByLine', imageByLineDirective);

imageByLineDirective.$inject = imageByLineDirectiveDepends;
