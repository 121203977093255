// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::spinner.index][spinner.index]]
'use strict';

require('../../../shared/blocks/routerHelper'); // helper functions for configuring the ui-router state

require('./spinner.module');
require('./spinner.constant');                    // the spinner state name
require('./spinner.run');                         // configure the spinner state and views
require('./spinner.controller');                  // bind the spinner question model to the main view
require('./spinnerHeading.controller');            // bind the spinner title to the header view
require('./spinnerSubheading.controller');       // bind the observed spinner to the observation view
// spinner.index ends here
