'use strict';

var angular;
var modalProvider;
var modalProviderDepends;

angular = require('angular');
modalProvider = require('./modalProvider');
modalProviderDepends = [];
angular
  .module('blocks.zf.modal')
  .provider('$modal', modalProvider);

modalProvider.$inject = modalProviderDepends;

