// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::routerHelper.module][routerHelper.module]]
'use strict';

var angular = require('angular');
var modDepends = [];

modDepends.push('ui.router');
/**
 * @module blocks/routerHelper
 * @description bundle a module's ui-router state configuration inside the module's run block
 * @requires {@link module:angular-ui-router}
 * @see module:blocks
 */
angular
  .module('blocks.routerHelper', modDepends);
// routerHelper.module ends here
