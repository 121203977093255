// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::splitToLength.filter.js][splitToLength.filter.js]]
'use strict';

module.exports = function () {
  function sigDig(number, digits) {
    return number.toFixed(digits);
  }

  return function(number, digits) {
    return sigDig(number, digits);
  };
};
// splitToLength.filter.js ends here
