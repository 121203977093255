'use strict';

module.exports = imageCarouselServiceDecorator;

function imageCarouselServiceDecorator($delegate) {
  var viewport1023;
  var settings1023;
  var viewport640;
  var settings640;

  // make the image carousel responsive:
  // 1023px screen width
  settings1023 = {};
  settings1023.arrows = true;
  viewport1023 = {};
  viewport1023.breakpoint = 1023;
  viewport1023.settings = settings1023;

  // 640px screen width
  settings640 = {};
  settings640.infinite = false;
  settings640.arrows = true;
  settings640.centerMode = true;
  settings640.centerPadding = '30px';
  settings640.slidesToShow = 1;
  settings640.initialSlide = 0;
  viewport640 = {};
  viewport640.breakpoint = 640;
  viewport640.settings = settings640;

  // configure the image carousel
  $delegate.slickConfig.lazyLoad = 'ondemand';
  $delegate.slickConfig.infinite = false;
  $delegate.slickConfig.arrows = true;
  $delegate.slickConfig.dots = false;
  $delegate.slickConfig.centerMode = true;
  $delegate.slickConfig.slidesToShow = 3;
  $delegate.slickConfig.initialSlide = 0;
  $delegate.slickConfig.enabled = true;
  $delegate.slickConfig.method = {};
  $delegate.slickConfig.responsive = [];
  $delegate.slickConfig.responsive.push(viewport1023);
  $delegate.slickConfig.responsive.push(viewport640);

  return $delegate;
}

