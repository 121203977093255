'use strict';

var angular = require('angular');
var appConfig = require('./appConfig');
var configDepends = [];

configDepends.push('$locationProvider');
configDepends.push('$httpProvider');
angular
  .module('app')
  .config(appConfig);

appConfig.$inject = configDepends;

