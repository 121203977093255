'use strict';

var angular;
var headingDirective;

angular = require('angular');
headingDirective = require('./headingDirective');
angular
  .module('app.identify')
  .directive('heading', headingDirective);
