// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::when.run][when.run]]
'use strict';
var angular;
var whenRun;
var runDepends;

angular = require('angular');
whenRun = require('./whenRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('WHEN_STATE');
angular
  .module('app.identify.when')
  .run(whenRun);

whenRun.$inject = runDepends;
// when.run ends here
