module.exports = slidingDrawerService;

function slidingDrawerService() {
  this.isPaused = false;
  this.currentTrack = null;
  this.play = play;
  this.pause = pause;
    
  function play(track) {
    this.currentTrack = track;
    this.isPaused = false;
  }

  function pause() {
    this.isPaused = !this.isPaused;        
  }
    
}
