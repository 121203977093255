// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::PlaceHeadingController][PlaceHeadingController]]
'use strict';

/**
 * @class PlaceHeadingController
 * @description bind the place question title to a separate view
 * @memberOf Controllers
 */
module.exports = PlaceHeadingController;

function PlaceHeadingController(assistantService, PLACE_STATE, HOME_STATE) {
  var vm;

  vm = this;
  vm.totalSteps = assistantService.totalSteps();
  vm.currentStep = 1 + assistantService.index(PLACE_STATE);
  if (!assistantService.step(PLACE_STATE).prior) vm.prior = HOME_STATE;
    else vm.prior = assistantService.step(PLACE_STATE).prior.value;
}
// PlaceHeadingController ends here
