'use strict';

module.exports = directive;

function directive($filter) {
  var directive;

  directive = {};
  directive.restrict = 'EA';
  directive.scope = {};
  directive.scope.title = '@title';  
  directive.scope.artist = '@artist';
  directive.scope.location = '@location';
  directive.scope.country = '@country';        
  directive.scope.when = '@when';
  directive.templateUrl = 'app/modules/shared/blocks/imageCarousel/imageByLine.directive.html';    
  directive.link = postLink;
    
  return directive;

  function postLink(scope, elem, attr) {
    // format the image date as MONTH DD, YYYY    
    scope.time = $filter('date')(new Date(scope.when), 'MMMM dd, yyyy');
  }
    
}
      
