// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::start.index][start.index]]
'use strict';
var angular = require('angular');
var navMenuDirective = require('./navMenu.directive');
var NavMenuController = require('./navMenu.controller');

angular
  .module('app')
  .directive('mwNavMenu', navMenuDirective)
  .controller('NavMenuController', NavMenuController);

navMenuDirective.$inject = ['$timeout'];
NavMenuController.$inject = ['$state'];
// start.index ends here
