// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::home.controller][home.controller]]
'use strict';
var angular;
var HomeController;
var controllerDepends;

angular = require('angular');
HomeController = require('./HomeController');
controllerDepends = [];
controllerDepends.push('PLACE_STATE');
angular
  .module('app.dashboard.home')
  .controller('HomeController', HomeController);

HomeController.$inject = controllerDepends;
// home.controller ends here
