// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::shared][shared]]
'use strict';

//require('./core');
//require('./birdParams');
//require('./match');
require('./blocks');
require('./navMenu');
require('./sliderMenu');
require('./sliderItem');
require('./animation');
require('./slide');
require('./sigDig');
require('./sizes');
require('./splitToLength');
require('./capitalize');
require('./join');
require('./truncate');
require('./escapeApostrophe');
// shared ends here
