'use strict';

module.exports = locationLookup;

function locationLookup() {
  var directive;
  var map;

  map = {};
  map.select = '&';
  directive = {};
  directive.restrict = 'E';
  directive.templateUrl = 'app/modules/components/identify/place/locator/locationLookup.html';
  directive.scope = map;
  directive.link = linkLookup;

  return directive;

  function linkLookup(scope, iElement, iAttrs) {
    var elem;
    var googlePlacesService;

    //Generate a DOM elment for Google Places Service
    elem = document.createElement('div');

    // Setup Google Places Service
    googlePlacesService = new google.maps.places.PlacesService(iElement[0].appendChild(elem));  // jshint ignore:line

    scope.limit = scope.$eval(iAttrs.limit) || 15;
    scope.results = [];
    scope.pickLocation = pickLocation;

    function pickLocation(location) {
      var locationId;

      locationId = {};
      locationId.placeId = location.place_id;

      // Update the view with details for the selected location.
      googlePlacesService.getDetails(locationId, updateDetails);

      function updateDetails(place) {
        scope.$apply(getDetails);

        function getDetails() {
          var headingDisplay;
          headingDisplay = location.terms.reduce(queryParams, '');

          var locData = {
            name: headingDisplay,
            description: location.description,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng()
          };

          /**************************************************************
            NOTE: scope.select() returns the name of the function passed
                  as an attribute, so scope.select()(locData) calls the
                  function with locData passed in as an argument.
          *************************************************************/
          scope.select()(locData);

          function queryParams(memo, param, index, query) {
            if (index < query.length - 1) memo += param.value + ' ';
            return memo;
          }

        }

      }

    }

  }

}
