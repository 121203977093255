// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::animation.directive.js][animation.directive.js]]
'use strict';

module.exports = function () {
  var directive = {
      restrict: "CEA",
      templateUrl: 'app/modules/shared/animation/animation.directive.html',
  };

  return directive;
};
// animation.directive.js ends here
