// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::splitToLength.filter.js][splitToLength.filter.js]]
'use strict';

module.exports = function () {
  function splitToLength(collection, length) {
    var nestedArray = [];
    var collectionLength, isArray, i, j;

    if (typeof collection === 'object' && collection !== null) {
      isArray = Array.isArray(collection);

      if (isArray) collectionLength = collection.length;
        else collectionLength = Object.keys(collection).length;

      for (i = 0, j = -1; i < collectionLength; i++) {
        if (i % length === 0) {
          j++;
          nestedArray[j] = isArray ? [] : {};
        }

        if (isArray) nestedArray[j].push(collection[i]);
          else nestedArray[j][Object.keys(collection)[i]] = collection[Object.keys(collection)[i]];
      }

      return nestedArray;
    } else {
      return collection;
    }
  }

  return function(collection, length) {
    return splitToLength(collection, length);
  };
};
// splitToLength.filter.js ends here
