'use strict';
var states;
var home;

states = [];
home = {};
home.config = {};
home.config.url = '^/home';

// configure three multiple views for the question, header, and collected data
home.config.views = {};

// configure the view for the home question
home.config.views['@app.dashboard'] = {};
home.config.views['@app.dashboard'].templateUrl = './app/modules/components/dashboard/' +
                                      'home/home.view.html';
home.config.views['@app.dashboard'].controller = 'HomeController as home';

// configure the header title during the home question
home.config.views['heading@app.dashboard'] = {};
home.config.views['heading@app.dashboard'].templateUrl = './app/modules/components/dashboard/' +
                                                  'home/homeHeading.view.html';
home.config.views['heading@app.dashboard'].controller = 'HomeHeadingController as heading';
module.exports = homeRun;

function homeRun(routerHelper, HOME_STATE, $state, assistantService, sessionService) {
  // restore the question parameteres to default values if navigating to home
  // from the inspect state or any of its children
  home.config.onEnter = checkForReset;
  home.state = HOME_STATE;
  states.push(home);

  // add the home state and make it the target when an invalid url is entered
  // routerHelper.configureStates(states, '/home');
  // routerHelper.configureStates(states, stayPut);
  routerHelper.configureStates(states);

  // rule for state to navigate to when invalis url entered
  // function stayPut() {

  //     return $state.current.url;
  // }
  // stayPut.$inject = ['$injector'];

  // Restore the assistant questions to default values for a new bird search
  // if the user navigated to HOME from a search results page
  function checkForReset() {
    // reset the assistant question inputs
    assistantService
        .reset();

    // route to signup page if trial limit exceeded
    if ($state.current.name.includes('app.identify.inspect')) {
      sessionService
        .selectLandingPage('app.dashboard.home',
                           'app.dashboard.signup',
                           'app.dashboard.thanks');
    }

  }

}
