'use strict';

module.exports = digestRun;


function digestRun() {

  return filter;

  function filter(word, position) {
  
    if (typeof word !== 'string') return word;
    position = position - 1 || 0;

    return word.split('').reduce(capitalize, '');

    function capitalize(memo, letter, index) {
      if (index === position) memo = memo + letter.toUpperCase();
        else memo = memo + letter;

      return memo;
    }
      
  }

}
