'use strict';

/**
 * @function colors-birdParamsServiceDecorator
 * @description add 'colors' state data for use in the steps display and navigator
 * @memberOf Decorators
 */
module.exports = ColorsController;

function ColorsController($state, $filter, assistantService, COLORS_STATE, capitalizeFilter) {
  var vm;
  var next;

  vm = this;
  vm.prompt = assistantService.step(COLORS_STATE).prompt;
  vm.controls = assistantService.step(COLORS_STATE).controls;
  vm.submitColors = submitColors;
  next = assistantService.step(COLORS_STATE).next.value;

  function submitColors() {
    var selectedColors;
    var selectedColorsDisplay;
    var selected;

    // go to the next question if the min number of colors are
    // selected
    selected = vm.controls.reduce(totalSelected, 0);
    if (selected > 0) {
      selectedColors = vm.controls.reduce(collectColors, []);
      selectedColorsDisplay = vm.controls.reduce(collectColorsDisplay, []).join(', ');        
      assistantService.step(COLORS_STATE).params.colors = selectedColors;
      assistantService.step(COLORS_STATE).display = selectedColorsDisplay;
      $state.go(next);
    }

    function collectColorsDisplay(memo, control) {
      if (control.selected) memo.push(capitalizeFilter(control.value, 1));

      return memo;
    }

    function collectColors(memo, control) {
      if (control.selected) memo.push(control.value);

      return memo;
    }

    function totalSelected(memo, control) {
      if (control.selected) memo = memo + 1;

      return memo;
    }

  }

}

