'use strict';

require('./locator');                           // directives used in view template
require('../../../shared/blocks/routerHelper'); // helper functions for configuring the ui-router state
require('../../../shared/blocks/assistant');    // add the location to the series of bird id questions
require('../../dashboard/home');

require('./place.module');
require('./place.constant');                    // the place state name
require('./place.run');                         // configure the place state and views
require('./place.controller');                  // bind the place question model to the main view
require('./placeHeading.controller');            // bind the place title to the header view
require('./placeSubheading.controller');       // bind the observed place to the observation view
require('./place.decorator');                   // add 'place' data to the observation model
