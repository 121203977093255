// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::navMenu.controller.js][navMenu.controller.js]]
'use strict';

module.exports = function ($state) {
  var vm = this;

  init();

  function init () {
    vm.navStates = $state.get();

    vm.rows = vm.navStates.reduce(function (memo, state) {
      if (state.data) {
        if (state.data.group === 'primary') memo.upper.push(state);
        if (state.data.group === 'secondary') memo.lower.push(state);
      }
      return memo;
    }, {upper: [], lower: []});
  }

};
// navMenu.controller.js ends here
