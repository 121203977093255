'use strict';

module.exports = function($locationProvider, $httpProvider) {
  // get rid of hash # from the address bar
  $locationProvider.html5Mode(true);

  // add bang ! to hash # for SEO
  $locationProvider.hashPrefix('!');

  $httpProvider.defaults.useXDomain = true;

  delete $httpProvider.defaults.headers.common['X-Requested-With'];
};
