// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::birdParamsService][birdParamsService]]
'use strict';

/**
 * @class birdParamsService
 * @description ask where a bird to be identified was observed
 * @memberOf Services
 * @requires {@link module:inventoryService}
 * @requires {@link module:matchService}
 */
module.exports = function ($state, inventoryService, matchService) {
  var vm = this;

  vm.location = '';
  vm.lat = "";
  vm.lng = "";
  vm.time = '';
  vm.size = '';
  vm.colors = [];
  vm.behavior = '';
  vm.currentStep = 1;
  // create collections for input controls
  vm.range = [];
  vm.spectrum = [];
  vm.activity = [];
  // create multiple buttons with a single label that displays the current selection
  vm.range.push(new SingleLabelRadio('size1', 'sparrow-sized or smaller', 'bug icon'));
  vm.range.push(new SingleLabelRadio('size2', 'between sparrow and robin', 'bug icon'));
  vm.range.push(new SingleLabelRadio('size3', 'robin size', 'bug icon'));
  vm.range.push(new SingleLabelRadio('size4', 'between robin and crow', 'bug icon'));
  vm.range.push(new SingleLabelRadio('size5', 'crow size', 'bug icon'));
  vm.range.push(new SingleLabelRadio('size6', 'between crow and goose', 'bug icon'));
  vm.range.push(new SingleLabelRadio('size7', 'goose-sized or larger', 'bug icon'));
  // create a grid of buttons of which at most three may be selected
  vm.spectrum.push(new MultiSelectButton('black', 'Black', 'black'));
  vm.spectrum.push(new MultiSelectButton('gray', 'Gray', 'grey'));
  vm.spectrum.push(new MultiSelectButton('white', 'White', 'white'));
  vm.spectrum.push(new MultiSelectButton('brown', 'Buff/Brown', 'brown'));
  vm.spectrum.push(new MultiSelectButton('red', 'Red/Rufous', 'red'));
  vm.spectrum.push(new MultiSelectButton('yellow', 'Yellow', 'yellow'));
  vm.spectrum.push(new MultiSelectButton('green', 'Olive/Green', 'olive'));
  vm.spectrum.push(new MultiSelectButton('blue', 'Blue', 'blue'));
  vm.spectrum.push(new MultiSelectButton('orange', 'Orange', 'orange'));
  // creat radio buttons with a label next to each button
  vm.activity.push(new MultiLabelRadio('feeder', 'eating at a feeder'));
  vm.activity.push(new MultiLabelRadio('water', 'swimming or wading'));
  vm.activity.push(new MultiLabelRadio('ground', 'on the ground'));
  vm.activity.push(new MultiLabelRadio('trees', 'in trees or bushes'));
  vm.activity.push(new MultiLabelRadio('fence', 'on a fence or wire'));
  vm.activity.push(new MultiLabelRadio('flying', 'soaring or flying'));
  // collect all states which are part of the id steps
  vm.steps = $state.get().reduce(function (memo, state) {
    if (state.data) {
      if (state.data.group === 'idStep') memo.push(state);
    }
    return memo;
  }, []);

  /**
   * @function activateNext
   * @description ask where a bird to be identified was observed
   * @memberOf birdParamsService
   */
  vm.activateNext = function () {
    if (vm.currentStep < 5) vm.currentStep += 1;
      else vm.currentStep = 1;
  };

  /**
   * @function findMatch
   * @description ask where a bird to be identified was observed
   * @param {Object} inventoryService - a reference to the inventoryService object
   * @memberOf birdParamsService
   * @see module:inventory
   */
  vm.findMatch = function (inventoryService) {
    var matchParams;

    matchParams = {};
    matchParams.lat = vm.lat;
    matchParams.lng = vm.lng;
    matchParams.date = vm.time;
    matchParams.size = vm.size;
    matchParams.colors = vm.colors.join();
    matchParams.behavior = vm.behavior;

    matchService.get(matchParams, matchCallback);

    $state.go('app.identify.bestMatch.detail');

    function matchCallback(matches) {
      inventoryService.findMatch(matches);
      vm.match = matches;
    }

  };

  /**
   * @function submitParam
   * @description submit observed bird property
   * @param {String} param - 'size', colors', 'behavior'
   * @param {String} value - 'value'
   * @memberOf birdParamsService
   */
  vm.submitParam = function (param, value) {
    // Activate the next step if this is the first submission for this property and not an update.
    if ( !vm[param] || (Array.isArray(vm[param]) && vm[param].length === 0) ) vm.activateNext();

    // Write the value of the parameter.
    if (param === 'colors') {
      vm.colors = [];

      Object.keys(value).forEach(function(color, index) {
        vm.colors[index] = color;
      });
    } else {
      vm[param] = value;
    }

    // Navigate to the state for the current incomplete step.
    if (param === 'behavior') vm.findMatch(inventoryService);
      else $state.go('^.' + vm.steps[vm.currentStep - 1].name);
  };

  function SingleLabelRadio(urlParam, label, icon) {
    this.urlParam = urlParam;
    this.description = label;
    this.icon = icon;
  }

  function MultiSelectButton(urlParam, label, cssName) {
    this.urlParam = urlParam;
    this.description = label;
    this.uiName = cssName;
  }

  function MultiLabelRadio(urlParam, label) {
    this.urlParam = urlParam;
    this.description = label;
  }

};
// birdParamsService ends here
