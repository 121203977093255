// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::assistant.service][assistant.service]]
'use strict';
var angular;
var assistantService;
var serviceDepends;

angular = require('angular');
assistantService = require('./assistantService');
serviceDepends = [];
serviceDepends.push('dataStructuresService');
angular
  .module('blocks.assistant')
  .service('assistantService', assistantService);

assistantService.$inject = serviceDepends;
// assistant.service ends here
