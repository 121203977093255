// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::size.controller][size.controller]]
'use strict';
var angular;
var SizeController;
var controllerDepends;

angular = require('angular');
SizeController = require('./SizeController');
controllerDepends = [];
controllerDepends.push('$state');
controllerDepends.push('assistantService');
controllerDepends.push('SIZE_STATE');
angular
  .module('app.identify.size')
  .controller('SizeController', SizeController);

SizeController.$inject = controllerDepends;
// size.controller ends here
