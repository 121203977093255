// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::assistant.decorator][assistant.decorator]]
'use strict';

var angular;
var dataStructuresServiceDecorator;
var decoratorDepends;

angular = require('angular');
dataStructuresServiceDecorator = require('./dataStructuresServiceDecorator');
decoratorDepends = [];
decoratorDepends.push('$delegate');
angular
  .module('blocks.assistant')
  .decorator('dataStructuresService', dataStructuresServiceDecorator);

dataStructuresServiceDecorator.$inject = decoratorDepends;
// assistant.decorator ends here
