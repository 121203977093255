'use strict';

//require('angular-touch');
require('./angular-foundation');
// require('angular-foundation');
require('./birdParams');
require('./idSteps');
require('./inventory');
require('./match');

require('../dashboard');
require('../../shared/blocks/centering');
// require('../../shared/blocks/zfTransition');
// require('../../shared/blocks/zfModal');
// require('../../shared/blocks/modal');
/* The order of the id steps is fixed by the order of loading each step.
   The first step is the first loaded. */
require('./place');
require('./when');
require('./size');
require('./colors');
require('./behavior');
// The last step is loaded last.
require('./flight');
require('./inspect');
require('./spinner');
require('./bestMatch');

require('./identify.module');
require('./identify.run');
require('./identify.heading.directive');
require('./identify.subheading.directive');
require('./identify.controller');
