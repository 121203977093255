'use strict';
var angular;
var SorryHeadingController;
var controllerDepends;

angular = require('angular');
SorryHeadingController = require('./SorryHeadingController');
controllerDepends = [];
controllerDepends.push('SORRY_STATE');
angular
  .module('app.dashboard.sorry')
  .controller('SorryHeadingController', SorryHeadingController);

SorryHeadingController.$inject = controllerDepends;
