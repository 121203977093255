'use strict';

module.exports = interchangeToolsSingleton;

function interchangeToolsSingleton($window, matchMedia, interchangeQueries) {
    /**
     * @ngdoc method
     * @name interchangeTools#parseAttribute
     * @methodOf mm.foundation.interchange.interchangeTools
     * @description
     *
     * Attribute parser to transform an `interchange` attribute
     * value to an object with media query (name or query) as key,
     * and file to use as value.
     *
     * ```
     * {
     *   small: 'bridge-500.jpg',
     *   large: 'bridge-1200.jpg'
     * }
     * ```
     *
     * @param {string} value Interchange query string
     * @returns {object} Attribute parsed
     */
    var parseAttribute = function (value) {
      var raw = value.split(/\[(.*?)\]/),
        i = raw.length,
        breaker = /^(.+)\,\ \((.+)\)$/,
        breaked,
        output = {};

      while (i--) {
        if (raw[i].replace(/[\W\d]+/, '').length > 4) {
          breaked = breaker.exec(raw[i]);
          if (!!breaked && breaked.length === 3) {
            output[breaked[2]] = breaked[1];
          }
        }
      }
      return output;
    };

    /**
     * @ngdoc method
     * @name interchangeTools#findCurrentMediaFile
     * @methodOf mm.foundation.interchange.interchangeTools
     * @description
     *
     * Find the current item to display from a file list
     * (object returned by `parseAttribute`) and the
     * current page dimensions.
     *
     * ```
     * {
     *   small: 'bridge-500.jpg',
     *   large: 'bridge-1200.jpg'
     * }
     * ```
     *
     * @param {object} files Parsed version of `interchange` attribute
     * @returns {string} File to display (or `undefined`)
     */
    var findCurrentMediaFile = function (files) {
      var file, media, match;
      for (file in files) {
        media = interchangeQueries[file] || file;
        match = matchMedia(media);
        if (match.matches) {
          return files[file];
        }
      }
      return;
    };

    return {
      parseAttribute: parseAttribute,
      findCurrentMediaFile: findCurrentMediaFile
    };

}
