// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::WhenHeadingController][WhenHeadingController]]
'use strict';

/**
 * @class WhenHeadingController
 * @description bind the when question title to a separate view
 * @memberOf Controllers
 */
module.exports = WhenHeadingController;

function WhenHeadingController(assistantService, WHEN_STATE) {
  var vm;

  vm = this;
  vm.totalSteps = assistantService.totalSteps();
  vm.currentStep = 1 + assistantService.index(WHEN_STATE);
  vm.prior = assistantService.step(WHEN_STATE).prior.value;
}
// WhenHeadingController ends here
