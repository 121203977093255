'use strict';

module.exports = backDropDirective;

function backDropDirective($modalStack, $timeout) {
  var directive;

  directive = {};
  directive.restrict = 'EA';
  directive.replace = true;
  directive.template = '<div class="reveal-modal-bg fade" ng-class="{in: animate}" ng-click="close($event)" style="display: block"></div>';  
  directive.link = postLink;
    
  return directive;

  function postLink(scope, element, attrs) {
    scope.animate = false;

    //trigger CSS transitions
    $timeout(function () {
      scope.animate = true;
    });

    scope.close = function (evt) {
      var modal = $modalStack.getTop();
      if (modal && modal.value.backdrop && modal.value.backdrop != 'static' && (evt.target === evt.currentTarget)) {
        evt.preventDefault();
        evt.stopPropagation();
        $modalStack.dismiss(modal.key, 'backdrop click');
      }
    };

  }
      
}
      
