'use strict';

module.exports = inspectConfig;

function inspectConfig(slickCarouselConfig) {
  var event = {};
  event.beforeChange = beforeChange;
  event.afterChange = afterChange;
  event.breakpoint = breakpoint;
  event.destroy = destroy;
  event.edge = edge;
  event.reInit = reInit;
  event.init = init;
  event.setPosition = setPosition;
  event.swipe = swipe;

  // globally configure all slick carousels
  slickCarouselConfig.enabled = true;
  slickCarouselConfig.method = {};
  slickCarouselConfig.accessibility = true;
  slickCarouselConfig.adaptiveHeight = true;
  slickCarouselConfig.autoplay = false;
  slickCarouselConfig.arrows = true;
  slickCarouselConfig.centerMode = true;
  slickCarouselConfig.centerPadding = '50px';
  slickCarouselConfig.draggable = true;
  slickCarouselConfig.easing = 'linear';
  slickCarouselConfig.infinite = false;
  slickCarouselConfig.lazyLoad = 'ondemand';
  slickCarouselConfig.mobileFirst = true;
  slickCarouselConfig.respondTo = 'min';
  slickCarouselConfig.slidesPerRow = 3;
  slickCarouselConfig.slidesToShow = 1;
  slickCarouselConfig.slidesToScroll = 1;
  slickCarouselConfig.swipe = true;
  slickCarouselConfig.swipeToSlide = false;
  slickCarouselConfig.touchMove = true;
  slickCarouselConfig.useCSS = true;
  slickCarouselConfig.useTransform = true;
  slickCarouselConfig.variableWidth = false;
  slickCarouselConfig.vertical = false;
  slickCarouselConfig.verticalSwiping = false;
  slickCarouselConfig.waitForAnimate = true;
  slickCarouselConfig.zIndex = 1000;
  slickCarouselConfig.event = event;

  // signature: event, slick, currentSlide, nextSlide
  function beforeChange() {
    
  }

  // signature: event, slick, currentSlide, nextSlide
  function afterChange() {

  }

  // signature: event, slick, breakpoint
  function breakpoint() {

  }

  // signature: event, slick
  function destroy() {

  }

  // signature: event, slick, direction
  function edge() {

  }

  // signature: event, slick
  function reInit() {

  }

  // signature: event, slick
  function init() {

  }

  // signature: event, slick
  function setPosition() {

  }

  // signature: event, slick, direction
  function swipe() {

  } 

}
