'use strict';

/**
 * @class HomeController
 * @description enter the observed birds home
 * @memberOf Controllers
 */
module.exports = HomeController;

function HomeController(PLACE_STATE) {
  var vm;

  vm = this;
  vm.startID = PLACE_STATE;

}

