'use strict';

var angular;
var subheadingDirective;

angular = require('angular');
subheadingDirective = require('./subheadingDirective');
angular
  .module('app.identify')
  .directive('subheading', subheadingDirective);
