// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::expired.run][expired.run]]
'use strict';
var angular;
var expiredRun;
var runDepends;

angular = require('angular');
expiredRun = require('./expiredRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('EXPIRED_STATE');
angular
  .module('app.dashboard.expired')
  .run(expiredRun);

expiredRun.$inject = runDepends;
// expired.run ends here
