'use strict';

module.exports = guideFilter;

function guideFilter() {
  return filter;

    function filter(commonName) {
      var name;
        
      name = commonName || '';

      // replace each space with _ and remove hyphens
      return name.replace(/ /g, '_').replace("'", '');
        
    }
    
}
