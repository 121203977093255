// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::whenRun][whenRun]]
'use strict';
var states;
var when;
var config;

states = [];
when = {};
config = {};
config.url = '^/when';
// configure three multiple views for the question, header, and collected data
config.views = {};
// configure the view for the when question
config.views['@app.identify'] = {};
config.views['@app.identify'].templateUrl = './app/modules/components/identify/' +
                                      'when/when.view.html';
config.views['@app.identify'].controller = 'WhenController as when';
// configure the header title during the when question
config.views['heading@app.identify'] = {};
config.views['heading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                  'when/whenHeading.view.html';
config.views['heading@app.identify'].controller = 'WhenHeadingController as heading';
// configure the view for displaying the collected observation data
config.views['subheading@app.identify'] = {};
config.views['subheading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                       'when/whenSubheading.view.html';
config.views['subheading@app.identify'].controller = 'WhenSubheadingController as subheading';
when.config = config;
module.exports = whenRun;

function whenRun(routerHelper, WHEN_STATE) {
  // set the when question as a child of the identify state
  when.state = WHEN_STATE;
  states.push(when);
  routerHelper.configureStates(states);
}
// whenRun ends here
