// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::inventory.controller][inventory.controller]]
'use strict';

var angular = require('angular');
var InventoryController = require('./InventoryController');
var controllerDepends = [];

controllerDepends.push('$state');
controllerDepends.push('inventoryService');
angular
  .module('app.identify.inventory')
  .controller('InventoryController', InventoryController);

InventoryController.$inject = controllerDepends;
// inventory.controller ends here
