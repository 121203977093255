// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::ThanksHeadingController][ThanksHeadingController]]
'use strict';

/**
 * @class IdBirdsHeadingController
 * @description bind the thanks question title to a separate view
 * @memberOf Controllers
 */
module.exports = IdBirdsHeadingController;

function IdBirdsHeadingController(THANKS_STATE) {
  var vm;

  vm = this;
  vm.state = THANKS_STATE;
}
// ThanksHeadingController ends here
