'use strict';
var states;
var inspect;

states = [];
inspect = {};
inspect.config = {};
inspect.config.url = '^/inspect';
inspect.config.params = {};
inspect.config.params.detail = null;
inspect.config.params.list = null;

// configure three multiple views for the question, header, and collected data
inspect.config.views = {};

// configure the view for the edit options
inspect.config.views['edit@app.identify'] = {};
inspect.config.views['edit@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                       'inspect/inspectEdit.view.html';
inspect.config.views['edit@app.identify'].controller = 'InspectController as inspect';

// configure the view for the inspect question
inspect.config.views['@app.identify'] = {};
inspect.config.views['@app.identify'].templateUrl = flightFormat;
inspect.config.views['@app.identify'].controller = 'InspectController as inspect';

// configure the header title during the inspect question
inspect.config.views['heading@app.identify'] = {};
inspect.config.views['heading@app.identify'].templateUrl = flightFormatHeading;
inspect.config.views['heading@app.identify'].controller = 'InspectHeadingController as heading';

// configure the view for displaying the collected observation data
inspect.config.views['subheading@app.identify'] = {};
inspect.config.views['subheading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                       'inspect/inspectSubheading.view.html';
inspect.config.views['subheading@app.identify'].controller = 'InspectSubheadingController as subheading';
module.exports = inspectRun;

function flightFormat($stateParams) {
  if ($stateParams.list) return './app/modules/components/identify/' +
                                'inspect/inspectList.view.html';
    else return './app/modules/components/identify/' +
                'inspect/inspectDetail.view.html';
}

function flightFormatHeading($stateParams) {
  if ($stateParams.list) return './app/modules/components/identify/' +
                                'inspect/inspectListHeading.view.html';
    else return './app/modules/components/identify/' +
                'inspect/inspectDetailHeading.view.html';
}

function inspectRun(routerHelper, INSPECT_STATE) {
  // set the inspect question as a child of the identify state
  inspect.state = INSPECT_STATE;
  states.push(inspect);
  routerHelper.configureStates(states);
}
