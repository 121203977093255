'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push('ui.router');
modDepends.push('blocks.routerHelper');
modDepends.push('blocks.assistant');
modDepends.push('app.identify.place.locator');
modDepends.push('app.dashboard.home');
/**
 * @module app/identify/place
 * @description enter the location of a bird observation
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/identify/birdParams}
 * @see module:app
 * @see module:identify
 */
angular
  .module('app.identify.place', modDepends);

