// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::session.constant][session.constant]]
'use strict';
var angular;
var roles;

angular = require('angular');
roles = {};
roles.all = '*';
roles.confirmed = 'confirmed';
roles.pending = 'pending';
roles.anonymous = 'anonymous';
angular
  .module('blocks.session')
  .constant('USER_ROLES', roles);
// session.constant ends here
