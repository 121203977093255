// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::placeSubheading.controller][placeSubheading.controller]]
'use strict';
var angular;
var PlaceSubheadingController;
var controllerDepends;

angular = require('angular');
PlaceSubheadingController = require('./PlaceSubheadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('PLACE_STATE');
controllerDepends.push('WHEN_STATE');
angular
  .module('app.identify.place')
  .controller('PlaceSubheadingController', PlaceSubheadingController);

PlaceSubheadingController.$inject = controllerDepends;
// placeSubheading.controller ends here
