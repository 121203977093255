// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::signup.decorator][signup.decorator]]
'use strict';
var angular;
var appServerServiceDecorator;
var appServerDepends;

angular = require('angular');
appServerServiceDecorator = require('./appServerServiceDecorator');
appServerDepends = [];
appServerDepends.push('$delegate');
appServerDepends.push('$resource');
appServerDepends.push('$state');
angular
  .module('app.dashboard.signup')
  .decorator('appServerService', appServerServiceDecorator);

appServerServiceDecorator.$inject = appServerDepends;
// signup.decorator ends here
