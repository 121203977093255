// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::place.run][place.run]]
'use strict';
var angular;
var placeRun;
var runDepends;

angular = require('angular');
placeRun = require('./placeRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('PLACE_STATE');
angular
  .module('app.identify.place')
  .run(placeRun);

placeRun.$inject = runDepends;
// place.run ends here
