// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::centering.module][centering.module]]
'use strict';

var angular = require('angular');
var modDepends = [];

/**
 * @module app/blocks/centering
 * @description API for stepping through bird identification
 * @requires {@link module:app/blocks/dataStructures}
 * @see module:app
 * @see module:blocks
 */
angular
  .module('blocks.centering', modDepends);
// centering.module ends here
