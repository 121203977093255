// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::spinner.module][spinner.module]]
'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push('ui.router');
modDepends.push('blocks.routerHelper');
/**
 * @module app/identify/spinner
 * @description enter the location of a bird observation
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/identify/birdParams}
 * @see module:app
 * @see module:identify
 */
angular
  .module('app.identify.spinner', modDepends);
// spinner.module ends here
