// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::InspectHeadingController][InspectHeadingController]]
'use strict';

/**
 * @class InspectHeadingController
 * @description bind the inspect question title to a separate view
 * @memberOf Controllers
 */
module.exports = InspectHeadingController;

function InspectHeadingController(BEHAVIOR_STATE, INSPECT_STATE) {
  var vm;

  vm = this;
  vm.prior = BEHAVIOR_STATE;
  vm.state = INSPECT_STATE;
}
// InspectHeadingController ends here
