// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::slide.animation.js][slide.animation.js]]
'use strict';

var jQuery = require("jquery");

module.exports = function () {
  var animation = {
    enter: function(element, done) {
      var jqElement = jQuery(element);

      jqElement.css({
        opacity: 0.5,
        position: "relative",
        top: "10px",
        left: "20px"
      })
      .animate({
        top: 0,
        left: 0,
        opacity: 1
        }, 300, done);
    }
  };

  return animation;
};
  //   enter: function(element, done) {
  //     element.css({
  //       opacity: 0.5,
  //       position: "relative",
  //       top: "10px",
  //       left: "20px"
  //     })
  //     .animate({
  //       top: 0,
  //       left: 0,
  //       opacity: 1
  //       }, 1000, done);
  //   }
  // };
    // // make note that other events (like addClass/removeClass)
    // // have different function input parameters
    // enter: function(element, doneFn) {
    //   angular.element.fadeIn(1000, doneFn);

    //   // remember to call doneFn so that angular
    //   // knows that the animation has concluded
    // },

    // move: function(element, doneFn) {
    //   angular.element.fadeIn(1000, doneFn);
    // },

    // leave: function(element, doneFn) {
    //   angular.element.fadeOut(1000, doneFn);
    // }
  //};
// slide.animation.js ends here
