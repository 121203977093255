// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::dashboard.run][dashboard.run]]
'use strict';
var angular;
var dashboardRun;
var runDepends;

angular = require('angular');
dashboardRun = require('./dashboardRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('$rootScope');
runDepends.push('$timeout');
runDepends.push('DASHBOARD_STATE');
angular
  .module('app.dashboard')
  .run(dashboardRun);

dashboardRun.$inject = runDepends;
// dashboard.run ends here
