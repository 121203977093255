  'use strict';

  /**
    * @function routerHelperProvider
    * @description provide helper functions for configuring state and url handling
    * @param {Object} $locationProvider - reference to the object used to configure how the application deep linking paths are stored
    * @param {Object} $stateProvider - reference to the object that focusses on managing state and UI for the app
    * @param {Object} $urlRouterProvider - reference to the object that has the responsibility of watching $location
    * @memberOf Providers
    * @see {@link module:angular}
    * @see {@link module:angular-ui-router}
    * @see {@link module:blocks/routerHelper}
    * @example
    * var states = [];
    * var dashboard = {};
    * var config = {};
    *
    * config.url = '/dashboard';
    * config.templateUrl = './app/modules/components/dashboard/dashboard.view.html';
    * config.controller = 'DashboardController as dashboard';
    * dashboard.state = 'dashboard';
    * dashboard.config = config;
    * states.push(dashboard);
    *
    * angular
    *   .module('app.dashboard', ['blocks.routerHelper'])
    *   .run(modRun)
    *   .controller('DashboardController', DashboardController);
    *
    * modRun.$inject = ['routerHelper'];
    * function modRun(routerHelper) {
    *   routerHelper.configureStates(states);
    * }
    *
    */
module.exports = function($locationProvider, $stateProvider, $urlRouterProvider) {
  this.$get = RouterHelper;
  $locationProvider.html5Mode(true);
  RouterHelper.$inject = ['$state'];

  function RouterHelper($state) {
    var hasOtherwise = false;
    var hasRedirect = false;
    var service;

    service = {};
    service.configureStates = configureStates;
    service.getStates = getStates;

    return service;

    function configureStates(states, otherwisePath, redirectPath) {
      states.forEach(pushState);
      if (redirectPath && !hasRedirect) {
        hasRedirect = true;
        $urlRouterProvider.when('', redirectPath);
      }

      if (otherwisePath && !hasOtherwise) {
        hasOtherwise = true;
        $urlRouterProvider.otherwise(otherwisePath);
      }

    }

    function pushState(state) {
      $stateProvider.state(state.state, state.config);
    }

    function getStates() {
      return $state.get();
    }
  }
};
