// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::home.run][home.run]]
'use strict';
var angular;
var homeRun;
var runDepends;

angular = require('angular');
homeRun = require('./homeRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('HOME_STATE');
runDepends.push('$state');
runDepends.push('assistantService');
runDepends.push('sessionService');
angular
  .module('app.dashboard.home')
  .run(homeRun);

homeRun.$inject = runDepends;
// home.run ends here
