// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::behavior.decorator][behavior.decorator]]
'use strict';
var angular;
var assistantServiceDecorator;
var decoratorDepends;

angular = require('angular');
assistantServiceDecorator = require('./assistantServiceDecorator');
decoratorDepends = [];
decoratorDepends.push('$delegate');
decoratorDepends.push('BEHAVIOR_STATE');
angular
  .module('app.identify.behavior')
  .decorator('assistantService', assistantServiceDecorator);

assistantServiceDecorator.$inject = decoratorDepends;
// behavior.decorator ends here
