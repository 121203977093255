// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::idSteps.controller][idSteps.controller]]
'use strict';

var angular = require('angular');
var IdStepsController = require('./IdStepsController');
var controllerDepends = [];

controllerDepends.push('$state');
controllerDepends.push('birdParamsService');
angular
  .module('app.identify.idSteps')
  .controller('IdStepsController', IdStepsController);

IdStepsController.$inject = controllerDepends;
// idSteps.controller ends here
