// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::behavior.run][behavior.run]]
'use strict';
var angular;
var behaviorRun;
var runDepends;

angular = require('angular');
behaviorRun = require('./behaviorRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('BEHAVIOR_STATE');
angular
  .module('app.identify.behavior')
  .run(behaviorRun);

behaviorRun.$inject = runDepends;
// behavior.run ends here
