'use strict';
var angular;
var BehaviorController;
var controllerDepends;

angular = require('angular');
BehaviorController = require('./BehaviorController');
controllerDepends = [];
controllerDepends.push('appServerService');
controllerDepends.push('sessionService');
controllerDepends.push('assistantService');
controllerDepends.push('BEHAVIOR_STATE');
controllerDepends.push('INSPECT_STATE');
controllerDepends.push('SPINNER_STATE');
controllerDepends.push('SIGNUP_STATE');
controllerDepends.push('SORRY_STATE');
controllerDepends.push('$state');
angular
  .module('app.identify.behavior')
  .controller('BehaviorController', BehaviorController);

BehaviorController.$inject = controllerDepends;
