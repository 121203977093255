'use strict';

/**
 * @class ThanksController
 * @description enter the observed birds thanks
 * @memberOf Controllers
 */
module.exports = ThanksController;

function ThanksController(HOME_STATE,
                          SIGNUP_STATE,
                          $stateParams,
                          $state,
                          sessionService) {
  var vm;

  vm = this;
  vm.home = HOME_STATE;
  vm.resend = !!$stateParams.resend;
  vm.checkStatus = checkStatus;
  vm.tryAgain = tryAgain;

  function checkStatus() {
    sessionService
      .selectLandingPage('app.dashboard.home',
                         'app.dashboard.signup',
                         'app.dashboard.thanks');
  }

  function tryAgain() {
    $state.go(SIGNUP_STATE);
  }

}
