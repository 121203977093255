'use strict';

/**
 * @class BehaviorHeadingController
 * @description bind the behavior question title to a separate view
 * @memberOf Controllers
 */
module.exports = BehaviorHeadingController;

function BehaviorHeadingController(assistantService, BEHAVIOR_STATE) {
  var vm;

  vm = this;
  vm.totalSteps = assistantService.totalSteps();
  vm.currentStep = 1 + assistantService.index(BEHAVIOR_STATE);
  vm.prior = assistantService.step(BEHAVIOR_STATE).prior.value;
}
