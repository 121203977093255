'use strict';

var angular;
var imageCarouselService;
var serviceDepends;

angular = require('angular');
imageCarouselService = require('./imageCarouselService');
serviceDepends = [];
angular
  .module('blocks.imageCarousel')
  .service('imageCarouselService', imageCarouselService);

imageCarouselService.$inject = serviceDepends;
