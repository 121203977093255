// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::sizeHeading.controller][sizeHeading.controller]]
'use strict';
var angular;
var SizeHeadingController;
var controllerDepends;

angular = require('angular');
SizeHeadingController = require('./SizeHeadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('SIZE_STATE');
angular
  .module('app.identify.size')
  .controller('SizeHeadingController', SizeHeadingController);

SizeHeadingController.$inject = controllerDepends;
// sizeHeading.controller ends here
