// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::session.module][session.module]]
'use strict';

var angular = require('angular');
var modDepends = [];

modDepends.push('ngStorage');
modDepends.push('blocks.appServer');
/**
 * @module app/blocks/session
 * @description API for stepping through bird identification
 * @requires {@link module:app/blocks/dataStructures}
 * @see module:app
 * @see module:blocks
 */
angular
  .module('blocks.session', modDepends);
// session.module ends here
