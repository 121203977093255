'use strict';

var angular;
var interchangeQueriesFactory;
var interchangeQueriesManagerFactory;
var interchangeToolsFactory;
var interchangeToolsFactoryDepends;
var interchangeQueriesManagerFactoryDepends;
var interchangeQueriesFactoryDepends;

angular = require('angular');
interchangeToolsFactory = require('./interchangeToolsFactory');
interchangeToolsFactoryDepends = [];
interchangeToolsFactoryDepends.push('$window');
interchangeToolsFactoryDepends.push('matchMedia');
interchangeToolsFactoryDepends.push('interchangeQueries');
interchangeQueriesManagerFactory = require('./interchangeQueriesManagerFactory');
interchangeQueriesManagerFactoryDepends = [];
interchangeQueriesManagerFactoryDepends.push('interchangeQueries');
interchangeQueriesFactory = require('./interchangeQueriesFactory');
interchangeQueriesFactoryDepends = [];
interchangeQueriesFactoryDepends.push('$document');

angular
  .module('blocks.zf.interchange')
  .factory('interchangeQueries', interchangeQueriesFactory)
  .factory('interchangeTools', interchangeToolsFactory)
  .factory('interchangeQueriesManager', interchangeQueriesManagerFactory);

interchangeQueriesManagerFactory.$inject = interchangeQueriesManagerFactoryDepends;
interchangeQueriesFactory.$inject = interchangeQueriesFactoryDepends;
interchangeToolsFactory.$inject = interchangeToolsFactoryDepends;
