// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::escapeApostrophe.index][escapeApostrophe.index]]
'use strict';

var angular = require('angular');
var escapeApostrophe = require('./escapeApostrophe.filter');

angular
  .module('app.escapeApostrophe', [])
    .filter('escapeApostrophe', escapeApostrophe);
// escapeApostrophe.index ends here
