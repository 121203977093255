'use strict';

/**
 * @class SignupController
 * @description enter the observed birds signup
 * @memberOf Controllers
 */
module.exports = SignupController;

function SignupController(appServerService,
                          sessionService,
                          THANKS_STATE,
                          SIGNUP_STATE,
                          EXPIRED_STATE,
                          SPINNER_STATE,
                          HOME_STATE) {
  var vm;

  vm = this;
  vm.submitEmail = submitEmail;

  function submitEmail() {
    var body;
    var landingStates;
    var emailRegEx;

    emailRegEx = /^[^\.][a-zA-Z0-9._%+-]+[^\.]@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!vm.address || !emailRegEx.test(vm.address)) {
      vm.notValid = true;
      angular.element(document.querySelector('#merlin-email'))[0].value = '';  //jshint ignore:line
      return;
    } else {
      vm.empty = false;
    }

    // stick the user's address in the body of the request to register
    // console.dir(JSON.parse(JSON.stringify(sessionService.storage)));
    // body = sessionService.storage;
    body = {};
    body.token = sessionService.storage.token;
    body.email_address = vm.address;

    // collect the states to navigate to on success and failure to register
    landingStates = {};
    landingStates.spinner = SPINNER_STATE;
    landingStates.thanks = THANKS_STATE;
    landingStates.entry = HOME_STATE;
    landingStates.signup = SIGNUP_STATE;
    landingStates.expired = EXPIRED_STATE;

    appServerService.putAddress(body, landingStates, sessionService.storage);
    //appServerService.Register.newUser(null, body, registerSuccess, registerFailure);

    // function registerSuccess(newStatus) {
    //   // update the session state
    //   sessionService.storage.status = newStatus.payload;
    //   sessionService.storage.token = newStatus.token;

    //   // go to the 'thank you' screen
    //   $state.go(THANKS_STATE);
    // }

    // function registerFailure(err) {
    //   console.log('signup error');
    //   console.dir(err);

    //   // if the token expired...
    //   if (err.data.errors[0].name === 'TokenExpiredError') {

    //     // and if the trial period is active navigate to the signup page
    //     if (sessionService.storage.status.trial.isActive) $state.go(SIGNUP_STATE);

    //     // or if the grace period is active navigate to the email reminder page
    //     if (sessionService.storage.status.email.grace) $state.go(EXPIRED_STATE);

    //   // otherwise if the trial attempts limit reached...
    //   } else if (err.data.errors[0].name === 'TrialLimitError') {

    //     // navigate to the signup page
    //     $state.go(SIGNUP_STATE);

    //   // otherwise if the token was not valid...
    //   } else if (err.data.errors[0].name === 'JsonWebTokenError' || err.data.errors[0].name === 'Unauthorized') {

    //     // navigate to the signup page
    //     $state.go(SIGNUP_STATE);

    //   }

    // }

  }

}
