'use strict';

/**
 * @function inspect-appServerServiceDecorator
 * @description add 'flight' state data for use in the steps display and navigator
 * @memberOf Decorators
 */
module.exports = appServerServiceDecorator;

function appServerServiceDecorator($delegate, $resource) {
  var idRoute;
  var customActions;

  customActions = {};
  customActions.bird = {};
  customActions.bird.method = 'PUT';

  idRoute = '/idbird';

  $delegate.Id = $resource(idRoute, null, customActions);

  return $delegate;
}

