// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::calendarService][calendarService]]
'use strict';

module.exports = function () {
  var vm = this;
  var today = new Date();
  var year = today.getFullYear();
  var febDays = year % 4 === 0 ? 29 : 28;

  vm.today = today;
  vm.daysIn = {};
  vm.daysIn.January = monthDays(31);
  vm.daysIn.February = monthDays(febDays);
  vm.daysIn.March = monthDays(31);
  vm.daysIn.April = monthDays(30);
  vm.daysIn.May = monthDays(31);
  vm.daysIn.June = monthDays(30);
  vm.daysIn.July = monthDays(31);
  vm.daysIn.August = monthDays(31);
  vm.daysIn.September = monthDays(30);
  vm.daysIn.October = monthDays(31);
  vm.daysIn.November = monthDays(30);
  vm.daysIn.December = monthDays(31);
  // format the number display of the month
  vm.monthToNumber = {};
  vm.monthToNumber.January = '01';
  vm.monthToNumber.February = '02';
  vm.monthToNumber.March = '03';
  vm.monthToNumber.April = '04';
  vm.monthToNumber.May = '05';
  vm.monthToNumber.June = '06';
  vm.monthToNumber.July = '07';
  vm.monthToNumber.August = '08';
  vm.monthToNumber.September = '09';
  vm.monthToNumber.October = '10';
  vm.monthToNumber.November = '11';
  vm.monthToNumber.December = '12';


  vm.dayToNumber = function(day) {
    var dayString;

      if (typeof day === 'string') dayString = day;
      else dayString = day.toString();

    if (dayString.length === 1) return ('0' + dayString);
      else return dayString;
  };


  vm.months = Object.keys(vm.daysIn);

  function monthDays(totalDays) {
    var days = Array
      .apply(null, Array(totalDays))
      .reduce(function(memo, element, index) {
        memo.push(index+1);
        return memo;
      },[]);

    return days;
  }

};
// calendarService ends here
