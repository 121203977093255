// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::colorsSubheading.controller][colorsSubheading.controller]]
'use strict';
var angular;
var ColorsSubheadingController;
var controllerDepends;

angular = require('angular');
ColorsSubheadingController = require('./ColorsSubheadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('PLACE_STATE');
controllerDepends.push('WHEN_STATE');
angular
  .module('app.identify.colors')
  .controller('ColorsSubheadingController', ColorsSubheadingController);

ColorsSubheadingController.$inject = controllerDepends;
// colorsSubheading.controller ends here
