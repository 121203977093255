// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::details.run][details.run]]
'use strict';
var angular;
var detailsRun;
var runDepends;

angular = require('angular');
detailsRun = require('./detailsRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('DETAILS_STATE');
angular
  .module('app.identify.inspect.details')
  .run(detailsRun);

detailsRun.$inject = runDepends;
// details.run ends here
