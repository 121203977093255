// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::IdStepsController.js][IdStepsController.js]]
'use strict';

/**
 * @class IdStepsController
 * @description provides access to the bird identification process data
 * @param {Object} $state - a reference to the angular ui-router state service
 * @param {Object} birdParamsService - a reference to the birdParams service
 * @memberOf Controllers
 */
module.exports = function ($state, birdParamsService) {
  var vm = this;
  var sizeWords = '';

  birdParamsService.range.forEach(describeSize);

  vm.params = [
    birdParamsService.location,
    birdParamsService.time,
    sizeWords,
    birdParamsService.colors,
    birdParamsService.behavior
  ];

  vm.currentStep = birdParamsService.currentStep;

  vm.steps = birdParamsService.steps;

  vm.isEmpty = function(index) {
    if (Array.isArray(vm.params[index]) && vm.params[index].length === 0) return true;

    if (!vm.params[index]) return true;

    return false;
  };

  vm.isActive = function(stateName) {
    return stateName === $state.current.name ? true : false;
  };

  function describeSize(size) {
    if (size.urlParam === birdParamsService.size) sizeWords = size.description;
  }
};
// IdStepsController.js ends here
