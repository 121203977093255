// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::appNamespace.js][appNamespace.js]]
'use strict';
// establish the app namespace for reference documentation

/**
 * @namespace Providers
 */

/**
 * @namespace Services
 */

/**
 * @namespace Directives
 */

/**
 * @namespace Filters
 */

/**
 * @namespace Controllers
 */
// appNamespace.js ends here
