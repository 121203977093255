'use strict';

var angular;
var modalBackdropDirective;
var modalWindowDirective;
var modalBackdropDirectiveDepends;
var modalWindowDirectiveDepends;

angular = require('angular');
modalBackdropDirective = require('./modalBackdropDirective');
modalWindowDirective = require('./modalWindowDirective');
modalBackdropDirectiveDepends = [];
modalBackdropDirectiveDepends.push('$modalStack');
modalBackdropDirectiveDepends.push('$timeout');
modalWindowDirectiveDepends = [];
modalWindowDirectiveDepends.push('$modalStack');
modalWindowDirectiveDepends.push('$timeout');
angular
  .module('blocks.zf.modal')
  .directive('modalBackdrop', modalBackdropDirective)
  .directive('modalWindow', modalWindowDirective);

modalBackdropDirective.$inject = modalBackdropDirectiveDepends;
modalWindowDirective.$inject = modalWindowDirectiveDepends;
