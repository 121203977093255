// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::detailRun][detailRun]]
'use strict';

var states = [];
var detail = {};
var config = {};

config.url = '^/bestMatch/detail';
config.templateUrl = './app/modules/components/identify/bestMatch/detail/detail.view.html';
config.controller = 'DetailController as detail';
detail.state = 'app.identify.bestMatch.detail';
detail.config = config;
states.push(detail);
module.exports = function (routerHelper) {
  routerHelper.configureStates(states);
};
// detailRun ends here
