// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::identify.run][identify.run]]
'use strict';
var angular;
var identifyRun;
var runDepends;

angular = require('angular');
identifyRun = require('./identifyRun');
runDepends = [];
runDepends.push('routerHelper');
angular
  .module('app.identify')
  .run(identifyRun);

identifyRun.$inject = runDepends;
// identify.run ends here
