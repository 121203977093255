// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::placeHeading.controller][placeHeading.controller]]
'use strict';
var angular;
var PlaceHeadingController;
var controllerDepends;

angular = require('angular');
PlaceHeadingController = require('./PlaceHeadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('PLACE_STATE');
controllerDepends.push('HOME_STATE');
angular
  .module('app.identify.place')
  .controller('PlaceHeadingController', PlaceHeadingController);

PlaceHeadingController.$inject = controllerDepends;
// placeHeading.controller ends here
