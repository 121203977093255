// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::spinner.controller][spinner.controller]]
'use strict';
var angular;
var SpinnerController;
var SpinnerControllerDepends;

angular = require('angular');
SpinnerController = require('./SpinnerController');
SpinnerControllerDepends = [];
SpinnerControllerDepends.push('$stateParams');
angular
  .module('app.identify.spinner')
  .controller('SpinnerController', SpinnerController);

SpinnerController.$inject = SpinnerControllerDepends;
// spinner.controller ends here
