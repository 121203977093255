'use strict';

module.exports = appServerServiceDecorator;

function appServerServiceDecorator($delegate, $resource) {
  var customActions;

  customActions = {};
  customActions.update = {};
  customActions.update.method = 'PUT';
  $delegate.Token = $resource('/token', null, customActions);

  return $delegate;
}

