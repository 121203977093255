'use strict';
var states;
var details;

states = [];
details = {};
details.config = {};
details.config.url = '^/details/{plumage:int}';
details.config.params = {};
details.config.params.info = null;
details.config.params.sounds = null;
details.config.params.map = null;
details.config.params.plumage = null;

// configure three nested views for the header, menu, and
// content
details.config.views = {};

// set the view for the edit menu
details.config.views['edit@app.identify'] = {};
details.config.views['edit@app.identify'].template = '';

// set the view for the bird details content
details.config.views['@app.identify'] = {};
details.config.views['@app.identify'].templateUrl = topicUrl;
details.config.views['@app.identify'].controller = 'InspectController as inspect';

// set the header nav
details.config.views['heading@app.identify'] = {};
details.config.views['heading@app.identify']
      .templateUrl = './app/modules/components/identify/inspect/' +
                     'details/detailsHeading.view.html';
details.config.views['heading@app.identify']
      .controller = 'DetailsHeadingController as heading';

// set the menu nav view
details.config.views['subheading@app.identify'] = {};
details.config.views['subheading@app.identify'].templateUrl = topicSubHeading;
details.config.views['subheading@app.identify']
      .controller = 'DetailsSubheadingController as subheading';
module.exports = detailsRun;

function topicUrl($stateParams) {
  if ($stateParams.sounds) return './app/modules/components/identify/inspect/' +
                                'details/detailsSounds.view.html';
  if ($stateParams.map) return './app/modules/components/identify/inspect/' +
                                'details/detailsMap.view.html';
    else return './app/modules/components/identify/inspect/' +
                'details/detailsInfo.view.html';
}

function topicSubHeading($stateParams) {
    if ($stateParams.sounds) return './app/modules/components/identify/inspect/' +
                                    'details/detailsSoundsSubheading.view.html';
    if ($stateParams.map) return './app/modules/components/identify/inspect/' +
                                 'details/detailsMapSubheading.view.html';
      else return './app/modules/components/identify/inspect/' +
                  'details/detailsInfoSubheading.view.html';
}

function detailsRun(routerHelper, DETAILS_STATE) {
  // set the details question as a child of the identify state
  details.state = DETAILS_STATE;
  states.push(details);
  routerHelper.configureStates(states);
}
