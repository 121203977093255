// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::SpinnerHeadingController][SpinnerHeadingController]]
'use strict';

/**
 * @class SpinnerHeadingController
 * @description bind the spinner question title to a separate view
 * @memberOf Controllers
 */
module.exports = SpinnerHeadingController;

function SpinnerHeadingController() {
  var vm;

  vm = this;
}
// SpinnerHeadingController ends here
