/**
 * @function flight-assistantServiceDecorator
 * @description add 'flight' state data for use in the steps display and navigator
 * @memberOf Decorators
 */
module.exports = assistantServiceDecorator;

function assistantServiceDecorator($delegate, removeTagsFilter) {
  $delegate.result = [];
  $delegate.flight = flight.bind($delegate);

  return $delegate;

  function flight(birds) {
    if (birds) {
      this.result = [];
      birds.matches.forEach(mapKeys, this);
    } else {
      return this.result;
    }

    function mapKeys(match, index) {
      this.result[index] = {};
      this.result[index].commonName = match.common_name;
      this.result[index].uncommon = match.abundance < 33;
      this.result[index].rare = match.abundance < 5;
      this.result[index].pms = match.pms;
      this.result[index].plumageID = match.bestMatchPlumageID;
      this.result[index].photos = match.photos;
      this.result[index].sounds = match.sounds;
      this.result[index].map = match.map;
      this.result[index].cardText = removeTagsFilter(match.id_text);
      this.result[index].detailText = removeTagsFilter(match.id_text);
      this.result[index].eBirdCode = match.species_code;
      this.result[index].aab_species = match.aab_species;
    }

  }

}
