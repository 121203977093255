'use strict';

/**
 * @class CongratsHeadingController
 * @description bind the congrats question title to a separate view
 * @memberOf Controllers
 */
module.exports = CongratsHeadingController;

function CongratsHeadingController(assistantService, $state, $stateParams) {
  var vm;

  vm = this;
  vm.state = $state.current.name;
  vm.prior = '^';
  vm.plumage = $stateParams.plumage;
  vm.commonName = assistantService.result[$stateParams.plumage].commonName;
}

