// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::idSteps.directive][idSteps.directive]]
'use strict';

var angular = require('angular');
var idStepsDirective = require('./idStepsDirective');

angular
  .module('app.identify.idSteps')
  .directive('mwIdSteps', idStepsDirective);
// idSteps.directive ends here
