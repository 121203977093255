// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::details.controller][details.controller]]
'use strict';
var angular;
var DetailsController;
var controllerDepends;

angular = require('angular');
DetailsController = require('./DetailsController');
controllerDepends = [];
controllerDepends.push('imageCarouselService');
controllerDepends.push('assistantService');
controllerDepends.push('$stateParams');
angular
  .module('app.identify.inspect.details')
  .controller('DetailsController', DetailsController);

DetailsController.$inject = controllerDepends;
// details.controller ends here
