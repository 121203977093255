// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::match.module][match.module]]
'use strict';

var angular = require('angular');
var modDepends = [];

/**
 * @module app/identify/match
 * @description identify a bird with a simple five-step process
 * @see module:app
 * @see module:identify
 */
angular
  .module('app.identify.match', modDepends);
// match.module ends here
