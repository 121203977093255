'use strict';

//require('jquery');
require('angular-sanitize');

// require('angular-foundation');
require('../../../shared/blocks/capitalize');
require('../../../shared/blocks/assistant');
require('../../../shared/blocks/lazyLoad');
require('../../../shared/blocks/imageCarousel');
require('../../../shared/blocks/session');

// helper functions for configuring the ui-router state
require('../../../shared/blocks/routerHelper');
require('../../../shared/blocks/jplayer');
require('../../../shared/blocks/zfMediaQueries');
require('../flight');
require('../place');
require('../when');
require('./details');
require('./congrats');
//require('angular-flickity');

require('./inspect.module');

// the inspect state name
require('./inspect.constant');
require('./inspect.config');

// configure the inspect state and views
require('./inspect.run');
require('./inspect.decorator');

// bind the inspect question model to the main view
require('./inspect.controller');

// bind the inspect title to the header view
require('./inspectHeading.controller');

// bind the observed inspect to the observation view
require('./inspectSubheading.controller');
