'use strict';
var states;
var thanks;
var config;

states = [];
thanks = {};
config = {};
config.url = '^/thanks';

// configure the parameter passed to toggle the display of messages
config.params = {};
config.params.resend = undefined;

// configure three multiple views for the question, header, and collected data
config.views = {};

// configure the view for the thanks question
config.views['@app.dashboard'] = {};
config.views['@app.dashboard'].templateUrl = './app/modules/components/dashboard/' +
                                      'thanks/thanks.view.html';
config.views['@app.dashboard'].controller = 'ThanksController as thanks';

// configure the header title during the thanks question
config.views['heading@app.dashboard'] = {};
config.views['heading@app.dashboard'].templateUrl = './app/modules/components/dashboard/' +
                                                  'thanks/thanksHeading.view.html';
config.views['heading@app.dashboard'].controller = 'ThanksHeadingController as heading';
thanks.config = config;
module.exports = thanksRun;

function thanksRun(routerHelper, THANKS_STATE) {
  // set the size question as a child of the dashboard state
  thanks.state = THANKS_STATE;
  states.push(thanks);
  routerHelper.configureStates(states);
}
