'use strict';

require('../../../../shared/blocks/assistant');
require('../../../../shared/blocks/imageCarousel');
require('../../../../shared/blocks/routerHelper');   // helper functions for configuring the ui-router state
require('../../../../shared/blocks/jplayer');
require('../../flight');
// require('angular-audio');

require('./details.module');
require('./details.constant');                    // the details state name
require('./details.run');                         // configure the details state and views
require('./details.controller');                  // bind the details question model to the main view
require('./detailsHeading.controller');            // bind the details title to the header view
require('./detailsSubheading.controller');       // bind the observed details to the observation view

