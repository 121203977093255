'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push('blocks.routerHelper');
modDepends.push('blocks.centering');
modDepends.push('blocks.session');

// TODO: add the embed option?
//modDepends.push('app.dashboard.embed');
// modDepends.push('app.dashboard.sendBirds');
modDepends.push('app.dashboard.home');
// modDepends.push('app.dashboard.idBirds');
modDepends.push('app.dashboard.thanks');
modDepends.push('app.dashboard.signup');
modDepends.push('app.dashboard.sorry');
// modDepends.push('app.dashboard.sorry');
/**
 * @module app/dashboard
 * @description provides access to the highest level navigation in Merlin Web Client
 * @requires {@link module:blocks/routerHelper}
 * @see module:app
 */
angular
  .module('app.dashboard', modDepends);
