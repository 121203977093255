'use strict';

/**
 * @function flight-appServerServiceDecorator
 * @description add 'flight' state data for use in the steps display and navigator
 * @memberOf Decorators
 */
module.exports = appServerServiceDecorator;

function appServerServiceDecorator($delegate, $resource, $state) {
  var matchRoute;
  var customActions;
  var defaults;

  defaults = {};
  defaults.mybird = 'na';

  customActions = {};

  // get the best Merlin matches and send the user's token
  customActions.signedMatch = {};
  customActions.signedMatch.method = 'PUT';

  // post a new Merlin session with the selected bird and user's token
  customActions.selectBird = {};
  customActions.selectBird.method = 'PUT';
  customActions.selectBird.params = {};
  customActions.selectBird.params.results = '@results';
  customActions.selectBird.params.mybird = '@mybird';
  customActions.selectBird.url = '/selectmerlinbird' +
                                 '/:lat' +
                                 '/:lng' +
                                 '/:date' +
                                 '/:size' +
                                 '/:colors' +
                                 '/:behavior' +
                                 '/:results' +
                                 '/:mybird';

  // the route for getting merlin matches
  matchRoute = '/match' +
               '/:lat' +
               '/:lng' +
               '/:date' +
               '/:size' +
               '/:colors' +
               '/:behavior' +
               '/:mybird';

  $delegate.Flight = $resource(matchRoute, defaults, customActions);
  $delegate.getMerlinFlight = getMerlinFlight.bind($delegate);

  return $delegate;

  function getMerlinFlight(flightParams, webStorage, landingStates, storeBirds) {
    var stateOptions;

    stateOptions = {};
    stateOptions.location = 'replace';

    // request the flight of Merlin birds
    this.Flight.signedMatch(flightParams, webStorage, flightSuccess, flightError);  // jshint ignore:line

    // go to the 'in-progress' view
    if (landingStates.waiting.params) $state.go(landingStates.waiting.state, landingStates.waiting.params);
      else $state.go(landingStates.waiting.state);

    function flightSuccess(birds) {

      // update the session state
      webStorage.token = birds.token;

      // store the session state in a human-readable format for dev work
      // webStorage.status = birds.payload;

      // store the flight of birds
      storeBirds(birds);

      // inspect the flight of birds
      if (landingStates.success.params) $state.go(landingStates.success.state, landingStates.success.params, stateOptions);
        else $state.go(landingStates.success.state, null, stateOptions);
    }

    function flightError(err) {
      var thanksOptions;
      var name ;

      thanksOptions = {};
      thanksOptions.resend = true;

      // tell the user to register in order to keep using the alp
      // $state
      //   .go(landingStates.problem.expired.state, thanksOptions, stateOptions);

      name = err.data.errors[0].name;

      // send the user to the signup page if there is a known problem with
      // the session state
      if (name === 'Unauthorized' || name === 'TrialLimitError' ||
          name === 'JsonWebTokenError' || name === 'TokenExpiredError') {
        $state
          .go(landingStates.problem.expired.state, thanksOptions, stateOptions);

      // otherwise tell the user that an internal error has ocurred
      } else {
        $state
          .go(landingStates.problem.internal.state, thanksOptions, stateOptions);
      }

      // // if the token expired...
      // if (err.data.errors[0].name === 'TokenExpiredError') {

      //   // and if the trial period is active navigate to the signup page
      //   if (webStorage.status.trial.isActive) $state.go(landingStates.problem.signup.state, null, stateOptions);

      //   // or if the grace period is active navigate to the email reminder page
      //   if (webStorage.status.email.grace) $state.go(landingStates.problem.expired.state, null, stateOptions);

      // // otherwise if the trial attempts limit reached...
      // } else if (err.data.errors[0].name === 'TrialLimitError') {

      //   // navigate to the signup page
      //   $state.go(landingStates.problem.signup.state, null, stateOptions);

      // // otherwise if the token was not valid...
      // } else if (err.data.errors[0].name === 'JsonWebTokenError' || err.data.errors[0].name === 'Unauthorized') {

      //   // navigate to the signup page
      //   $state.go(landingStates.problem.signup.state, null, stateOptions);

      // }

    }

  }

}
