// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::bestMatch.run][bestMatch.run]]
'use strict';

var angular = require('angular');
var bestMatchRun = require('./bestMatchRun');
var runDepends = [];

runDepends.push('routerHelper');
angular
  .module('app.identify.bestMatch')
  .run(bestMatchRun);

bestMatchRun.$inject = runDepends;
// bestMatch.run ends here
