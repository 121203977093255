'use strict';
var angular;
var SignupHeadingController;
var controllerDepends;

angular = require('angular');
SignupHeadingController = require('./SignupHeadingController');
controllerDepends = [];
controllerDepends.push('SIGNUP_STATE');
angular
  .module('app.dashboard.signup')
  .controller('SignupHeadingController', SignupHeadingController);

SignupHeadingController.$inject = controllerDepends;
