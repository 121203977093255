// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::modal.directive][modal.directive]]
'use strict';

var angular;
var modalDirective;

angular = require('angular');
modalDirective = require('./modalDirective');
angular
  .module('blocks.modal')
  .directive('modalDialog', modalDirective);
// modal.directive ends here
