// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::expired.index][expired.index]]
require('../../../shared/blocks/routerHelper'); // helper functions for configuring the ui-router state
require('../../dashboard');
require('../../dashboard/home');

require('./expired.module');
require('./expired.constant');
require('./expired.run');
require('./expired.controller');
require('./expiredHeading.controller');
// expired.index ends here
