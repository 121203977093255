// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::splitToLength.filter.js][splitToLength.filter.js]]
'use strict';

module.exports = function () {
  function join(text) {
    if (Array.isArray(text)) return text.join(', ');

    return text;
  }

  return function(text) {
    return join(text);
  };
};
// splitToLength.filter.js ends here
