// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::idSteps.module][idSteps.module]]
'use strict';

var angular = require('angular');
var modDepends = [];

modDepends.push('ui.router');
modDepends.push('app.identify.birdParams');
/**
 * @module app/identify/idSteps
 * @description store the state and data for the identification process
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/identify/birdParams}
 * @see module:app
 * @see module:identify
 */
angular
  .module('app.identify.idSteps', modDepends);
// idSteps.module ends here
