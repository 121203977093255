'use strict';

/**
 * @class IdBirdsHeadingController
 * @description bind the sorry question title to a separate view
 * @memberOf Controllers
 */
module.exports = IdBirdsHeadingController;

function IdBirdsHeadingController(SORRY_STATE) {
  var vm;

  vm = this;
  vm.state = SORRY_STATE;
}
