// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::colorsRun][colorsRun]]
'use strict';
var states;
var colors;
var config;

states = [];
colors = {};
config = {};
config.url = '^/colors';
// configure three multiple views for the question, header, and collected data
config.views = {};
// configure the view for the colors question
config.views['@app.identify'] = {};
config.views['@app.identify'].templateUrl = './app/modules/components/identify/' +
                                      'colors/colors.view.html';
config.views['@app.identify'].controller = 'ColorsController as colors';
// configure the header title during the colors question
config.views['heading@app.identify'] = {};
config.views['heading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                  'colors/colorsHeading.view.html';
config.views['heading@app.identify'].controller = 'ColorsHeadingController as heading';
// configure the view for displaying the collected observation data
config.views['subheading@app.identify'] = {};
config.views['subheading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                       'colors/colorsSubheading.view.html';
config.views['subheading@app.identify'].controller = 'ColorsSubheadingController as subheading';
colors.config = config;
module.exports = colorsRun;

function colorsRun(routerHelper, COLORS_STATE) {
  // set the size question as a child of the identify state
  colors.state = COLORS_STATE;
  states.push(colors);
  routerHelper.configureStates(states);
}
// colorsRun ends here
