'use strict';

module.exports = interchangeQueriesSingleton;

function interchangeQueriesSingleton($document) {
  var element,
    mediaSize,
    formatList = {
    'default': 'only screen',
    landscape : 'only screen and (orientation: landscape)',
    portrait : 'only screen and (orientation: portrait)',
    retina : 'only screen and (-webkit-min-device-pixel-ratio: 2),' +
      'only screen and (min--moz-device-pixel-ratio: 2),' +
      'only screen and (-o-min-device-pixel-ratio: 2/1),' +
      'only screen and (min-device-pixel-ratio: 2),' +
      'only screen and (min-resolution: 192dpi),' +
      'only screen and (min-resolution: 2dppx)'
  },
      
  classPrefix = 'foundation-mq-',
  classList = ['extra-small', 'small', 'medium', 'large', 'xlarge', 'xxlarge'],
  head = angular.element($document[0].querySelector('head'));

  for (var i = 0; i < classList.length; i++) {
    head.append('<meta class="' + classPrefix + classList[i] + '" />');
    element = getComputedStyle(head[0].querySelector('meta.' + classPrefix + classList[i]));
    mediaSize = element.fontFamily.replace(/^[\/\\'"]+|(;\s?})+|[\/\\'"]+$/g, '');
    formatList[classList[i]] = mediaSize;
  }
    
  return formatList;
}
