// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::centering.directive][centering.directive]]
'use strict';

var angular;
var centeringDirective;

angular = require('angular');
centeringDirective = require('./centeringDirective');
angular
  .module('blocks.centering')
  .directive('hzVtCenter', centeringDirective);
// centering.directive ends here
