'use strict';

module.exports = CongratsController;

/**
 * @class CongratsController
 * @description bind the location of an observed bird to the main view
 * @memberOf Controllers
 */
function CongratsController(assistantService, $stateParams, $state, SIGNUP_STATE) {
  var vm;

  vm = this;
  vm.saveBird = saveBird;
  vm.plumage = assistantService.result[$stateParams.plumage];
  // vm.gallery = {};
  // vm.gallery.display = 'inline';
  // vm.gallery['list-style-type'] = 'none';
  // vm.gallery['padding-right'] = '20px';
  // vm.center = {};
  // vm.center['margin-left'] = 'auto';
  // vm.center['margin-right'] = 'auto';
  // vm.align = {};
  // vm.align.display = 'flex';
  // vm.align['justify-content'] = 'center';
  // vm.align['align-items'] = 'center';
  // vm.simpleButton = {};
  // vm.simpleButton.border = '10pt';
  // vm.simpleButton.width = '120pt';
  // vm.simpleButton.height = '33pt';
  // vm.simpleButton.background = 'rgba(235, 235, 235, 1.0)';
  // vm.simpleButton.color = 'rgba(118, 164, 58, 1.0)';
  // vm.simpleButton['border-radius'] = '6pt';
  // vm.simpleButton['font-size'] = '14pt';
  // vm.simpleButton['font-weight'] = '600';
  vm.map = vm.plumage.map;

  function saveBird() {
    var spinnerOptions;

    spinnerOptions = {};

    spinnerOptions.noMessage = true;
    $state.go(SIGNUP_STATE, spinnerOptions);
  }

}
