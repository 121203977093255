// helper functions for configuring the ui-router state
require('../../../shared/blocks/routerHelper'); 
require('../../dashboard');
require('../../dashboard/home');

require('./thanks.module');
require('./thanks.constant');
require('./thanks.run');
require('./thanks.controller');
require('./thanksHeading.controller');
