// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::subheadingDirective.js][subheadingDirective.js]]
'use strict';

module.exports = subheadingDirective;

function subheadingDirective() {
  var directive;

  directive = {};
  directive.restrict = 'E';
  directive.transclude = true;
  directive.scope = {};
  directive.templateUrl = 'app/modules/components/identify/subheading.directive.html';

  return directive;
}
// subheadingDirective.js ends here
