// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::place.controller][place.controller]]
'use strict';
var angular;
var PlaceController;
var controllerDepends;

angular = require('angular');
PlaceController = require('./PlaceController');
controllerDepends = [];
controllerDepends.push('$scope');
controllerDepends.push('$state');
controllerDepends.push('assistantService');
controllerDepends.push('PLACE_STATE');
angular
  .module('app.identify.place')
  .controller('PlaceController', PlaceController);

PlaceController.$inject = controllerDepends;
// place.controller ends here
