'use strict';

var angular;
var multiSelectDirective;
var multiSelectDirectiveDepends;

angular = require('angular');
multiSelectDirective = require('./multiSelectDirective');
multiSelectDirectiveDepends = [];
angular
  .module('blocks.multiSelect')
  .directive('multiSelect', multiSelectDirective);

multiSelectDirective.$inject = multiSelectDirectiveDepends;
