// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::sliderItem.directive.js][sliderItem.directive.js]]
'use strict';

module.exports = function () {
  var directive = {
      restrict: "CEA",
      transclude: true,
      templateUrl: 'app/modules/shared/sliderItem/sliderItem.directive.html',
  };

  return directive;
};
// sliderItem.directive.js ends here
