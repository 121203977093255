// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::congratsSubheading.controller][congratsSubheading.controller]]
'use strict';
var angular;
var CongratsSubheadingController;
var controllerDepends;

angular = require('angular');
CongratsSubheadingController = require('./CongratsSubheadingController');
controllerDepends = [];
controllerDepends.push('CONGRATS_STATE');
controllerDepends.push('$stateParams');
controllerDepends.push('assistantService');
angular
  .module('app.identify.inspect.congrats')
  .controller('CongratsSubheadingController', CongratsSubheadingController);

CongratsSubheadingController.$inject = controllerDepends;
// congratsSubheading.controller ends here
