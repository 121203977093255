// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::size.module][size.module]]
'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push('ui.router');
modDepends.push('blocks.routerHelper');
modDepends.push('blocks.assistant');
//modDepends.push('app.identify.birdParams');
/**
 * @module app/identify/size
 * @description estimate the size of the observed bird
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/identify/birdParams}
 * @see module:app
 * @see module:identify
 */
angular
  .module('app.identify.size', modDepends);
// size.module ends here
