require('../../../shared/blocks/routerHelper'); // helper functions for configuring the ui-router state
require('../../../shared/blocks/assistant');    // add the location to the series of bird id questions
require('../../../shared/blocks/multiSelect');
require('../../../shared/blocks/capitalize');

require('./colors.module');
require('./colors.constant');
require('./colors.run');
require('./colors.controller');
// require('./colors.directive');
require('./colorsHeading.controller');
require('./colorsSubHeading.controller');
require('./colors.decorator');

