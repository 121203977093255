// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::expiredRun][expiredRun]]
'use strict';
var states;
var expired;
var config;

states = [];
expired = {};
config = {};
config.url = '^/expired';
// configure three multiple views for the question, header, and collected data
config.views = {};
// configure the view for the expired question
config.views['@app.dashboard'] = {};
config.views['@app.dashboard'].templateUrl = './app/modules/components/dashboard/' +
                                      'expired/expired.view.html';
config.views['@app.dashboard'].controller = 'ExpiredController as expired';
// configure the header title during the expired question
config.views['heading@app.dashboard'] = {};
config.views['heading@app.dashboard'].templateUrl = './app/modules/components/dashboard/' +
                                                  'expired/expiredHeading.view.html';
config.views['heading@app.dashboard'].controller = 'ExpiredHeadingController as heading';
expired.config = config;
module.exports = expiredRun;

function expiredRun(routerHelper, EXPIRED_STATE) {
  // set the size question as a child of the dashboard state
  expired.state = EXPIRED_STATE;
  states.push(expired);
  routerHelper.configureStates(states);
}
// expiredRun ends here
