// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::app.controller][app.controller]]
'use strict';

var angular = require('angular');
var AppController = require('./AppController');
var controllerDepends = [];

controllerDepends.push('$rootScope');
angular
  .module('app')
  .controller('AppController', AppController);

AppController.$inject = controllerDepends;
// app.controller ends here
