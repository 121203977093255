// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::ExpiredController][ExpiredController]]
'use strict';

/**
 * @class SorryController
 * @description enter the observed birds sorry
 * @memberOf Controllers
 */
module.exports = ExpiredController;

function ExpiredController(HOME_STATE) {
  var vm;

  vm = this;
  vm.home = HOME_STATE;
}
// ExpiredController ends here
