// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::behaviorHeading.controller][behaviorHeading.controller]]
'use strict';
var angular;
var BehaviorHeadingController;
var controllerDepends;

angular = require('angular');
BehaviorHeadingController = require('./BehaviorHeadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('BEHAVIOR_STATE');
angular
  .module('app.identify.behavior')
  .controller('BehaviorHeadingController', BehaviorHeadingController);

BehaviorHeadingController.$inject = controllerDepends;
// behaviorHeading.controller ends here
