// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::sliderMenu.controller.js][sliderMenu.controller.js]]
'use strict';

module.exports = function ($rootScope, $timeout) {
  var vm = this;

  vm.leftVisible = false;
  vm.rightVisible = false;

  vm.close = function () {
    vm.leftVisible = false;
    vm.rightVisible = false;
   };

  vm.showLeft = function (event) {
    vm.leftVisible = true;
     /*Kill the click event before triggerring a broadcast form the
      app controller that would close the slider*/
    event.stopPropagation();
  };

  vm.showRight = function (event) {
    vm.rightVisible = true;
     /*Kill the click event before triggerring a broadcast form the
      app controller that would close the slider*/
    event.stopPropagation();
  };

  $rootScope.$on("documentClicked", _close(vm));
  $rootScope.$on("escapePressed", _close(vm));

  function _close (scope) {
    return function () {
       $timeout(function () {
        scope.close();
      }, 0);
    };
  }
};
// sliderMenu.controller.js ends here
