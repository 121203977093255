'use strict';

module.exports = embedService;

function embedService() {
  var hostsNotLinkingToAAB;
  var parentLocation;

  parentLocation = document.referrer ? new URL(document.referrer) : undefined;
  hostsNotLinkingToAAB = [];
  hostsNotLinkingToAAB.push('ruffapp.ornith.cornell.edu');

  this.isLinkingToAAB = isLinkingToAAB;         // jshint ignore:line
  this.isCrossDomainFrame = isCrossDomainFrame; // jshint ignore:line

  function isLinkingToAAB() {
    // check if AAB is the parent
    if (!isCrossDomainFrame()) return true;

    return !hostsNotLinkingToAAB.includes(parentLocation.hostname);
  }

  function isCrossDomainFrame() {
    // return false if not embedded
    if (parent === window) return false;

    // and test if the parent is a 3rd-party host
    return (parentLocation.protocol !== location.protocol ||
            parentLocation.hostname !== location.hostname ||
            parentLocation.port     !== location.port);
  }

}


