'use strict';

module.exports = windowDirective;

function windowDirective($modalStack, $timeout) {
  var directive;

  directive = {};
  directive.restrict = 'EA';
  directive.replace = true;  
  directive.transclude = true;  
  directive.scope = {};
  directive.scope.index = '@';  
  directive.scope.animate = '=';
  directive.scope.flightindex = '@flightposition';      
  directive.template = '<div tabindex="-1" class="reveal-modal fade {{ windowClass }}"' +
                       'ng-class="{in: animate}" style="display: block; visibility: visible">' +
                       '<div ng-transclude></div>' +
                       '</div>';
  directive.link = postLink;
    
  return directive;

  function postLink(scope, element, attrs) {
    scope.windowClass = attrs.windowClass || '';

    $timeout(function () {
      // trigger CSS transitions
      scope.animate = true;

      // If the modal contains any autofocus elements refocus onto the first one
      if (element[0].querySelectorAll('[autofocus]').length > 0) {
        element[0].querySelectorAll('[autofocus]')[0].focus();
      }
      else{
      // otherwise focus the freshly-opened modal
        element[0].focus();
      }
    });
      
  }

}
      
