'use strict';

/**
 * @class SizeController
 * @description ask what the observed bird's behavior is
 * @memberOf Controllers
 */
module.exports = SizeController;

function SizeController($state, assistantService, SIZE_STATE) {
    var vm;
    var next;

    vm = this;
    vm.selectedSize = assistantService.step(SIZE_STATE).params.size || '';
    vm.selectedLabel = '';
    vm.prompt = assistantService.step(SIZE_STATE).prompt;
    vm.controls = assistantService.step(SIZE_STATE).controls;
    vm.hide = hide;
    vm.show = show;
    vm.select = select;
    vm.submitSize = submitSize;
    vm.showSelection = showSelection;
    next = assistantService.step(SIZE_STATE).next.value;
    vm.showSelection();

    function showSelection(selectedControl) {

       if(selectedControl) {

            vm.selectedSize = selectedControl.value;
            vm.selectedLabel = selectedControl.label;
        }

    } // showSelection()

    function select(selectedIndex) {
        vm.controls.forEach(toggle);

        function toggle(control, index) {
            if (index === selectedIndex) control.selected = true;
            else control.selected = false;

            return;
        }

    }

    function hide() {
        vm.showSelection();
        //if (vm.controls[index].value !== vm.selectedSize) vm.controls[index].visible = false;
    }

    function show(index) {
        vm.selectedLabel = vm.controls[index].label;
    }

    function submitSize() {
        if (vm.selectedSize) {
            assistantService.step(SIZE_STATE).params.size = vm.selectedSize;
            vm.controls.forEach(writeSelectedDisplay);
            $state.go(next);
        }

        function writeSelectedDisplay(control) {
            if (vm.selectedSize === control.value) assistantService.step(SIZE_STATE).display = control.label;

            return;
        }

    }

}
