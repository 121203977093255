'use strict';

module.exports = directive;

function directive() {
  var directive;

  directive = {};
  directive.restrict = 'EA';
  directive.transclude = true;  
  directive.scope = {};
  directive.scope.title = '@title';  
  directive.scope.audio = '@song';
  directive.scope.flightindex = '@flightposition';      
  directive.templateUrl = 'app/modules/shared/blocks/jplayer/jPlayer.directive.html';    
  directive.link = postLink;
    
  return directive;

  function postLink(scope, element, attrs) {
    var containerId;
    var playerId;
    var options;
    var pauseClass;
    var controlId;
      
    // create ids unique to the bird in the flight
    if (scope.flightindex) {
      containerId = 'jp_container_audio_' + scope.flightindex;
      playerId = 'jquery_jplayer_audio_' + scope.flightindex;  
      pauseClass = 'pause_' + scope.flightindex;
      controlId = 'songbutton_' + scope.flightindex;  
      $('#jquery_jplayer_audio').prop('id', playerId);
      $('#jp_container_audio').prop('id', containerId);
      $('#songbutton').prop('id', controlId);          
    } else {  
      containerId = 'jp_container_audio';
      playerId = 'jquery_jplayer_audio';
      pauseClass = 'pause';
      controlId = 'songbutton';
    }
      
    // jPlayer constructor options
    options = {};
    options.cssSelectorAncestor = '#' + containerId;
    options.swfPath = 'dist/assets/js';        
    options.supplied = 'mp3';
    options.solution = 'html, flash';
    options.preload = 'none';
    options.wmode = 'window';
    options.ready = ready;
    options.play = play;
    options.pause = pause;
    options.stop = stop;
    options.ended = ended;

    // attach jPlayer to the element with id=jquery_jplayer_audio
    $('#' + playerId).jPlayer(options);

    // pause when button clicked and song is already playing
    $('#' + controlId).click(toggleSong);      
      
    function ready() {
      var mediaOptions;
        mediaOptions = {};
        
      // queue the track assigned to the audio param  
      mediaOptions.mp3 = scope.audio;
        
      // start the player if autoplay is enabled
      $(this).jPlayer('setMedia', mediaOptions)
             .jPlayer(attrs.autoplay === 'true' ? 'play' : 'stop');
    }

    function play() {
      $(this).addClass(pauseClass);

      // pause other players if there are multiple players
      if (attrs.pauseothers === 'true') $(this).jPlayer('pauseOthers');
    }
      
    function pause() {
      $(this).removeClass(pauseClass);
    }
      
    function stop() {
      $(this).removeClass(pauseClass);        
    }
      
    function ended() {
      $(this).removeClass(pauseClass);
    }
      
    function toggleSong(event) {
      if ($('#' + playerId).hasClass(pauseClass)) {
        $('#' + playerId).jPlayer('pause');
      }
        
    }
      
  }

}
      
