'use strict';

module.exports = matchMediaSingleton;

function matchMediaSingleton($document, $window) {
  // MatchMedia for IE <= 9
  return $window.matchMedia || (function matchMedia(doc, undefined){
      var bool,
          docElem = doc.documentElement,
          refNode = docElem.firstElementChild || docElem.firstChild,
          // fakeBody required for <FF4 when executed in <head>
          fakeBody = doc.createElement("body"),
          div = doc.createElement("div");

      div.id = "mq-test-1";
      div.style.cssText = "position:absolute;top:-100em";
      fakeBody.style.background = "none";
      fakeBody.appendChild(div);

      return function (q) {
          div.innerHTML = "&shy;<style media=\"" + q + "\"> #mq-test-1 { width: 42px; }</style>";
          docElem.insertBefore(fakeBody, refNode);
          bool = div.offsetWidth === 42;
          docElem.removeChild(fakeBody);
          return {
              matches: bool,
              media: q
          };
      };

  }($document[0]));

}
