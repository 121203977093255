// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::expired.module][expired.module]]
'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push('ui.router');
modDepends.push('blocks.routerHelper');
modDepends.push('app.dashboard');
modDepends.push('app.dashboard.home');
/**
 * @module app/dashboard/expired
 * @description enter the expired of an observed bird
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/dashboard/birdParams}
 * @see module:app
 * @see module:dashboard
 */
angular
  .module('app.dashboard.expired', modDepends);
// expired.module ends here
