// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::size.index][size.index]]
require('../../../shared/blocks/routerHelper'); // helper functions for configuring the ui-router state
require('../../../shared/blocks/assistant');    // add the location to the series of bird id questions

require('./size.module');
require('./size.constant');
require('./size.run');
require('./size.controller');
require('./sizeHeading.controller');
require('./sizeSubHeading.controller');
require('./size.decorator');
// size.index ends here
