// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::ColorsSubheadingController][ColorsSubheadingController]]
'use strict';

/**
 * @class ColorsSubheadingController
 * @description bind the collected observation data to a separate view
 * @memberOf Controllers
 */
module.exports = ColorsSubheadingController;

function ColorsSubheadingController(assistantService, PLACE_STATE, WHEN_STATE) {
  var vm;

  vm = this;
  vm.place = assistantService.step(PLACE_STATE).display;
  vm.date = assistantService.step(WHEN_STATE).display;
  vm.hasInputData = !!(vm.place || vm.date);
}
// ColorsSubheadingController ends here
