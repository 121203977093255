'use strict';

require('../../../../shared/blocks/assistant');
require('../../../../shared/blocks/routerHelper');   // helper functions for configuring the ui-router state
require('../../../../components/dashboard/signup');   
require('../../flight');
// require('angular-audio');

require('./congrats.module');
require('./congrats.constant');                    // the congrats state name
require('./congrats.run');                         // configure the congrats state and views
require('./congrats.controller');                  // bind the congrats question model to the main view
require('./congrats.filter');
require('./congratsHeading.controller');            // bind the congrats title to the header view
require('./congratsSubheading.controller');       // bind the observed details to the observation view
