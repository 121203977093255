'use strict';

module.exports = interchangeQueriesManagerSingleton;

function interchangeQueriesManagerSingleton(interchangeQueries) {
  return {
    /**
     * @ngdoc method
     * @name interchangeQueriesManager#add
     * @methodOf mm.foundation.interchange.interchangeQueriesManager
     * @description
     *
     * Add a custom media query in the `interchangeQueries`
     * factory. This method does not allow to update an existing
     * media query.
     *
     * @param {string} name MediaQuery name
     * @param {string} media MediaQuery
     * @returns {boolean} True if the insert is a success
     */
    add: function (name, media) {
      if (!name || !media ||
        !angular.isString(name) || !angular.isString(media) ||
        !!interchangeQueries[name]) {
        return false;
      }
      interchangeQueries[name] = media;
      return true;
    }
      
  };

}
