'use strict';
var angular;
var appServerServiceDecorator;
var assistantServiceDecorator;
var appServerDepends;
var assistantDepends;

angular = require('angular');
appServerServiceDecorator = require('./appServerServiceDecorator');
assistantServiceDecorator = require('./assistantServiceDecorator');
appServerDepends = [];
appServerDepends.push('$delegate');
appServerDepends.push('$resource');
appServerDepends.push('$state');
assistantDepends = [];
assistantDepends.push('$delegate');
assistantDepends.push('removeTagsFilter');
angular
  .module('app.identify.flight')
  .decorator('appServerService', appServerServiceDecorator)
  .decorator('assistantService', assistantServiceDecorator);

assistantServiceDecorator.$inject = assistantDepends;
appServerServiceDecorator.$inject = appServerDepends;
