'use strict';

var angular;
var lazyLoadDirective;
var lazyLoadDirectiveDepends;

angular = require('angular');
lazyLoadDirective = require('./lazyLoadDirective');
lazyLoadDirectiveDepends = [];
lazyLoadDirectiveDepends.push('$window');
lazyLoadDirectiveDepends.push('$document');
lazyLoadDirectiveDepends.push('$timeout');
angular
  .module('blocks.lazyLoad')
  .directive('lazyLoad', lazyLoadDirective);

lazyLoadDirective.$inject = lazyLoadDirectiveDepends;
