'use strict';
var angular;
var appServerServiceDecorator;
var appServerDepends;
var imageCarouselServiceDecorator;
var imageCarouselDepends;

angular = require('angular');
appServerServiceDecorator = require('./appServerServiceDecorator');
imageCarouselServiceDecorator = require('./imageCarouselServiceDecorator');
appServerDepends = [];
appServerDepends.push('$delegate');
appServerDepends.push('$resource');
imageCarouselDepends = [];
imageCarouselDepends.push('$delegate');
angular
  .module('app.identify.inspect')
  .decorator('appServerService', appServerServiceDecorator)
  .decorator('imageCarouselService', imageCarouselServiceDecorator);

appServerServiceDecorator.$inject = appServerDepends;
imageCarouselServiceDecorator.$inject = imageCarouselDepends;

