// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::ExpiredHeadingController][ExpiredHeadingController]]
'use strict';

/**
 * @class IdBirdsHeadingController
 * @description bind the sorry question title to a separate view
 * @memberOf Controllers
 */
module.exports = IdBirdsHeadingController;

function IdBirdsHeadingController(EXPIRED_STATE) {
  var vm;

  vm = this;
  vm.state = EXPIRED_STATE;
}
// ExpiredHeadingController ends here
