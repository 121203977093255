'use strict';

var states = [];
var app = {};
var config = {};

// configure the client app abstract state
config.url = '/app';
config.template = '<ui-view/>';
config.abstract = true;
config.controller = 'AppController as app';
app.state = 'app';
app.config = config;
states.push(app);

module.exports = appRun;

function appRun(routerHelper, sessionService) {
  routerHelper.configureStates(states);
  sessionService.selectLandingPage('app.dashboard.home', 'app.dashboard.signup', 'app.dashboard.thanks');
}
