'use strict';

/**
 * @function size-assistantServiceDecorator
 * @description add 'size' state data for use in the steps display and navigator
 * @memberOf Decorators
 */
module.exports = assistantServiceDecorator;

function assistantServiceDecorator($delegate, SIZE_STATE) {
  var properties;
  var sparrow;
  var sparrowToRobin;
  var robin;
  var robinToCrow;
  var crow;
  var crowToGoose;
  var goose;

  // add the size step to the assistant
  properties = {};
  properties.params = {};
  properties.params.size = '';
  properties.prompt = 'What size was the bird?';
  properties.restoreDefault = restore;
  $delegate.step(SIZE_STATE, properties);

  // add the sparrow control
  sparrow = {};
  sparrow.value = 'size1';
  sparrow.label = 'sparrow-sized or smaller';
  sparrow.icon = 'home';
  sparrow.cssName = '';
  $delegate.addControl(SIZE_STATE, sparrow);

  // add the sparrowToRobin control
  sparrowToRobin = {};
  sparrowToRobin.value = 'size2';
  sparrowToRobin.label = 'between sparrow and robin';
  sparrowToRobin.icon = 'home';
  sparrowToRobin.cssName = '';
  $delegate.addControl(SIZE_STATE, sparrowToRobin);

  // add the robin control
  robin = {};
  robin.value = 'size3';
  robin.label = 'robin-sized';
  robin.icon = 'home';
  robin.cssName = '';
  $delegate.addControl(SIZE_STATE, robin);

  // add the robinToCrow control
  robinToCrow = {};
  robinToCrow.value = 'size4';
  robinToCrow.label = 'between robin and crow';
  robinToCrow.icon = 'home';
  robinToCrow.cssName = '';
  $delegate.addControl(SIZE_STATE, robinToCrow);

  // add the robinToCrow control
  crow = {};
  crow.value = 'size5';
  crow.label = 'crow-sized';
  crow.icon = 'home';
  crow.cssName = '';
  $delegate.addControl(SIZE_STATE, crow);

  // add the crowToGoose control
  crowToGoose = {};
  crowToGoose.value = 'size6';
  crowToGoose.label = 'between crow and goose';
  crowToGoose.icon = 'home';
  crowToGoose.cssName = '';
  $delegate.addControl(SIZE_STATE, crowToGoose);

  // add the goose control
  goose = {};
  goose.value = 'size7';
  goose.label = 'goose-sized';
  goose.icon = 'home';
  goose.cssName = '';
  $delegate.addControl(SIZE_STATE, goose);

  return $delegate;

  function restore() {
    this.params.size = '';  // jshint ignore:line
  }
 
}
