'use strict';

/**
 * @function colors-assistantServiceDecorator
 * @description add 'colors' state data for use in the steps display and navigator
 * @memberOf Decorators
 */
module.exports = assistantServiceDecorator;

function assistantServiceDecorator($delegate, COLORS_STATE) {
  var properties;
  var black;
  var gray;
  var white;
  var brown;
  var red;
  var yellow;
  var green;
  var blue;
  var orange;

  // add the colors step to the assistant
  properties = {};
  properties.params = {};
  properties.params.colors = '';
  properties.prompt = 'What were the main colors?';
  properties.restoreDefault = restore;
  $delegate.step(COLORS_STATE, properties);

  // add the black control
  black = {};
  black.value = 'black';
  black.cssName = 'black';
  black.label = 'Black';
  $delegate.addControl(COLORS_STATE, black);

  // add the gray control
  gray = {};
  gray.value = 'gray';
  gray.cssName = 'gray';
  gray.label = 'Gray';
  $delegate.addControl(COLORS_STATE, gray);

  // add the white control
  white = {};
  white.value = 'white';
  white.cssName = 'white';
  white.label = 'White';
  $delegate.addControl(COLORS_STATE, white);

  // add the brown control
  brown = {};
  brown.value = 'brown';
  brown.cssName = 'buff';
  brown.label = 'Buff/Brown';
  $delegate.addControl(COLORS_STATE, brown);

  // add the red control
  red = {};
  red.value = 'red';
  red.cssName = 'red-rufous';
  red.label = 'Red/Rufous';
  $delegate.addControl(COLORS_STATE, red);

  // add the yellow control
  yellow = {};
  yellow.value = 'yellow';
  yellow.cssName = 'yellow';
  yellow.label = 'Yellow';
  $delegate.addControl(COLORS_STATE, yellow);

  // add the green control
  green = {};
  green.value = 'green';
  green.cssName = 'olive-green';
  green.label = 'Olive/Green';
  $delegate.addControl(COLORS_STATE, green);

  // add the blue control
  blue = {};
  blue.value = 'blue';
  blue.cssName = 'blue';
  blue.label = 'Blue';
  $delegate.addControl(COLORS_STATE, blue);

  // add the orange control
  orange = {};
  orange.value = 'orange';
  orange.cssName = 'orange';
  orange.label = 'Orange';
  $delegate.addControl(COLORS_STATE, orange);

  return $delegate;

  function restore() {
    // unselect each color choice
    this.controls.forEach(unselectColor); // jshint ignore:line

    // unselect a given color control
    function unselectColor(control) {
      control.selected = false;
    }

  }

}
