'use strict';
var angular;
var assistantServiceDecorator;
var assistantServiceDecoratorDepends;

angular = require('angular');
assistantServiceDecorator = require('./assistantServiceDecorator');
assistantServiceDecoratorDepends = [];
assistantServiceDecoratorDepends.push('$delegate');
assistantServiceDecoratorDepends.push('PLACE_STATE');
angular
  .module('app.identify.place')
  .decorator('assistantService', assistantServiceDecorator);

assistantServiceDecorator.$inject = assistantServiceDecoratorDepends;
