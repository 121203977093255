'use strict';

/**
 * @class IdentifyController
 * @description provides access to the five-step bird identification process
 * @memberOf Controllers
 */
module.exports = IdentifyController;

function IdentifyController(HOME_STATE, $modal, $state) {
  var vm;

  vm = this;    // jshint ignore:line
  vm.home = HOME_STATE;
  vm.open = modalOpen;

  // Prompt the user when the home icon is pressed
  function modalOpen() {
    var params;
    var modalInstance;

    // Configure the modal parameters
    params = {};

    // Confirm the user is ok with losing the current bird data
    params.template = '' +
    '<div>\n' +
      '<h4>Confirm</h4>\n' +
      '<p>Are you sure you want to start over?</p>\n' +
      '<button ng-click=\"modal.cancel()\">\n' +
      '  No \n' +
      '</button>\n' +
      '<button ng-click=\"modal.ok()\">\n' +
      '  Yes\n' +
      '</button></div>';
    params.controller = ModalController;
    params.controllerAs = 'modal';

    // Inject dependencies otherwise the providers won't be recognized after
    // minification
    params.controller.$inject = ['$modalInstance', '$state'];

    //params.backdrop = false;
    params.size = 'tiny';

    // Open the modal
    modalInstance = $modal.open(params);

    // Issue a modal promise for results
    modalInstance.result.then(goHome, exit);

    function ModalController($modalInstance) {
      var vm = this;
      vm.cancel = cancel;
      vm.ok = ok;

      function ok() {
        $modalInstance.close();
      }

      function cancel() {
        $modalInstance.dismiss('cancel');
      }

    }

    // Go home if the modal is closed successfully
    function goHome() {
      $state.go(HOME_STATE);
    }

    // Exit out if the modal is cancelled
    function exit() {

    }

  }

}
