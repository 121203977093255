// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::CongratsSubheadingController][CongratsSubheadingController]]
'use strict';

/**
 * @class CongratsSubheadingController
 * @description bind the collected observation data to a separate view
 * @memberOf Controllers
 */
module.exports = CongratsSubheadingController;

function CongratsSubheadingController(CONGRATS_STATE, $stateParams, assistantService) {
  var vm;

  vm = this;
  vm.state = CONGRATS_STATE;
  vm.prior = '^';
  vm.plumage = $stateParams.plumage;
  vm.commonName = assistantService.result[$stateParams.plumage].commonName;
}
// CongratsSubheadingController ends here
