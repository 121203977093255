'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push('ui.router');
modDepends.push('blocks.routerHelper');
modDepends.push('app.dashboard');
/**
 * @module app/dashboard/sorry
 * @description enter the sorry of an observed bird
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/dashboard/birdParams}
 * @see module:app
 * @see module:dashboard
 */
angular
  .module('app.dashboard.sorry', modDepends);
