// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::SizeHeadingController][SizeHeadingController]]
'use strict';

/**
 * @class SizeHeadingController
 * @description bind the size question title to a separate view
 * @memberOf Controllers
 */
module.exports = SizeHeadingController;

function SizeHeadingController(assistantService, SIZE_STATE) {
  var vm;

  vm = this;
  vm.totalSteps = assistantService.totalSteps();
  vm.currentStep = 1 + assistantService.index(SIZE_STATE);
  vm.prior = assistantService.step(SIZE_STATE).prior.value;
}
// SizeHeadingController ends here
