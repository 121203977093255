'use strict';
var angular;
var InspectController;
var controllerDepends;

angular = require('angular');
InspectController = require('./InspectController');
controllerDepends = [];
controllerDepends.push('$sanitize');
controllerDepends.push('$state');
controllerDepends.push('$stateParams');
controllerDepends.push('imageCarouselService');
controllerDepends.push('appServerService');
controllerDepends.push('assistantService');
controllerDepends.push('sessionService');
controllerDepends.push('embedService');
controllerDepends.push('DETAILS_STATE');
controllerDepends.push('SPINNER_STATE');
controllerDepends.push('INSPECT_STATE');
controllerDepends.push('SIGNUP_STATE');
controllerDepends.push('EXPIRED_STATE');
controllerDepends.push('CONGRATS_STATE');
controllerDepends.push('PLACE_STATE');
controllerDepends.push('WHEN_STATE');
controllerDepends.push('SIZE_STATE');
controllerDepends.push('COLORS_STATE');
controllerDepends.push('BEHAVIOR_STATE');
controllerDepends.push('capitalizeFilter');
angular
  .module('app.identify.inspect')
  .controller('InspectController', InspectController);

InspectController.$inject = controllerDepends;
