 'use strict';
 var angular;
 var modDepends;

 angular = require('angular');
 modDepends = [];
 modDepends.push('blocks.capitalize');
 modDepends.push('blocks.removeTags');
 modDepends.push('blocks.appServer');
 modDepends.push('blocks.assistant');
 modDepends.push('blocks.centering');
 modDepends.push('blocks.dataStructures');
 modDepends.push('blocks.embed');
 modDepends.push('blocks.imageCarousel');
 modDepends.push('blocks.lazyLoad');
 modDepends.push('blocks.modal');
 modDepends.push('blocks.multiSelect');
 modDepends.push('blocks.routerHelper');
// modDepends.push('blocks.slidingDrawer');
 modDepends.push('blocks.session');
// modDepends.push('blocks.user');
 /**
  * @module blocks
  * @description provide modules for use across multiple applications
  */
 angular
   .module('blocks', modDepends);

