// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::SpinnerSubheadingController][SpinnerSubheadingController]]
'use strict';

/**
 * @class SpinnerSubheadingController
 * @description bind the collected observation data to a separate view
 * @memberOf Controllers
 */
module.exports = SpinnerSubheadingController;

function SpinnerSubheadingController() {
  var vm;

  vm = this;
}
// SpinnerSubheadingController ends here
