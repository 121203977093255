// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::assistant.module][assistant.module]]
'use strict';

var angular = require('angular');
var modDepends = [];

modDepends.push('ui.router');
modDepends.push('blocks.dataStructures');
/**
 * @module app/blocks/assistant
 * @description API for stepping through bird identification
 * @requires {@link module:app/blocks/dataStructures}
 * @see module:app
 * @see module:blocks
 */
angular
  .module('blocks.assistant', modDepends);
// assistant.module ends here
