// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::spinner.run][spinner.run]]
'use strict';
var angular;
var spinnerRun;
var runDepends;

angular = require('angular');
spinnerRun = require('./spinnerRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('SPINNER_STATE');
angular
  .module('app.identify.spinner')
  .run(spinnerRun);

spinnerRun.$inject = runDepends;
// spinner.run ends here
