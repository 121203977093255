// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::birdParams.service][birdParams.service]]
'use strict';

var angular = require('angular');
var birdParamsService = require('./birdParamsService');
var serviceDepends = [];

serviceDepends.push('$state');
serviceDepends.push('inventoryService');
serviceDepends.push('matchService');
angular
  .module('app.identify.birdParams')
  .service('birdParamsService', birdParamsService);

birdParamsService.$inject = serviceDepends;
// birdParams.service ends here
