'use strict';

module.exports = WhenController;

/**
 * @class WhenController
 * @description bind the day of an observed bird to the main view
 * @memberOf Controllers
 */
function WhenController($state, $filter, assistantService, calendarService, WHEN_STATE) {
  var vm;
  var next;

  vm = this;
  vm.date = new Date();
  vm.prompt = assistantService.step(WHEN_STATE).prompt;
  vm.submitTime = submitTime;
  vm.resetDate = resetDate;
  next = assistantService.step(WHEN_STATE).next.value;

  function resetDate() {
    vm.date = new Date();
  }

  function submitTime() {
    assistantService.step(WHEN_STATE).params.date = $filter('date')(vm.date, 'yyyy') + '-' +
                                                    $filter('date')(vm.date, 'MM') + '-' +
                                                    $filter('date')(vm.date, 'dd');
    assistantService.step(WHEN_STATE).display = $filter('date')(vm.date, 'MMM dd');
    $state.go(next);
  }

}
