// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::HomeHeadingController][HomeHeadingController]]
'use strict';

/**
 * @class HomeHeadingController
 * @description bind the home question title to a separate view
 * @memberOf Controllers
 */
module.exports = HomeHeadingController;

function HomeHeadingController(HOME_STATE) {
  var vm;

  vm = this;
  vm.state = HOME_STATE;
}
// HomeHeadingController ends here
