// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::SignupHeadingController][SignupHeadingController]]
'use strict';

/**
 * @class SignupHeadingController
 * @description bind the signup question title to a separate view
 * @memberOf Controllers
 */
module.exports = SignupHeadingController;

function SignupHeadingController(SIGNUP_STATE) {
  var vm;

  vm = this;
  vm.state = SIGNUP_STATE;
}
// SignupHeadingController ends here
