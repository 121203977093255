'use strict';

/**
 * @class SorryController
 * @description enter the observed birds sorry
 * @memberOf Controllers
 */
module.exports = SorryController;

function SorryController() {
  var vm;

  vm = this;
}
