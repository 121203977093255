// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::escapeApostrophe.filter][escapeApostrophe.filter]]
'use strict';

module.exports = function() {
  return escapeApostrophe;

  function escapeApostrophe(text) {
    return text.replace(/'/g, "\\\'");
  }
};
// escapeApostrophe.filter ends here
