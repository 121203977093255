'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push('blocks.appServer');
modDepends.push('blocks.assistant');
modDepends.push('blocks.removeTags');
/**
 * @module app/identify/flight
 * @description enter the flight of an observed bird
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/identify/birdParams}
 * @see module:app
 * @see module:identify
 */
angular
  .module('app.identify.flight', modDepends);
