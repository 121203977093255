"use strict";

var angular = require("angular");

angular.module("datetime", []);
angular.module("datetime").constant("datetimePlaceholder", {
  year: "(year)",
  yearShort: "(year)",
  month: "(month)",
  date: "(date)",
  day: "(day)",
  hour: "(hour)",
  hour12: "(hour12)",
  minute: "(minute)",
  second: "(second)",
  millisecond: "(millisecond)",
  ampm: "(AM/PM)",
  week: "(week)"
});