// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::thanksHeading.controller][thanksHeading.controller]]
'use strict';
var angular;
var ThanksHeadingController;
var controllerDepends;

angular = require('angular');
ThanksHeadingController = require('./ThanksHeadingController');
controllerDepends = [];
controllerDepends.push('THANKS_STATE');
angular
  .module('app.dashboard.thanks')
  .controller('ThanksHeadingController', ThanksHeadingController);

ThanksHeadingController.$inject = controllerDepends;
// thanksHeading.controller ends here
