'use strict';
var angular;
var IdentifyController;
var controllerDepends;

angular = require('angular');
IdentifyController = require('./IdentifyController');
controllerDepends = [];
controllerDepends.push('HOME_STATE');
controllerDepends.push('$modal');
controllerDepends.push('$state');
angular
  .module('app.identify')
  .controller('IdentifyController', IdentifyController);

IdentifyController.$inject = controllerDepends;

