'use strict';

module.exports = function () {
  function capitalize(word, position) {
    if (typeof word !== 'string') return word;

    position = position - 1 || 0;

    return word.split('').reduce(function(memo, letter, index) {
      if (index === position) memo = memo + letter.toUpperCase();
        else memo = memo + letter;

      return memo;
    }, '');
  }

  return function(word, position) {
    return capitalize(word, position);
  };
};
