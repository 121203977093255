// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::truncate.index][truncate.index]]
'use strict';

var angular = require('angular');
var characters = require('./characters.filter');
var splitCharacters = require('./splitCharacters.filter');
var words = require('./words.filter');

angular
  .module('app.truncate', [])
    .filter('characters', characters)
    .filter('splitCharacters', splitCharacters)
    .filter('words', words);
// truncate.index ends here
