'use strict';
var angular;
var sorryRun;
var runDepends;

angular = require('angular');
sorryRun = require('./sorryRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('SORRY_STATE');
angular
  .module('app.dashboard.sorry')
  .run(sorryRun);

sorryRun.$inject = runDepends;
