'use strict';

module.exports = modalStackSingleton;

function modalStackSingleton($window, $transition, $timeout, $document, $compile, $rootScope, $$stackedMap) {
  var OPENED_MODAL_CLASS = 'modal-open';

  var backdropDomEl, backdropScope, cssTop;
  var openedWindows = $$stackedMap.createNew();
  var $modalStack = {};

  function backdropIndex() {
    var topBackdropIndex = -1;
    var opened = openedWindows.keys();
    for (var i = 0; i < opened.length; i++) {
      if (openedWindows.get(opened[i]).value.backdrop) {
        topBackdropIndex = i;
      }
    }
    return topBackdropIndex;
  }

  $rootScope.$watch(backdropIndex, function(newBackdropIndex){
    if (backdropScope) {
      backdropScope.index = newBackdropIndex;
    }
  });

  function removeModalWindow(modalInstance) {
    var parent = $document.find(modalInstance.options.parent).eq(0);
    var modalWindow = openedWindows.get(modalInstance).value;

    //clean up the stack
    openedWindows.remove(modalInstance);

    //remove window DOM element
    removeAfterAnimate(modalWindow.modalDomEl, modalWindow.modalScope, 300, function() {
      modalWindow.modalScope.$destroy();
      parent.toggleClass(OPENED_MODAL_CLASS, openedWindows.length() > 0);
      checkRemoveBackdrop();
    });
  }

  function checkRemoveBackdrop() {
      //remove backdrop if no longer needed
      if (backdropDomEl && backdropIndex() == -1) {
        var backdropScopeRef = backdropScope;
        removeAfterAnimate(backdropDomEl, backdropScope, 150, function () {
          backdropScopeRef.$destroy();
          backdropScopeRef = null;
        });
        backdropDomEl = undefined;
        backdropScope = undefined;
      }
  }

  function removeAfterAnimate(domEl, scope, emulateTime, done) {
    // Closing animation
    scope.animate = false;

    var transitionEndEventName = $transition.transitionEndEventName;
    if (transitionEndEventName) {
      // transition out
      var timeout = $timeout(afterAnimating, emulateTime);

      domEl.bind(transitionEndEventName, function () {
        $timeout.cancel(timeout);
        afterAnimating();
        scope.$apply();
      });
    } else {
      // Ensure this call is async
      $timeout(afterAnimating, 0);
    }

    function afterAnimating() {
      if (afterAnimating.done) {
        return;
      }
      afterAnimating.done = true;

      domEl.remove();
      if (done) {
        done();
      }
    }
  }

  function calculateModalTop(modalElement, offset) {
    if (angular.isUndefined(offset)) {
      offset = 0;
    }
    var scrollY = $window.pageYOffset || 0;
    return offset + scrollY;
  }

  $document.bind('keydown', function (evt) {
    var modal;

    if (evt.which === 27) {
      modal = openedWindows.top();
      if (modal && modal.value.keyboard) {
        $rootScope.$apply(function () {
          $modalStack.dismiss(modal.key);
        });
      }
    }
  });

  $modalStack.open = function (modalInstance, modal) {
    modalInstance.options = {
      deferred: modal.deferred,
      modalScope: modal.scope,
      backdrop: modal.backdrop,
      keyboard: modal.keyboard,
      parent: modal.parent
    };
    openedWindows.add(modalInstance, modalInstance.options);

    var parent = $document.find(modal.parent).eq(0),
        currBackdropIndex = backdropIndex();

    if (currBackdropIndex >= 0 && !backdropDomEl) {
      backdropScope = $rootScope.$new(true);
      backdropScope.index = currBackdropIndex;
      backdropDomEl = $compile('<div modal-backdrop></div>')(backdropScope);
      parent.append(backdropDomEl);
    }

    // Create a faux modal div just to measure its
    // distance to top
    var faux = angular.element('<div class="reveal-modal" style="z-index:-1""></div>');
    parent.append(faux[0]);
    cssTop = parseInt($window.getComputedStyle(faux[0]).top) || 0;
    var openAt = calculateModalTop(faux, cssTop);
    faux.remove();

    var angularDomEl = angular.element('<div modal-window style="visibility: visible; top:' + openAt +'px;"></div>')
      .attr({
        'window-class': modal.windowClass,
        'index': openedWindows.length() - 1,
        'animate': 'animate'
      });
    angularDomEl.html(modal.content);

    var modalDomEl = $compile(angularDomEl)(modal.scope);
    openedWindows.top().value.modalDomEl = modalDomEl;
    parent.append(modalDomEl);
    parent.addClass(OPENED_MODAL_CLASS);
  };

  $modalStack.reposition = function (modalInstance) {
    var modalWindow = openedWindows.get(modalInstance).value;
    if (modalWindow) {
      var modalDomEl = modalWindow.modalDomEl;
      var top = calculateModalTop(modalDomEl, cssTop);
      modalDomEl.css('top', top + "px");
    }
  };

  $modalStack.close = function (modalInstance, result) {
    var modalWindow = openedWindows.get(modalInstance);
    if (modalWindow) {
      modalWindow.value.deferred.resolve(result);
      removeModalWindow(modalInstance);
    }
  };

  $modalStack.dismiss = function (modalInstance, reason) {
    var modalWindow = openedWindows.get(modalInstance);
    if (modalWindow) {
      modalWindow.value.deferred.reject(reason);
      removeModalWindow(modalInstance);
    }
  };

  $modalStack.dismissAll = function (reason) {
    var topModal = this.getTop();
    while (topModal) {
      this.dismiss(topModal.key, reason);
      topModal = this.getTop();
    }
  };

  $modalStack.getTop = function () {
    return openedWindows.top();
  };

  return $modalStack;
}
      
