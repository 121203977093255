'use strict';

/**
 * @function flight-appServerServiceDecorator
 * @description add 'flight' state data for use in the steps display and navigator
 * @memberOf Decorators
 */
module.exports = appServerServiceDecorator;

function appServerServiceDecorator($delegate, $resource, $state) {
  var customActions;

  customActions = {};
  customActions.newUser = {};
  customActions.newUser.method = 'PUT';

  $delegate.Register = $resource('/register', null, customActions);
  $delegate.putAddress = putAddress.bind($delegate);

  return $delegate;

  function putAddress(body, landingStates, webStorage) {
    var spinnerOptions;
    var stateOptions;

    stateOptions = {};
    stateOptions.location = 'replace';

    // send the user's email address for registration
    this  // jshint ignore:line
      .Register
      .newUser(null, body, registerSuccess, registerFailure);

    // go to the in-progress spinner
    spinnerOptions = {};
    spinnerOptions.noMessage = true;
    $state.go(landingStates.spinner, spinnerOptions);

    function registerSuccess(newStatus) {
      // update the session state
      webStorage.token = newStatus.token;

      // store the session state in human-readable format for dev work
      // webStorage.status = newStatus.payload;

      // go to the entry point if the address is confirmed
      if (newStatus.payload.email.isConfirmed)
        $state.go(landingStates.entry, null, stateOptions);

        // otherwise tell the user to confirm the email
      else $state.go(landingStates.thanks, null, stateOptions);
    }

    function registerFailure() {
      // tell the user to sign up if there is an error
      $state.go(landingStates.signup, null, stateOptions);

      // // if the token expired...
      // if (err.data.errors[0].name === 'TokenExpiredError') {

      //   // and if the trial period is active navigate to the signup page
      //   if (webStorage.status.trial.isActive)
      //     $state.go(landingStates.signup);

      //   // or if the grace period is active navigate to the email reminder
      //   page
      //   if (webStorage.status.email.grace) $state.go(landingStates.expired);

      // // otherwise if the trial attempts limit reached...
      // } else if (err.data.errors[0].name === 'TrialLimitError') {

      //   // navigate to the signup page
      //   $state.go(landingStates.signup);

      // // otherwise if the token was not valid...
      // } else if (err.data.errors[0].name === 'JsonWebTokenError' ||
      //            err.data.errors[0].name === 'Unauthorized') {

      //   // navigate to the signup page
      //   $state.go(landingStates.signup);

      // } else {

      //   // go to the 'thank you' screen
      //   $state.go(landingStates.thanks);
      // }

    }

  }

}
