// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::start.index][start.index]]
'use strict';
var angular = require('angular');
var sliderMenuDirective = require('./sliderMenu.directive');
var SliderMenuController = require('./sliderMenu.controller');

angular
  .module('app')
  .directive('mwSliderMenu', sliderMenuDirective)
  .controller('SliderMenuController', SliderMenuController);

SliderMenuController.$inject = ['$rootScope', '$timeout'];
// start.index ends here
