// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::bestMatchRun][bestMatchRun]]
'use strict';

var states = [];
var bestMatch = {};
var config = {};

config.url = '^/bestMatch';
config.templateUrl = './app/modules/components/identify/bestMatch/bestMatch.view.html';
config.controller = 'BestMatchController as bestMatch';
bestMatch.state = 'app.identify.bestMatch';
bestMatch.config = config;
states.push(bestMatch);
module.exports = function (routerHelper) {
  routerHelper.configureStates(states);
};
// bestMatchRun ends here
