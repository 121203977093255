'use strict';

module.exports = DetailsController;

/**
 * @class DetailsController
 * @description bind the location of an observed bird to the main view
 * @memberOf Controllers
 */
function DetailsController(imageCarouselService, assistantService, $stateParams) {
  var vm;

  vm = this;
  vm.slickConfig = imageCarouselService.slickConfig;

  // display selected bird
  vm.plumage = assistantService.result[$stateParams.plumage];
  vm.flightIndex = $stateParams.plumage;
  vm.map = vm.plumage.map;

}

