// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::whenHeading.controller][whenHeading.controller]]
'use strict';
var angular;
var WhenHeadingController;
var controllerDepends;

angular = require('angular');
WhenHeadingController = require('./WhenHeadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('WHEN_STATE');
angular
  .module('app.identify.when')
  .controller('WhenHeadingController', WhenHeadingController);

WhenHeadingController.$inject = controllerDepends;
// whenHeading.controller ends here
