// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::matchService][matchService]]
'use strict';

/**
 * @function matchService
 * @memberOf Services
 * @param {Object} $resource - a reference to the angular service for requests made via RESTful APIs
 */
module.exports = function ($resource) {
  var matchRoute = '/match' +
                   '/:lat' +
                   '/:lng' +
                   '/:date' +
                   '/:size' +
                   '/:colors' +
                   '/:behavior';

  return $resource(matchRoute);
};
// matchService ends here
