'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push(require('angular-touch'));
modDepends.push('mm.foundation.mediaQueries');
// modDepends.push('blocks.zf.transition');
// modDepends.push('blocks.zf.modal');
modDepends.push('mm.foundation.modal');
modDepends.push('app.identify.idSteps');
modDepends.push('app.identify.place');
modDepends.push('app.identify.when');
modDepends.push('app.identify.size');
modDepends.push('app.identify.colors');
modDepends.push('app.identify.behavior');
modDepends.push('app.identify.inspect');
modDepends.push('app.identify.flight');
modDepends.push('app.identify.spinner');
modDepends.push('app.identify.bestMatch');
modDepends.push('app.dashboard');
modDepends.push('blocks.routerHelper');
modDepends.push('blocks.centering');
// modDepends.push('blocks.modal');
/**
 * @module app/identify
 * @description identify a bird with a simple five-step process
 * @see module:app
 */
angular
  .module('app.identify', modDepends);
