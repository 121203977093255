// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::dataStructuresServiceDecorator][dataStructuresServiceDecorator]]
'use strict';

/**
 * @function assistant-dataStructuresServiceDecorator
 * @description add bird ID properties to the linked list node properties
 * @memberOf Decorators
 */
module.exports = dataStructuresServiceDecorator;

function dataStructuresServiceDecorator($delegate) {
  var constructor;

  constructor = $delegate.link;
  Link.prototype = Object.create(constructor.prototype);
  Link.prototype.constructor = Link;
  $delegate.link = Link;

  return $delegate;

  function Link(value) {
    constructor.call(this, value);
    this.params = {};
    this.display = '';
    this.controls = [];
    this.prompt = '';
    this.restoreDefault = null;
  }

}
// dataStructuresServiceDecorator ends here
