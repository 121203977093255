'use strict';

/**
 * @function behavior-assistantServiceDecorator
 * @description add 'behavior' state data for use in the steps display and navigator
 * @memberOf Decorators
 */
module.exports = assistantServiceDecorator;

function assistantServiceDecorator($delegate, BEHAVIOR_STATE) {
  var properties;
  var feeder;
  var fence;
  var flying;
  var ground;
  var trees;
  var water;

  // add the size step to the assistant
  properties = {};
  properties.params = {};
  properties.params.behavior = '';
  properties.prompt = 'Was the bird... ?';
  properties.restoreDefault = restore;
  $delegate.step(BEHAVIOR_STATE, properties);

  // add the feeder control
  feeder = {};
  feeder.value = 'feeder';
  feeder.label = 'Eating at a feeder';
  feeder.icon = '';
  feeder.cssName = '';
  $delegate.addControl(BEHAVIOR_STATE, feeder);

  // add the water control
  water = {};
  water.value = 'water';
  water.label = 'Swimming or wading';
  water.icon = '';
  water.cssName = '';
  $delegate.addControl(BEHAVIOR_STATE, water);

  // add the ground control
  ground = {};
  ground.value = 'ground';
  ground.label = 'On the ground';
  ground.icon = '';
  ground.cssName = '';
  $delegate.addControl(BEHAVIOR_STATE, ground);

  // add the trees control
  trees = {};
  trees.value = 'trees';
  trees.label = 'In trees or bushes';
  trees.icon = '';
  trees.cssName = '';
  $delegate.addControl(BEHAVIOR_STATE, trees);

  // add the fence control
  fence = {};
  fence.value = 'fence';
  fence.label = 'On a fence or wire';
  fence.icon = '';
  fence.cssName = '';
  $delegate.addControl(BEHAVIOR_STATE, fence);

  // add the flying control
  flying = {};
  flying.value = 'flying';
  flying.label = 'Soaring or flying';
  flying.icon = '';
  flying.cssName = '';
  $delegate.addControl(BEHAVIOR_STATE, flying);

  return $delegate;

  function restore() {
    this.params.behavior = '';  // jshint ignore:line
  }

}

