'use strict';
var angular;
var ThanksController;
var controllerDepends;

angular = require('angular');
ThanksController = require('./ThanksController');
controllerDepends = [];
controllerDepends.push('HOME_STATE');
controllerDepends.push('SIGNUP_STATE');
controllerDepends.push('$stateParams');
controllerDepends.push('$state');
controllerDepends.push('sessionService');
angular
  .module('app.dashboard.thanks')
  .controller('ThanksController', ThanksController);

ThanksController.$inject = controllerDepends;
