'use strict';

var angular;
var interchangeDirective;
var interchangeDirectiveDepends;

angular = require('angular');
interchangeDirective = require('./interchangeDirective');
interchangeDirectiveDepends = [];
interchangeDirectiveDepends.push('$window');
interchangeDirectiveDepends.push('$rootScope');
interchangeDirectiveDepends.push('interchangeTools');
angular
  .module('blocks.zf.interchange')
  .directive('interchange', interchangeDirective);

interchangeDirective.$inject = interchangeDirectiveDepends;
