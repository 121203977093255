// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::locationPredictions.directive][locationPredictions.directive]]
'use strict';

var angular = require('angular');
var locationPredictions = require('./locationPredictions');

angular
  .module('app.identify.place.locator')
  .directive('locationPredictions', locationPredictions);
// locationPredictions.directive ends here
