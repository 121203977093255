// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::detail.controller][detail.controller]]
'use strict';

var angular = require('angular');
var DetailController = require('./DetailController');
var controllerDepends = [];

controllerDepends.push('$state');
controllerDepends.push('inventoryService');
controllerDepends.push('$window');
angular
  .module('app.identify.bestMatch.detail')
  .controller('DetailController', DetailController);

DetailController.$inject = controllerDepends;
// detail.controller ends here
