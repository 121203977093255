// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::InspectSubheadingController][InspectSubheadingController]]
'use strict';

/**
 * @class InspectSubheadingController
 * @description bind the collected observation data to a separate view
 * @memberOf Controllers
 */
module.exports = InspectSubheadingController;

function InspectSubheadingController(assistantService, PLACE_STATE, WHEN_STATE) {
  var vm;

  vm = this;
  vm.place = assistantService.step(PLACE_STATE).display;
  vm.date = assistantService.step(WHEN_STATE).display;
  vm.hasInputData = !!(vm.place || vm.date);
}
// InspectSubheadingController ends here
