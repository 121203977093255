'use strict';
var states;
var identify;
var config;

states = [];
identify = {};
config = {};
config.url = '^/identify';
config.templateUrl = './app/modules/components/identify/identify.view.html';
config.controller = 'IdentifyController as identify';
identify.state = 'app.identify';
identify.config = config;
states.push(identify);
module.exports = function (routerHelper) {
  routerHelper.configureStates(states);
};

