module.exports = assistantService;

/**
 * @class assistantService
 * @description ask where a bird to be identified was observed
 * @memberOf Services
 * @requires {@link module:inventoryService}
 * @requires {@link module:matchService}
 */
function assistantService(dataStructuresService) {
  this.steps = dataStructuresService.linkedList();
  this.step = step;
  this.totalSteps = totalSteps;
  this.index = index;
  this.addControl = addControl;
  this.StepControl = StepControl;
  this.params = params;
  this.reset = reset;

  // Reset inputs for all steps in the assistant
  function reset() {
    this.steps.traverse(resetInputs);

    // Reset the input only if the assistant question has
    // a defined function for restoring defualt values
    function resetInputs(step) {
      if (step.restoreDefault) step.restoreDefault();
      
      return true;
    }

  }

  function params() {
    var params;

    params = {};
    this.steps.traverse(collectParams);

    return params;

    function collectParams(step) {
      Object.getOwnPropertyNames(step.params).forEach(collect);

      return true;

      function collect(key) {
        params[key] = step.params[key];
      }

    }

  }

  function step(step, properties, successor) {
    var link;

    link = null;
    if (!properties) {
      this.steps.traverse(toLink);
    } else {
      if (!successor) this.steps.add(step);
        else this.steps.add(step, successor);
      this.steps.traverse(toStepAndInsertProperties);
    }

    return link;

    function toLink(node) {
      if (node.value === step) {
        link = node;
        return false;
      } else {
        return true;
      }

    }

    function toStepAndInsertProperties(node) {
      if (node.value === step) {
        node.params = properties.params;
        node.display = properties.display;
        node.controls = properties.controls;
        node.prompt = properties.prompt;
        node.restoreDefault = properties.restoreDefault;
        link = node;
        return false;
      } else {
        return true;
      }

    }

  }

  function StepControl(option) {
    this.value = option.value || '';
    this.label = option.label || '';
    this.icon = option.icon || '';
    this.visible = option.visible || false;
    this.selected = option.selected || false;
    this.cssName = option.cssName || '';
  }

  function totalSteps() {
    return this.steps.length();
  }

  function index(step) {
    var index;
    var exists;

    exists = false;
    index = -1;
    // return -1 if no step is specified
    if (!step) return -1;
    this.steps.traverse(andCountToStep);
    // return -1 if the step is not found in the list
    if (!exists) return -1;

    return index;

    function andCountToStep(node) {
      ++index;
      if (node.value === step) {
        exists = true;
        return false;
      } else {
        return true;
      }

    }

  }

  function addControl(step, option) {
    var control;

    if (!step || !option) return -1;
    control = new this.StepControl(option);
    this.steps.traverse(toStepAndAddControl);

    return 0;

    function toStepAndAddControl(node) {
      if (node.value === step) {
        node.controls = node.controls || [];
        node.controls.push(control);
        return false;
      } else {
        return true;
      }

    }

  }

}
