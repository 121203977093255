require('angular-datetime-input');
// require('angular-input-masks');
require('./calendar');                          // service for formatting day and month values
//require('../birdParams');                       // service for storing time data in memory
require('../../../shared/blocks/routerHelper'); // helper functions for configuring the ui-router state
require('../../../shared/blocks/assistant');    // add the location to the series of bird id questions

require('./when.module');
require('./when.constant');                    // the when state name
require('./when.run');
require('./when.controller');
require('./whenHeading.controller');            // bind the when title to the header view
require('./whenSubheading.controller');       // bind the observed when to the observation view
require('./when.decorator');
