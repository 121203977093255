// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::session.service][session.service]]
'use strict';
var angular;
var serviceDepends;
var sessionService;

angular = require('angular');
sessionService = require('./sessionService');
serviceDepends = [];
serviceDepends.push('$localStorage');
serviceDepends.push('appServerService');
serviceDepends.push('USER_ROLES');
serviceDepends.push('$state');
serviceDepends.push('$timeout');
angular
  .module('blocks.session')
  .service('sessionService', sessionService);

sessionService.$inject = serviceDepends;
// session.service ends here
