'use strict';

module.exports = InspectController;

/**
 * @class InspectController
 * @description bind the location of an observed bird to the main view
 * @memberOf Controllers
 */
function InspectController($sanitize,
                           $state,
                           $stateParams,
                           imageCarouselService,
                           appServerService,
                           assistantService,
                           sessionService,
                           embedService,
                           DETAILS_STATE,
                           SPINNER_STATE,
                           INSPECT_STATE,
                           SIGNUP_STATE,
                           EXPIRED_STATE,
                           CONGRATS_STATE,
                           PLACE_STATE,
                           WHEN_STATE,
                           SIZE_STATE,
                           COLORS_STATE,
                           BEHAVIOR_STATE) {
  var vm;
  var stateOptions;

  stateOptions = {};
  stateOptions.location = 'replace';

  vm = this;
  vm.state = INSPECT_STATE;
  vm.slickConfig = imageCarouselService.slickConfig;
  vm.questions = assistantService;
  vm.isLinkingToAAB = embedService.isLinkingToAAB;

  // get the Merlin flight stored in the assistant but limited to the 15
  // highest-ranked birds,
  vm.flight = assistantService.result.slice(0, 15);

  // splice the selected plumage out of the collection and shift to front
  vm.flight.forEach(rebasePhotos);

  // states to jump to for editing inputs
  vm.assistantStates = [];
  vm.assistantStates.push(PLACE_STATE);
  vm.assistantStates.push(WHEN_STATE);
  vm.assistantStates.push(SIZE_STATE);
  vm.assistantStates.push(COLORS_STATE);
  vm.assistantStates.push(BEHAVIOR_STATE);

  // form the session by cloning the Merlin bird description
  vm.session = JSON.parse(JSON.stringify(assistantService.params()));

  // and add a map of the flight as a list of eBird species codes
  vm.session.results = vm.flight.reduce(toEbirdCode, []);

  // store the session
  vm.recordId = recordId;

  // the state displaying the details for a single bird in the flight
  vm.details = DETAILS_STATE;

  //update the user's Merlin checklist when the user decides, 'this is my bird'
  vm.updateChecklist = updateChecklist;
  vm.selectMerlinBird = selectMerlinBird;

  // Details on a bird in the flight:
    // the bird in the flight
  vm.plumage = $stateParams.plumage !== undefined &&
               $stateParams.plumage >= 0 &&
               $stateParams.plumage < assistantService.result.length ?
                 assistantService.result[$stateParams.plumage] :
                 assistantService.result[0];

    // the index for the bird in the flight
  vm.flightIndex = $stateParams.plumage;

  vm.saveBird = saveBird;

  vm.idNewBird = idNewBird;

  // lazyLoadContent();

  function rebasePhotos(speciesMatch) {
    var plumagePhotoIndex;

    // find the index in the album of the first photo that matches the plumage
    speciesMatch.photos.forEach(findPlumage);

    // give the photo carousel the standard slick config
    speciesMatch.photos.carouselConfig = JSON.parse(JSON.stringify(vm.slickConfig));

    // set the initial carousel slide to the selected plumage image
    if (plumagePhotoIndex && plumagePhotoIndex !== 0) {
      speciesMatch.photos.carouselConfig.initialSlide = plumagePhotoIndex;
      speciesMatch.photos.carouselConfig.responsive[0].settings.initialSlide = plumagePhotoIndex;
      speciesMatch.photos.carouselConfig.responsive[1].settings.initialSlide = plumagePhotoIndex;
    }

    function findPlumage(photo, index) {
      if (plumagePhotoIndex === undefined &&
          photo.plumageID === speciesMatch.plumageID)
        plumagePhotoIndex = index;
    }

  }

  // function lazyLoadContent() {
  //   var lazyLoadImages;
  //   var observerOptions;
  //   var imageObserver;

  //   // use the IntersectionObserver API if it is supported by the client
  //   if ("IntersectionObserver" in window) {

  //     // collect all elements with the class "lazy"
  //     lazyLoadImages = angular.element(document.querySelectorAll('.lazy'));  //jshint ignore:line
  //     observerOptions = {};

  //     // load the image when the first pixel of the containing box enters the
  //     // viewport
  //     observerOptions.threshold = 0;

  //     // create a 500px buffer so that images start loading before in view
  //     observerOptions.rootMargin = "0px 0px 500px 0px";

  //     // create an observer instance of the IntersectionObserver API
  //     imageObserver = new IntersectionObserver(updateInViewContent);

  //     // tell the observer to watch for each "lazy" element
  //     lazyLoadImages.forEach(watchElement);

  //     function watchElement(image) {
  //       //register the image with the observer
  //       imageObserver.observe(image);
  //     }

  //     // the callback function that is triggered when an image intersects the
  //     // viewport
  //     function updateInViewContent(entries) {
  //       entries.forEach(loadImageAndUnregister);
  //     }

  //     function loadImageAndUnregister(entry) {
  //       var image;

  //       if (entry.isIntersecting) {
  //         // grab the image element
  //         image = entry.target;

  //         // copy the image URL from "data-src" to a new "src" attribute
  //         image.src = image.dataset.src;

  //         // stop watching the image
  //         imageObserver.unobserve(image);

  //         // remove the "lazy" class so the element is never watched again
  //         image.classList.remove("lazy");
  //       }

  //     }

  //     // use custom event listeners if IntersectionObserver API is not supported
  //     // by the client
  //   } else {
  //   }

  // }

  function idNewBird() {
    // reset the assistant question inputs
    assistantService
        .reset();

    // route to signup page if trial limit exceeded
    sessionService
      .selectLandingPage(PLACE_STATE, SIGNUP_STATE);

  }

  function saveBird() {
    var spinnerOptions;

    spinnerOptions = {};
    spinnerOptions.noMessage = true;
    $state.go(SIGNUP_STATE, spinnerOptions, stateOptions);
  }

  // not currently being used
  function updateChecklist(flightIndex) {
    var flightParams;
    var landingStates;
    var userSelectedBird;

    // pick the user's bird out of the flight
    userSelectedBird = assistantService.result[flightIndex];

    // the total params collection is a combination of params from the assistant
    // and the 'mybird' code
    flightParams = JSON.parse(JSON.stringify(assistantService.params()));
    flightParams.mybird = userSelectedBird.eBirdCode;

    // define the states to navigate to after the request
    landingStates = {};
    landingStates.waiting = {};
    landingStates.waiting.state = SPINNER_STATE;
    landingStates.waiting.params = {};
    landingStates.waiting.params.noMessage = true;

    // display the user's bird on the congrats view
    landingStates.success = {};
    landingStates.success.state = CONGRATS_STATE;
    landingStates.success.params = {};
    landingStates.success.params.plumage = flightIndex;
    landingStates.problem = {};
    landingStates.problem.signup = {};
    landingStates.problem.signup.state = SIGNUP_STATE;
    landingStates.problem.expired = {};
    landingStates.problem.expired.state = EXPIRED_STATE;

    // get the flight of birds from Merlin
    appServerService.getMerlinFlight(flightParams,
                                     sessionService.storage,
                                     landingStates,
                                     assistantService.flight);
  }

  function selectMerlinBird(flightIndex) {
    var flightParams;
    var flightSession;
    var userSelectedBird;
    var hubEvent;
    var tracking;

    // record with Hubspot that the user requested birds
    hubEvent = {};
    hubEvent.id = 'Merlin-web This is My Bird';
    tracking = ['trackEvent', hubEvent];
    _hsq.push(tracking); // jshint ignore:line


    // pick the user's bird out of the flight
    userSelectedBird = assistantService.result[flightIndex];

    // the params for the session are the same used for finding the merlin
    // matches
    flightParams = JSON.parse(JSON.stringify(assistantService.params()));

    // the params for mybird and results are overwritten by the corresponding
    // keys in the body of the request, and the user token is sent in the
    // request body
    flightSession = JSON.parse(JSON.stringify(sessionService.storage));
    flightSession.mybird = userSelectedBird.eBirdCode;
    flightSession.results = vm.flight.reduce(toEbirdCode, []);

    // post a new Merlin session record
    appServerService.Flight.selectBird(flightParams, flightSession);

    // go to the congrats page
    $state.go(CONGRATS_STATE, {plumage: flightIndex});
  }

  function recordId(idEbirdCode) {
    var spinnerOptions;

    spinnerOptions = {};
    spinnerOptions.noMessage = true;

    // add the eBird code for the identified bird to the session
    vm.session.thisIsMyBird = idEbirdCode;

    // and add the user status to the session
    vm.session.token = sessionService.storage.token;

    // record the session
    appServerService.Id.bird(null, vm.session, recordSuccess, recordError);
    $state.go(SPINNER_STATE, spinnerOptions, stateOptions);

    function recordSuccess(status) {
      // update the session state
      sessionService.storage.status = status.payload;
      sessionService.storage.token = status.token;

      // view the id bird
      $state.go(INSPECT_STATE);
    }

    function recordError(err) {
      // if the token expired...
      if (err.data.errors[0].name === 'TokenExpiredError') {

        // and if the trial period is active navigate to the signup page
        if (sessionService.storage.status.trial.isActive) $state.go(SIGNUP_STATE);

        // or if the grace period is active navigate to the email reminder page
        if (sessionService.storage.status.email.grace) $state.go(EXPIRED_STATE);

      // otherwise if the trial attempts limit reached...
      } else if (err.data.errors[0].name === 'TrialLimitError') {

        // navigate to the signup page
        $state.go(SIGNUP_STATE);

      // otherwise if the token was not valid...
      } else if (err.data.errors[0].name === 'JsonWebTokenError' || err.data.errors[0].name === 'Unauthorized') {

        // navigate to the signup page
        $state.go(SIGNUP_STATE);

      }

    }

  }

  function toEbirdCode(memo, bird) {
    memo.push(bird.eBirdCode);

    return memo;
  }

}
