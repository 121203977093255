// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::detail.module][detail.module]]
'use strict';

var angular = require('angular');
var modDepends = [];

modDepends.push('ui.router');
modDepends.push('app.identify.inventory');
modDepends.push('blocks.routerHelper');
/**
 * @module app/identify/bestMatch/detail
 * @description display detailed list of possible matches for the observed bird
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/identify/inventory}
 * @requires {@link module:blocks/routerHelper}
 * @see module:app
 * @see module:identify
 * @see module:bestMatch
 */
angular
  .module('app.identify.bestMatch.detail', modDepends);
// detail.module ends here
