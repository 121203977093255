'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
/**
 * @module app/blocks/appServer
 * @description API for stepping through bird identification
 * @requires {@link module:app/blocks/dataStructures}
 * @see module:app
 * @see module:blocks
 */
angular
  .module('blocks.appServer', modDepends);
