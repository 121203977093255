// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::inventoryService][inventoryService]]
'use strict';

/**
 * @class inventoryService
 * @description process the list of possible bird matches
 * @memberOf Services
 */
module.exports = function () {
  var vm = this;

  //var merlinUrl = 'http://devmerlinapp.allaboutbirds.org/api/merlinBirds/' +
  //  'lat/42.4439614/lng/-76.5018807/date/2016-08-09/behavior/' +
  //  'flying/size/size6/colors/black,gray,brown';
  var mockImage = ['/app/assets/img/tiny.png'];
  var mockText = 'Lorem ipsum dolor sit amet, consectetur adipiscing ' +
    'elit. Cras scelerisque orci nec lorem pharetra faucibus. Fusce a' +
    ' eleifend justo. Suspendisse ornare libero ut tellus tincidunt f' +
    'ermentum. Sed tincidunt, ligula nec sagittis varius, orci tellus' +
    ' tristique lectus, nec tristique erat purus sit amet enim. Suspe' +
    'ndisse ligula est, fringilla quis posuere sit amet, fermentum et' +
    ' nisl. Integer nulla tellus, vestibulum quis dignissim vitae, sa' +
    'gittis a est. Integer lobortis mattis varius. Praesent varius ni' +
    'si eros, nec consectetur elit imperdiet non. Ut rhoncus id turpi' +
    's quis ultrices. Curabitur scelerisque nisl vel accumsan varius.' +
    ' Phasellus vitae ipsum vel sapien laoreet finibus. Interdum et m' +
    'alesuada fames ac ante ipsum primis in faucibus. Suspendisse mat' +
    'tis ultrices tellus, at varius sapien volutpat a. Duis auctor ut' +
    ' metus sit amet pharetra.';

  vm.mockData = [
    {pms: 0.096,
     commonName: 'Turkey Vulture',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 2,
     commonName: 'Canada Goose',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 1,
     commonName: 'Herring Gull',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 1,
     commonName: 'Bald Eagle',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 1,
     commonName: 'Ring-billed Gull',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 1,
     commonName: 'Great Black-backed Gull',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 1,
     commonName: 'Osprey',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 1,
     commonName: 'Red-tailed Hawk',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 1,
     commonName: 'Double-crested Cormorant',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 1,
     commonName: 'Green Heron',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 1,
     commonName: 'Common Merganser',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 1,
     commonName: 'Mallard',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 1,
     commonName: 'Pileated Woodpecker',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    },
    {pms: 1,
     commonName: 'Hooded Merganser',
     photos: mockImage,
     sounds: [],
     cardText: mockText,
     detailText: mockText
    }
  ];

  vm.bestMatch = [];
  vm.lastState = '';
  vm.moveToImage = moveToImage;

  function moveToImage(match) {
    var asset = 'http://devmerlinapi.allaboutbirds.net/Photos/';

    asset += match.eBirdCode + '/' + match.photos.reduce(firstPlumageImage, '');
    return asset;

    function firstPlumageImage(memo, photo) {
      memo = !memo.includes(match.bestMatchPlumageID) && photo.src;
      return memo;
    }

  }



  vm.findMatch = function(matches) {
    matches.matches.forEach(mapKeys);

    function mapKeys(match, index) {
      vm.bestMatch[index] = {};
      vm.bestMatch[index].commonName = match.common_name;
      vm.bestMatch[index].pms = match.pms;
      vm.bestMatch[index].photos = match.photos;
      vm.bestMatch[index].sounds = match.sounds;
      vm.bestMatch[index].cardText = match.id_text;
      vm.bestMatch[index].detailText = match.id_text;
      vm.bestMatch[index].eBirdCode = match.ebird_code;
    }

  };

};
// inventoryService ends here
