'use strict';

require('../../shared/blocks/centering');
require('../../shared/blocks/routerHelper');
//require('../../shared/blocks/slidingDrawer');
require('../../shared/blocks/session');
// require('./embed');
// require('./sendBirds');
require('./home');
// require('./idBirds');
require('./thanks');
require('./signup');
require('./sorry');
// require('./sorry');

require('./dashboard.module');
require('./dashboard.constant');
require('./dashboard.run');
require('./dashboard.controller');
//require('./dashboard.decorator');
