// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::navMenu.directive.js][navMenu.directive.js]]
'use strict';
var angular = require('angular');

module.exports = function ($timeout) {
  var directive = {
      restrict: 'EAC',
      controller: 'NavMenuController',
      controllerAs: 'menu',
      link: linkCallback($timeout),
      templateUrl: 'app/modules/shared/navMenu/navMenu.directive.html',
  };

  return directive;
};

function linkCallback ($timeout) {
  return function ($scope, element) {
    $timeout(function () {
      if (angular.element(element).hasClass('dropdown')) element.dropdown();
    }, 0);
  };
}
// navMenu.directive.js ends here
