module.exports = sessionService;

function sessionService($localStorage,
                        appServerService,
                        USER_ROLES,
                        $state,
                        $timeout) {
  // the default session values for a new, unconfirmed user
  this.defaultSession = {};
  /*
  this.defaultSession.email = {};
  this.defaultSession.trial = {};
  this.defaultSession.email.address = '';
  this.defaultSession.email.isConfirmed = false;
  this.defaultSession.trial.attempts = 0;
  this.defaultSession.trial.isActive = true;
  */
  this.defaultSession.token = '';
  //this.defaultSession.status = {};
  //this.defaultSession.status = USER_ROLES.anonymous;

  // set the session default values for a new, unconfirmed user
  this.storage = $localStorage.$default(this.defaultSession);

  // session API
  // this.address = address;
  this.isConfirmed = isConfirmed;
  this.isPending = isPending;
  this.isAnonymous = isAnonymous;
  // this.attempts = attempts;
  //this.status = status;
  // this.token = token;
  this.updateToken = updateToken;
  this.selectLandingPage = selectLandingPage;

  function selectLandingPage(home, signup, thanks) {
    var body;
    var stateOptions;

    // update the url and replace the last history item
    stateOptions = {};
    stateOptions.location = 'replace';

    body = {};
    body.token = this.storage.token;
    appServerService
      .Token.update(null, body, selectPage.bind(this), tokenError);

    function selectPage(status) {
      var thanksOptions;

      thanksOptions = {};
      thanksOptions.resend = true;

      // put the updated token in local storage
      this.storage.token = status.token;

      // if the token has expired...
      if (status.payload.detail) {

        // go to the email signup page
        $timeout(function() {$state.go(signup, null, stateOptions);}, 0);

      // otherewise store the user status in local storage
      } else {

        // include the human readable token info for dev purposes
        // this.storage.status = JSON.parse(JSON.stringify(status.payload));
      }

      if (this.isAnonymous(status) || this.isConfirmed(status)) {
        $timeout(function() {$state.go(home, null, stateOptions);}, 0);
      } else if (this.isPending(status) === undefined) {
        $timeout(function() {$state.go(signup, null, stateOptions);}, 0);
      } else if (this.isPending(status)) {
        $timeout(function() {$state.go(thanks, thanksOptions, stateOptions);}, 0);
      } else {
        $timeout(function() {$state.go(signup, null, stateOptions);}, 0);
      }

    }

    function tokenError(err) {
      console.dir(err);
      $timeout(function() {$state.go(signup, null, stateOptions);}, 0);
    }

  }

  function updateToken(home, signup) {
    var body;

    body = {};
    body.token = this.storage.token;
    appServerService.Token.update(null, body, storeToken.bind(this), tokenError);

    function storeToken(status) {
      this.storage.token = status.token;
      this.storage.status = JSON.parse(JSON.stringify(status.payload));
      if (this.isAnonymous(status) || this.isConfirmed(status)) {
        $timeout(function() {$state.go(home);}, 0);
      } else if (this.isPending(status) === undefined) {
        $timeout(function() {$state.go(signup);}, 0);
      } else if (this.isPending(status)) {
        $timeout(function() {$state.go(home);}, 0);
      }

    }

    function tokenError(err) {
      console.dir(err.errors);
    }

  }

  function isAnonymous(status) {
    return status.payload.trial.isActive;
    // return this.storage.status.trial.isActive;
  }

  function isPending(status, newState) {
    // return the active state if the newState is not a boolean
    if (typeof(newState) !== 'boolean') {
      if (!status.payload.email.grace) return undefined;
        else return status.payload.email.grace.isActive;
    }

    if (!status.payload.email.grace) {

      // start the grace period for the first time
      if (newState === 'true') {
        status.payload.email.grace = {};
        status.payload.email.grace.isActive = true;
        return true;
      } else {

        // return undefined if the grace period has never started
        return undefined;
      }

    } else {

      // return the new active state of the existing grace period
      status.payload.email.grace.isActive = newState;
      return status.payload.email.grace.isActive;
    }

  }

  // function isPending(newState) {
  //   // return the active state if the newState is not a boolean
  //   if (typeof(newState) !== 'boolean') {
  //     if (!this.storage.status.email.grace) return undefined;
  //       else return this.storage.status.email.grace.isActive;
  //   }

  //   if (!this.storage.status.email.grace) {

  //     // start the grace period for the first time
  //     if (newState === 'true') {
  //       this.storage.status.email.grace = {};
  //       this.storage.status.email.grace.isActive = true;
  //       return true;
  //     } else {

  //       // return undefined if the grace period has never started
  //       return undefined;
  //     }

  //   } else {

  //     // return the new active state of the existing grace period
  //     this.storage.status.email.grace.isActive = newState;
  //     return this.storage.status.email.grace.isActive;
  //   }

  // }

  function isConfirmed(status) {
    // if (typeof(newState) === 'boolean') this.storage.status.email.isConfirmed = newState;

    return status.payload.email.isConfirmed;
    // return this.storage.status.email.isConfirmed;
  }

  // function address(emailAddress) {
  //   if (emailAddress && (typeof(emailAddress) === 'string')) this.storage.email.address = emailAddress;

  //   return this.storage.email.address;
  // }

  // function attempts(extraAttempt) {
  //   if (extraAttempt && (typeof(extraAttempt) === 'number') && this.isAnonymous) {
  //     this.storage.trial.attempts += extraAttempt;
  //     if (~~process.env.ATTEMPT_LIMIT < this.storage.trial.attempts) this.storage.trial.isActive = false;
  //   }

  //   return this.storage.trial.attempts;
  // }

  // function status() {
  //   if (this.isConfirmed) return 'confirmed';
  //   if (this.isPending) return 'pending';
  //   if (this.isAnonymous) return 'anonymous';

  //   return '';
  // }

  // function token(currentToken) {
  //   if (typeof(curentToken) === 'string') this.storage.token = currentToken;

  //   return this.storage.token;
  // }

}
