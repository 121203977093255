'use strict';

module.exports = appServerService;

/**
 * @class appServerService
 * @description ask where a bird to be identified was observed
 * @memberOf Services
 * @requires {@link module:inventoryService}
 * @requires {@link module:matchService}
 */
function appServerService() {

}
