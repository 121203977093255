'use strict';

module.exports = mediaQueriesSingleton;

function mediaQueriesSingleton($document, matchMedia) {
  var head = angular.element($document[0].querySelector('head'));
  head.append('<meta class="foundation-mq-topbar" />');
  head.append('<meta class="foundation-mq-small" />');
  head.append('<meta class="foundation-mq-medium" />');
  head.append('<meta class="foundation-mq-large" />');

  var regex = /^[\/\\'"]+|(;\s?})+|[\/\\'"]+$/g;
  var queries = {
      topbar: getComputedStyle(head[0].querySelector('meta.foundation-mq-topbar')).fontFamily.replace(regex, ''),
      small : getComputedStyle(head[0].querySelector('meta.foundation-mq-small')).fontFamily.replace(regex, ''),
      medium : getComputedStyle(head[0].querySelector('meta.foundation-mq-medium')).fontFamily.replace(regex, ''),
      large : getComputedStyle(head[0].querySelector('meta.foundation-mq-large')).fontFamily.replace(regex, '')
  };

  return {
      topbarBreakpoint: function () {
          return !matchMedia(queries.topbar).matches;
      },
      small: function () {
          return matchMedia(queries.small).matches;
      },
      medium: function () {
          return matchMedia(queries.medium).matches;
      },
      large: function () {
          return matchMedia(queries.large).matches;
      }
      
  };
    
}
