// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::spinnerHeading.controller][spinnerHeading.controller]]
'use strict';
var angular;
var SpinnerHeadingController;

angular = require('angular');
SpinnerHeadingController = require('./SpinnerHeadingController');
angular
  .module('app.identify.spinner')
  .controller('SpinnerHeadingController', SpinnerHeadingController);
// spinnerHeading.controller ends here
