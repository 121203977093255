'use strict';
var angular;
var inspectRun;
var runDepends;

angular = require('angular');
inspectRun = require('./inspectRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('INSPECT_STATE');
angular
  .module('app.identify.inspect')
  .run(inspectRun);

inspectRun.$inject = runDepends;
