// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::detailsHeading.controller][detailsHeading.controller]]
'use strict';
var angular;
var DetailsHeadingController;
var controllerDepends;

angular = require('angular');
DetailsHeadingController = require('./DetailsHeadingController');
controllerDepends = [];
controllerDepends.push('DETAILS_STATE');
controllerDepends.push('$stateParams');
controllerDepends.push('assistantService');
angular
  .module('app.identify.inspect.details')
  .controller('DetailsHeadingController', DetailsHeadingController);

DetailsHeadingController.$inject = controllerDepends;
// detailsHeading.controller ends here
