// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::session.decorator][session.decorator]]
'use strict';
var angular;
var appServerServiceDecorator;
var appServerDepends;

angular = require('angular');
appServerServiceDecorator = require('./appServerServiceDecorator');
appServerDepends = [];
appServerDepends.push('$delegate');
appServerDepends.push('$resource');
angular
  .module('blocks.session')
  .decorator('appServerService', appServerServiceDecorator);

appServerServiceDecorator.$inject = appServerDepends;
// session.decorator ends here
