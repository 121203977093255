'use strict';

var angular;
var stackedMapFactory;
var modalStackFactory;
var stackedMapFactoryDepends;
var modalStackFactoryDepends;

angular = require('angular');
stackedMapFactory = require('./stackedMapFactory');
modalStackFactory = require('./modalStackFactory');
stackedMapFactoryDepends = [];
modalStackFactoryDepends = [];
modalStackFactoryDepends.push('$window');
modalStackFactoryDepends.push('$transition');
modalStackFactoryDepends.push('$timeout');
modalStackFactoryDepends.push('$document');
modalStackFactoryDepends.push('$compile');
modalStackFactoryDepends.push('$rootScope');
modalStackFactoryDepends.push('$$stackedMap');
angular
  .module('blocks.zf.modal')
  .factory('$$stackedMap', stackedMapFactory)
  .factory('$modalStack', modalStackFactory);

stackedMapFactory.$inject = stackedMapFactoryDepends;
modalStackFactory.$inject = modalStackFactoryDepends;
