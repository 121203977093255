// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::dataStructures.module][dataStructures.module]]
'use strict';

var angular = require('angular');
var modDepends = [];

/**
 * @module app/blocks/dataStructures
 * @description store and process bird dataStructures traits
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/identify/inventory}
 * @requires {@link module:app/identify/match}
 * @see module:app
 * @see module:blocks
 */
angular
  .module('blocks.dataStructures', modDepends);
// dataStructures.module ends here
