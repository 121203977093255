'use strict';
var angular;
var DashboardController;
var controllerDepends;

controllerDepends = [];
//controllerDepends.push('slidingDrawerService');
// controllerDepends.push('sessionService');
angular = require('angular');
DashboardController = require('./DashboardController');
angular
  .module('app.dashboard')
  .controller('DashboardController', DashboardController);

DashboardController.$inject = controllerDepends;
