// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::spinnerSubheading.controller][spinnerSubheading.controller]]
'use strict';
var angular;
var SpinnerSubheadingController;

angular = require('angular');
SpinnerSubheadingController = require('./SpinnerSubheadingController');
angular
  .module('app.identify.spinner')
  .controller('SpinnerSubheadingController', SpinnerSubheadingController);
// spinnerSubheading.controller ends here
