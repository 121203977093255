'use strict';
var states;
var dashboard;

states = [];
dashboard = {};
dashboard.config = {};
dashboard.config.url = '^/dashboard';
dashboard.config.templateUrl = './app/modules/components/dashboard/dashboard.view.html';
dashboard.config.controller = 'DashboardController as dashboard';
module.exports = dashboardRun;

function dashboardRun(routerHelper, $rootScope, $timeout, DASHBOARD_STATE) {
  dashboard.state = DASHBOARD_STATE;
  states.push(dashboard);
  routerHelper.configureStates(states);

  // ensure MDL animations work by registering all components with componentHandler
  // listeners($rootScope, $timeout);
}

// function listeners($rootScope, $timeout) {
//   //$rootScope.$on('$stateChangeSuccess', enqueue($timeout));
//   $rootScope.$on('$viewContentLoaded', enqueue($timeout));
// }

// function enqueue($timeout) {
//   return function() {
//     $timeout(upgradeAll);
//   };
// }

// function upgradeAll() {
//   componentHandler.upgradeAllRegistered();    // jshint ignore:line
// }
