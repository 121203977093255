'use strict';

var angular;
var jPlayerDirective;
var jPlayerDirectiveDepends;

angular = require('angular');
jPlayerDirective = require('./jPlayerDirective');
jPlayerDirectiveDepends = [];
angular
  .module('blocks.jplayer')
  .directive('jPlayerDialog', jPlayerDirective);

jPlayerDirective.$inject = jPlayerDirectiveDepends;
