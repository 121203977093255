'use strict';
var states;
var spinner;
var config;

states = [];
spinner = {};
config = {};
//config.url = '^/spinner';

// configure the parameter passed to toggle the display of messages
config.params = {};
config.params.noMessage = null;

// configure three multiple views for the question, header, and collected data
config.views = {};

// configure the view for the spinner question
config.views['@app.identify'] = {};
config.views['@app.identify'].templateUrl = './app/modules/components/identify/' +
                                      'spinner/spinner.view.html';
config.views['@app.identify'].controller = 'SpinnerController as spinner';

// configure the header title during the spinner question
config.views['heading@app.identify'] = {};
config.views['heading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                  'spinner/spinnerHeading.view.html';
config.views['heading@app.identify'].controller = 'SpinnerHeadingController as heading';

// configure the view for displaying the collected observation data
config.views['subheading@app.identify'] = {};
config.views['subheading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                       'spinner/spinnerSubheading.view.html';
config.views['subheading@app.identify'].controller = 'SpinnerSubheadingController as subheading';
spinner.config = config;
module.exports = spinnerRun;

function spinnerRun(routerHelper, SPINNER_STATE) {
  // set the spinner question as a child of the identify state
  spinner.state = SPINNER_STATE;
  states.push(spinner);
  routerHelper.configureStates(states);
}
