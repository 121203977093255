// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::characters.filter][characters.filter]]
'use strict';

module.exports = function() {
  return truncateChars;

  function truncateChars(input, chars, breakOnWord) {
      if (isNaN(chars)) return input;
      if (chars <= 0) return '';
      if (input && input.length > chars) {
          input = input.substring(0, chars);

          if (!breakOnWord) {
              var lastspace = input.lastIndexOf(' ');
              //get last space
              if (lastspace !== -1) {
                  input = input.substr(0, lastspace);
              }
          } else {
              while(input.charAt(input.length-1) === ' '){
                  input = input.substr(0, input.length -1);
              }
          }
          return input + 'â¦';
      }
      return input;
  }
};
// characters.filter ends here
