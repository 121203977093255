// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::sizeSubheading.controller][sizeSubheading.controller]]
'use strict';
var angular;
var SizeSubheadingController;
var controllerDepends;

angular = require('angular');
SizeSubheadingController = require('./SizeSubheadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('PLACE_STATE');
controllerDepends.push('WHEN_STATE');
angular
  .module('app.identify.size')
  .controller('SizeSubheadingController', SizeSubheadingController);

SizeSubheadingController.$inject = controllerDepends;
// sizeSubheading.controller ends here
