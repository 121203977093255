'use strict';
var angular;
var ColorsController;
var controllerDepends;

angular = require('angular');
ColorsController = require('./ColorsController');
controllerDepends = [];
controllerDepends.push('$state');
controllerDepends.push('$filter');
controllerDepends.push('assistantService');
controllerDepends.push('COLORS_STATE');
controllerDepends.push('capitalizeFilter');
angular
  .module('app.identify.colors')
  .controller('ColorsController', ColorsController);

ColorsController.$inject = controllerDepends;
