'use strict';
var angular;
var WhenController;
var controllerDepends;

angular = require('angular');
WhenController = require('./WhenController');
controllerDepends = [];
controllerDepends.push('$state');
controllerDepends.push('$filter');
controllerDepends.push('assistantService');
controllerDepends.push('calendarService');
controllerDepends.push('WHEN_STATE');
angular
  .module('app.identify.when')
  .controller('WhenController', WhenController);

WhenController.$inject = controllerDepends;
