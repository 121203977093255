// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::dataStructures.service][dataStructures.service]]
'use strict';

var angular = require('angular');
var dataStructuresService = require('./dataStructuresService');

angular
  .module('blocks.dataStructures')
  .service('dataStructuresService', dataStructuresService);
// dataStructures.service ends here
