// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::calendar.service][calendar.service]]
'use strict';

var angular = require('angular');
var calendarService = require('./calendarService');

angular
  .module('app.identify.when.calendar')
  .service('calendarService', calendarService);
// calendar.service ends here
