// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::inventory.module][inventory.module]]
'use strict';

var angular = require('angular');
var modDepends = [];

modDepends.push('ui.router');
/**
 * @module app/identify/inventory
 * @description store and process possible bird matches
 * @see module:app
 * @see module:identify
 */
angular
  .module('app.identify.inventory', modDepends);
// inventory.module ends here
