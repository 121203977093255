'use strict';

module.exports = directive;

function directive($window, $rootScope, interchangeTools) {
  var directive;
  var pictureFilePattern;

  pictureFilePattern = /[A-Za-z0-9-_]+\.(jpg|jpeg|png|gif|bmp|tiff)\ *,/i;    
  directive = {};
  directive.restrict = 'A';
  directive.scope = true;
  directive.compile = compile;

  return directive;

  function compile(tElem, attrs) {
    var link;

    // Set up the attribute to update
    if (tElem[0].nodeName === 'DIV' &&
          !pictureFilePattern.test(attrs.interchange)) {
      tElem.html('<ng-include src="currentFile"></ng-include>');
    }
      
    link = {};
    link.pre = preLink;
    link.post = postLink;
    return link;
      
    function preLink(scope, iElem, attrs) {}
    
    function postLink(scope, iElem, attrs) {
      var currentFile, nodeName;

      // Set up the attribute to update
      nodeName = iElem && iElem[0] && iElem[0].nodeName;
      scope.fileMap = interchangeTools.parseAttribute(attrs.interchange);
        
      // Find the type of interchange
      switch (nodeName) {
      case 'DIV':
        // If the tag is a div, we test the current file to see if it's picture
        currentFile = interchangeTools.findCurrentMediaFile(scope.fileMap);
        if (/[A-Za-z0-9-_]+\.(jpg|jpeg|png|gif|bmp|tiff)$/i.test(currentFile)) {
          scope.type = 'background';
        }
        else {
          scope.type = 'include';
        }
        break;

      case 'IMG':
        scope.type = 'image';
        break;

      default:
        return;
      }

      var replace = function (e) {
        // The the new file to display (exit if the same)
        var currentFile = interchangeTools.findCurrentMediaFile(scope.fileMap);

        if (!!scope.currentFile && scope.currentFile === currentFile) {
          return;
        }

        // Set up the new file
        scope.currentFile = currentFile;
        switch (scope.type) {
        case 'image':
          iElem.attr('src', scope.currentFile);
          break;

        case 'background':
          iElem.css('background-image', 'url(' + scope.currentFile + ')');
          break;
        }

        // Trigger events
        $rootScope.$emit('replace', iElem, scope);
        if (!!e) {
          scope.$apply();
        }
          
      };

      // Start
      replace();
      $window.addEventListener('resize', replace);
      scope.$on('$destroy', function () {
        $window.removeEventListener('resize', replace);
      });
          
    }

  }

}
      
