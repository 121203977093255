// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::colorsHeading.controller][colorsHeading.controller]]
'use strict';
var angular;
var ColorsHeadingController;
var controllerDepends;

angular = require('angular');
ColorsHeadingController = require('./ColorsHeadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('COLORS_STATE');
angular
  .module('app.identify.colors')
  .controller('ColorsHeadingController', ColorsHeadingController);

ColorsHeadingController.$inject = controllerDepends;
// colorsHeading.controller ends here
