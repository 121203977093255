require('../../../shared/blocks/routerHelper'); // helper functions for configuring the ui-router state
//require('../../../shared/blocks/slidingDrawer');
require('../../../shared/blocks/appServer');
require('../../../shared/blocks/session');
require('../../dashboard');

require('./signup.module');
require('./signup.constant');
require('./signup.run');
require('./signup.decorator');
require('./signup.controller');
require('./signupHeading.controller');

