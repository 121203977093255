'use strict';

module.exports = directive;

function directive() {
  var directive;

  directive = {};
  directive.restrict = 'A';
  directive.scope = {};
  directive.scope.selector = '=selector';
  directive.scope.collection = '=collection';
  directive.scope.toggle = '&toggle';
  directive.scope.max = '@max';
  directive.scope.min = '@min';
  directive.controller = SelectorController;
  directive.link = postLink;
  SelectorController.$inject = ['$scope'];

  return directive;

  function SelectorController($scope) {
    $scope.atMin = false;
    $scope.atMax = false;
    this.totalSelected = totalSelected;
    this.selected = $scope.collection.reduce(this.totalSelected,0);
    this.updateThresholds = updateThresholds;

    function updateThresholds() {
      var submitButton;

      submitButton = angular.element(document.querySelector('#multi-select-submit'));

      // count the inputs selected
      this.selected = $scope.collection.reduce(this.totalSelected,0);

      // check if max inputs reached
      $scope.atMax = this.selected > $scope.max - 1;

      // check if min inputs reached
      $scope.atMin = this.selected > $scope.min - 1;

      // disable/enable submit button
      if ($scope.atMin) {
        submitButton.removeClass('disabled');
        submitButton.addClass('active');
      } else {
        submitButton.removeClass('active');
        submitButton.addClass('disabled');
      }

    }

    function totalSelected(memo, control) {
      if (control.selected) memo = memo + 1;

      return memo;
    }

  }

  function postLink(scope, iElem, attr, ctrl) {
    var submitButton;

    // sync-up the inputs with the initial state of the the selectors
    iElem[0].checked = scope.selector.selected;

    // update selection thresholds and enable/disable submit button
    ctrl.updateThresholds();

    // disable unselected inputs if max number already selected
    iElem[0].disabled = scope.atMax && !iElem[0].checked;

    // update the selector state and throttle inputs when the user
    // makes a selection
    iElem.on('change', sync);

    function throttleInput(newValue, oldValue) {
      // enable/disable the selections
      scope.collection.forEach(throttle);

      function throttle(selector) {
        var id;
        var selection;

        // disable a selection input if the number selected is at max
        // and the user has not already selected the input
        id = selector.value + '-box';
        selection = angular.element(document.querySelector('#' + id))[0];
        selection.disabled = scope.atMax && !selection.checked;
      }

    }

    function sync(event) {
      // sync-up the selector with the user selection
      scope.selector.selected = iElem[0].checked;

      // update the selection thresholds
      ctrl.updateThresholds();

      // trigger enable/disable all inputs
      throttleInput();

    }

  }

}
