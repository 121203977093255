'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push('datetime');
modDepends.push('ui.router');
modDepends.push('blocks.routerHelper');
modDepends.push('blocks.assistant');
//modDepends.push('app.identify.birdParams');
modDepends.push('app.identify.when.calendar');
// modDepends.push('ui.utils.masks');
/**
 * @module app/identify/when
 * @description enter when a bird was observed
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/identify/birdParams}
 * @requires {@link module:app/identify/when/calendar}
 * @see module:app
 * @see module:identify
 */
angular
  .module('app.identify.when', modDepends);
