'use strict';
var states;
var signup;

states = [];
signup = {};
signup.config = {};
signup.config.url = '^/signup';

// configure three multiple views for the question, header, and collected data
signup.config.views = {};

// configure the view for the signup question
signup.config.views['@app.dashboard'] = {};
signup.config.views['@app.dashboard'].templateUrl = './app/modules/components/dashboard/' +
                                      'signup/signup.view.html';
signup.config.views['@app.dashboard'].controller = 'SignupController as signup';

// configure the header title during the signup question
signup.config.views['heading@app.dashboard'] = {};
signup.config.views['heading@app.dashboard'].templateUrl = './app/modules/components/dashboard/' +
                                                  'signup/signupHeading.view.html';
signup.config.views['heading@app.dashboard'].controller = 'SignupHeadingController as heading';
module.exports = signupRun;

function signupRun(routerHelper, $state, $rootScope, SIGNUP_STATE) {
  // set the email signup as a child of the dashboard state
  signup.state = SIGNUP_STATE;
  states.push(signup);
  routerHelper.configureStates(states);
  // intercept location edits and load the signup state
  //$rootScope.$on('$locationChangeSuccess', goToSignup($state, SIGNUP_STATE));
  // $rootScope.$on('$locationChangeStart', checkForEdit($state, SIGNUP_STATE));

  // function checkForEdit(event, newUrl, oldUrl, newState, oldState) {
  //     console.log('location change start event');
  //     console.dir(event);
  //     console.log('new URL: ', newUrl);
  //     console.log('old URL: ', oldUrl);
  //     console.log('new history state');
  //     console.dir(newState);
  //     console.log('old history state');
  //     console.dir(oldState);
  // }
    
  // function checkForEdit(state, signup) {
  //   return check;

  //   function check(event, toState, toParams, fromState) {
  //     if (fromState.name !== toState.name) {
  //       event.preventDefault();
  //       state.go(signup);
  //     }

  //   }

  // }
    
  /*
  function goToSignup(state) {
    return goTo;

    function goTo(event, newUrl, oldUrl) {
      event.preventDefault();
      console.log(newUrl, oldUrl);
      if (newUrl !== oldUrl) state.go(oldUrl);

    }

  }
 */

}
