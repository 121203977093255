// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::size.decorator][size.decorator]]
'use strict';
var angular;
var assistantServiceDecorator;
var decoratorDepends;

angular = require('angular');
assistantServiceDecorator = require('./assistantServiceDecorator');
decoratorDepends = [];
decoratorDepends.push('$delegate');
decoratorDepends.push('SIZE_STATE');
angular
  .module('app.identify.size')
  .decorator('assistantService', assistantServiceDecorator);

assistantServiceDecorator.$inject = decoratorDepends;
// size.decorator ends here
