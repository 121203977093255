// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::thanks.module][thanks.module]]
'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push('ui.router');
modDepends.push('blocks.routerHelper');
modDepends.push('app.dashboard');
modDepends.push('app.dashboard.home');
/**
 * @module app/dashboard/thanks
 * @description enter the thanks of an observed bird
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/dashboard/birdParams}
 * @see module:app
 * @see module:dashboard
 */
angular
  .module('app.dashboard.thanks', modDepends);
// thanks.module ends here
