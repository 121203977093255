// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::DetailController][DetailController]]
'use strict';

/**
 * @class DetailController
 * @description bind common name and navigation controls in the detail view of best bird matches
 * @memberOf Controllers
 */
module.exports = function ($state, inventoryService, $window) {
  var vm = this;

  vm.state = $state.current.name;
  vm.bestMatch = inventoryService.bestMatch;
  vm.startOver = startOver;
  vm.moveToImage = inventoryService.moveToImage;

  function startOver() {
    $state.go('place');
    $window.location.reload();
  }
};
// DetailController ends here
