// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::thanks.run][thanks.run]]
'use strict';
var angular;
var thanksRun;
var runDepends;

angular = require('angular');
thanksRun = require('./thanksRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('THANKS_STATE');
angular
  .module('app.dashboard.thanks')
  .run(thanksRun);

thanksRun.$inject = runDepends;
// thanks.run ends here
