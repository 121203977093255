module.exports = appController;

function appController($rootScope) {
  var vm = this;

  vm.keyup = function (event) {
    if (event.keyCode === 27) $rootScope.$broadcast("escapePressed", event.target);
  };

  vm.click = function (event) {
    $rootScope.$broadcast("documentClicked", event.target);
  };

}
