"use strict";

module.exports = {
  num2str: function num2str(num, minLength, maxLength) {
    var i;
    num = "" + num;

    if (num.length > maxLength) {
      num = num.substr(num.length - maxLength);
    } else if (num.length < minLength) {
      for (i = num.length; i < minLength; i++) {
        num = "0" + num;
      }
    }

    return num;
  },
  Emitter: require("event-lite")
};