// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::centeringDirective.js][centeringDirective.js]]
'use strict';

module.exports = directive;

function directive() {
  var directive;

  directive = {};
  directive.restrict = 'E';
  directive.transclude = true;
  directive.scope = {};
  directive.scope.backref = '@';
  directive.templateUrl = 'app/modules/shared/blocks/centering/centering.directive.html';

  return directive;
}
// centeringDirective.js ends here
