// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::home.module][home.module]]
'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push('ui.router');
modDepends.push('blocks.routerHelper');
//modDepends.push('blocks.slidingDrawer');
modDepends.push('app.identify');
modDepends.push('app.identify.place');
modDepends.push('app.dashboard');
/**
 * @module app/dashboard/home
 * @description enter the home of an observed bird
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/dashboard/birdParams}
 * @see module:app
 * @see module:dashboard
 */
angular
  .module('app.dashboard.home', modDepends);
// home.module ends here
