'use strict';

module.exports = SpinnerController;

/**
 * @class SpinnerController
 * @description bind the location of an observed bird to the main view
 * @memberOf Controllers
 */
function SpinnerController($stateParams) {
  var vm;

  vm = this;
  vm.noMessage = !!$stateParams.noMessage;

}
