// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::bestMatch.controller][bestMatch.controller]]
'use strict';

var angular = require('angular');
var BestMatchController = require('./bestMatchController');
var controllerDepends = [];

controllerDepends.push('$state');
controllerDepends.push('$stateParams');
controllerDepends.push('$window');
angular
  .module('app.identify.bestMatch')
  .controller('BestMatchController', BestMatchController);

BestMatchController.$inject = controllerDepends;
// bestMatch.controller ends here
