'use strict';

// load polyfill
require('@babel/polyfill');

// Overide Angular's JQueryLite and use traditional jQuery because
// some of the 3rd party directives are simply jQuery plugins wrapped
// with an Angular directive and will need all of the functions available
// from jQuery to work
var $ = require('jquery');
window.$ = $;
window.jQuery = $;

// audio and video jQuery plugin
// NOTE: this must be loaded after jQuery
require('jplayer');

// Load Angular
var angular = require('angular'); //jshint ignore:line

// establish the namespace used for reference documentation
require('./appNameSpace');

/** @module angular-messages */
require('angular-messages');
/** @module angular-resource */
require('angular-resource');
/** @module angular-ui-router */
require('angular-ui-router');
/** @module ng-autocomplete-commonjs */
require('ng-autocomplete-commonjs');
require('ngstorage');

require('./app.module');
require('./app.config');
require('./app.run');
require('./app.controller');

// load the app dependencies
require('./appModule');

//  LocalWords:  babel
