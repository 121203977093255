'use strict';

module.exports = dataStructuresService;

/**
 * @class dataStructuresService
 * @description ask where a bird to be identified was observed
 * @memberOf Services
 * @requires {@link module:inventoryService}
 * @requires {@link module:matchService}
 */
function dataStructuresService() {
  var vm;

  vm = this;                         //jshint ignore:line
  vm.link = Node;
  vm.linkedList = setLinkedList;

  function Node(value) {
    this.value = value;
    this.prior = null;
    this.next = null;
  }

  function setLinkedList() {
    return new LinkedList();
  }

  function LinkedList() {
    var _head;
    var _tail;
    var _length;
    var that;

    that = this;
    _head = null;
    _tail = null;
    _length = 0;

    this.length = length;
    this.traverse = traverse;
    this.log = log;
    this.toArray = toArray;
    this.peek = peek;
    this.clear = clear;
    this.add = add;
    this.remove = remove;

    function length() {
      return _length;
    }

    /* The visit callback function must take a Node reference as
     * its first argument and return a boolean value.
     * Return true to continue traversing the list.
     * Return false to stop traversing the list.
     */
    function traverse(visit) {
      var current;

      current = getHead();
      while (current) {
        if (!visit(current)) break;
        current = current.next;
      }

    }

    function getHead() {
      return _head;
    }

    function setHead(node) {
      var head;

      head = node || null;
      _head = node;
      return node;
    }

    function getTail() {
      return _tail;
    }

    function setTail(node) {
      var tail;

      tail = node || null;
      _tail = node;
      return node;
    }

    function increment() {
      return ++_length;
    }

    function decrement() {
      return --_length;
    }

    function clear() {
      var list;

      list = this.toArray();              //jshint ignore:line
      setHead(null);
      setTail(null);
      _length = 0;

      return list;
    }

    function peek() {
     if (!getTail()) return;
       else return getTail().value;
    }

    function removeHead() {
      var value;

      value = getHead().value;
      if (getHead().next) {
        setHead(getHead().next);
        getHead().prior = null;
      } else {
        setHead(null);
      }

      decrement();
      return value;
    }

    function addHead(value) {
      var node;

      node = new vm.link(value);
      if (!getHead) {
        setHead(node);
        setTail(node);
        return increment();
      } else {
        node.next = getHead();
        getHead().prior = node;
        setHead(node);
        return increment();
      }

    }

    function add(value, targetValue, targetNode) {
      var node;
      var target;

      if (targetValue) target = nodeWith(targetValue);
      if (targetNode) target = targetNode;
      if (!target && !targetValue) return addTail(value);
      if (target && target === getHead()) return addHead(value);
      if (target && target === getTail()) {
        that.remove();
        that.add(value);
        return that.add(target.value);
      }

      if (target) {
        node = new vm.link(value);
        node.prior = target.prior;
        node.next = target;
        target.prior.next = node;
        target.prior = node;
        return increment();
      }

    }

    function remove(targetValue, targetNode) {
      var target;

      if (targetValue) target = nodeWith(targetValue);
      if (targetNode) target = targetNode;
      if (!target) return removeTail();
      if (target === getHead()) return removeHead();
      if (target === getTail()) return removeTail();
      if (target) {
        target.prior.next = target.next;
        target.next.prior = target.prior;
        decrement();
        return target.value;
      }

    }

    function nodeWith(value) {
      var target;

      target = null;
      that.traverse(findTarget);
      return target;

      function findTarget(node) {
        if (node.value === value) {
          target = node;
          return false;
        } else {
          return true;
        }

      }

    }

    function removeTail() {
      var value;

      if (!getTail()) return;
      value = getTail().value;
      setTail(getTail().prior);
      if (getTail()) getTail().next = null;
        else setHead(null);
      decrement();
      return value;
    }

    function addTail(value) {
      var node;

      node = new vm.link(value);
      if (!getHead()) {
        setHead(node);
        setTail(node);
        return increment();
      } else {
        getTail().next = node;
        node.prior = getTail();
        setTail(node);
        return increment();
      }

    }

    function log() {
      var list;

      list = [];
      this.traverse(collectValues);       //jshint ignore:line

      function collectValues(node) {
        list.push(node.value);
        return true;
      }

    }

    function toArray() {
      var list;

      list = [];
      this.traverse(collectValues);      //jshint ignore:line
      return list;

      function collectValues(node) {
        list.push(node.value);
        return true;
      }

    }

  }

}
