// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::expiredHeading.controller][expiredHeading.controller]]
'use strict';
var angular;
var ExpiredHeadingController;
var controllerDepends;

angular = require('angular');
ExpiredHeadingController = require('./ExpiredHeadingController');
controllerDepends = [];
controllerDepends.push('EXPIRED_STATE');
angular
  .module('app.dashboard.expired')
  .controller('ExpiredHeadingController', ExpiredHeadingController);

ExpiredHeadingController.$inject = controllerDepends;
// expiredHeading.controller ends here
