'use strict';
var angular;
var signupRun;
var runDepends;

angular = require('angular');
signupRun = require('./signupRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('$state');
runDepends.push('$rootScope');
runDepends.push('SIGNUP_STATE');
angular
  .module('app.dashboard.signup')
  .run(signupRun);

signupRun.$inject = runDepends;
