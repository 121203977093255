'use strict';
var states;
var congrats;

states = [];
congrats = {};
congrats.config = {};
congrats.config.url = '^/congrats/{plumage:int}';
congrats.config.params = {};
congrats.config.params.info = null;
congrats.config.params.sounds = null;
congrats.config.params.map = null;
congrats.config.params.plumage = null;

// configure three multiple views for the question, header, and collected data
congrats.config.views = {};

// set the view for the edit menu
congrats.config.views['edit@app.identify'] = {};
congrats.config.views['edit@app.identify'].template = '';

// configure the view for the congrats question
congrats.config.views['@app.identify'] = {};
congrats
  .config
  .views['@app.identify']
  .templateUrl = './app/modules/components/identify/inspect/congrats/congrats.view.html';
congrats.config.views['@app.identify'].controller = 'InspectController as inspect';

// configure the header title during the congrats question
congrats.config.views['heading@app.identify'] = {};
congrats
  .config
  .views['heading@app.identify']
  .templateUrl = './app/modules/components/identify/inspect/congrats/congratsHeading.view.html';                                                   
congrats.config.views['heading@app.identify'].controller = 'CongratsHeadingController as heading';

// configure the view for displaying the collected observation data
congrats.config.views['subheading@app.identify'] = {};
congrats
  .config
  .views['subheading@app.identify']
  .templateUrl = './app/modules/components/identify/inspect/congrats/congratsSubheading.view.html';                                                   
congrats.config.views['subheading@app.identify'].controller = 'CongratsSubheadingController as subheading';
module.exports = congratsRun;

function congratsRun(routerHelper, CONGRATS_STATE) {
  // set the congrats question as a child of the identify state
  congrats.state = CONGRATS_STATE;
  states.push(congrats);
  routerHelper.configureStates(states);
}

