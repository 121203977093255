'use strict';

module.exports = PlaceController;

/**
 * @class PlaceController
 * @description bind the location of an observed bird to the main view
 * @memberOf Controllers
 */
function PlaceController($scope, $state, assistantService, PLACE_STATE) {
  var vm;
  var next;

  vm = this;

  // check if the browser supports Google geolocation
  vm.hasGeo = !!window.navigator.geolocation;
  vm.useCurrentLocation = useCurrentLocation;
  vm.prompt = assistantService.step(PLACE_STATE).prompt;
  vm.submitPlace = submitPlace;
  vm.locBlocked = false;
  vm.waitingForLatLng = false;
  next = assistantService.step(PLACE_STATE).next.value;

  function useCurrentLocation() {
    var geoOptions;

    geoOptions = {};

    // set the timeout (in AMS) for geolocation in case a result is never
    // returned
    geoOptions.timeout = 60 * 1000;
    geoOptions.maximumAge = 3600 * 1000;
    geoOptions.enableHighAccuracy = true;

    // use previous geolocation results if they are relatively recent
    geoOptions.maximumAge = 5 * 60 * 1000;

    // get the lat and lng
    vm.waitingForLatLng = true;
    window.navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions);

    // store the current state of the browser permission for location
    function geoError(error) {
      vm.locBlocked = true;
      vm.waitingForLatLng = false;
      vm.geoErrorMessage = error.code + ' ' +  error.message;
      $scope.$apply();
    }

    // store the lat and lng if the browser location is permitted and store
    // the address for the current browser location
    function geoSuccess(position) {
      var geocoder = new google.maps.Geocoder();    // jshint ignore:line
      var latlng = {};

      vm.waitingForLatLng = false;
      latlng.lat = position.coords.latitude;
      latlng.lng = position.coords.longitude;

      // store the lat/lng in memory
      assistantService.step(PLACE_STATE).params.lat = latlng.lat;
      assistantService.step(PLACE_STATE).params.lng = latlng.lng;

      // get the address
      geocoder.geocode({'location': latlng}, reverseLocate);
    }

  }

  function reverseLocate(addresses, status) {
    var locationName;

    // collect the appropriate short name for the location
    locationName =  addresses.reduce(findNames, {});

    // list the state if in the US; otherwise, show the country
    assistantService.step(PLACE_STATE).display =
      locationName.country === 'United States' ?
      locationName.city + ', ' + locationName.state :
      locationName.city + ', ' + locationName.country;

    // if there is a problem with the service then don't go to the next question
    // and store in memory that the current location is blocked
    if (status === 'OK') {
      $state.go(next);
    } else {
      vm.locBlocked = true;
      $scope.$apply();
    }

    function findNames(memo, place) {
      if (place.address_components) place.address_components.forEach(collectNames);

      return memo;

      function collectNames(component) {
        if (component.types.includes('locality') && !memo.city) memo.city = component.long_name;
        if (component.types.includes('administrative_area_level_1') && !memo.state) memo.state = component.long_name;
        if (component.types.includes('country') && !memo.country) memo.country = component.long_name;  
      }

    }

  }

  function submitPlace(locationData) {
    assistantService.step(PLACE_STATE).params.lat = locationData.latitude;
    assistantService.step(PLACE_STATE).params.lng = locationData.longitude;
    assistantService.step(PLACE_STATE).display = locationData.name;
    if (locationData.name === 'undefined, undefined') {
      vm.locBlocked = true;
      $scope.$apply();
    } else {
      $state.go(next);
    }

  }

}

