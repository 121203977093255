// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::whenSubheading.controller][whenSubheading.controller]]
'use strict';
var angular;
var WhenSubheadingController;
var controllerDepends;

angular = require('angular');
WhenSubheadingController = require('./WhenSubheadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('PLACE_STATE');
controllerDepends.push('WHEN_STATE');
angular
  .module('app.identify.when')
  .controller('WhenSubheadingController', WhenSubheadingController);

WhenSubheadingController.$inject = controllerDepends;
// whenSubheading.controller ends here
