// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::words.filter][words.filter]]
'use strict';

module.exports = function() {
  return truncateWords;

  function truncateWords(input, words, postfix) {
      if (isNaN(words)) return input;
      if (words <= 0) return '';
      if (typeof postfix === 'undefined') postfix = 'â¦';
      if (input) {
          var inputWords = input.split(/\s+/);
          if (inputWords.length > words) {
              input = inputWords.slice(0, words).join(' ') + postfix;
          }
      }
      return input;
  }
};
// words.filter ends here
