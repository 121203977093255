// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::inspectHeading.controller][inspectHeading.controller]]
'use strict';
var angular;
var InspectHeadingController;
var controllerDepends;

angular = require('angular');
InspectHeadingController = require('./InspectHeadingController');
controllerDepends = [];
controllerDepends.push('BEHAVIOR_STATE');
controllerDepends.push('INSPECT_STATE');
angular
  .module('app.identify.inspect')
  .controller('InspectHeadingController', InspectHeadingController);

InspectHeadingController.$inject = controllerDepends;
// inspectHeading.controller ends here
