// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::signup.module][signup.module]]
'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push('ui.router');
modDepends.push('blocks.routerHelper');
//modDepends.push('blocks.slidingDrawer');
modDepends.push('app.dashboard');
/**
 * @module app/dashboard/signup
 * @description enter the signup of an observed bird
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/dashboard/birdParams}
 * @see module:app
 * @see module:dashboard
 */
angular
  .module('app.dashboard.signup', modDepends);
// signup.module ends here
