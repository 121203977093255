'use strict';
var angular;
var modDepends;

angular = require('angular');
modDepends = [];
modDepends.push('ui.router');
modDepends.push('blocks.assistant');
modDepends.push('blocks.routerHelper');
modDepends.push('app.identify.flight');
modDepends.push('app.dashboard.signup');
// modDepends.push('ngAudio');
/**
 * @module app/identify/congrats
 * @description enter the location of a bird observation
 * @requires {@link module:angular-ui-router}
 * @requires {@link module:app/identify/birdParams}
 * @see module:app
 * @see module:identify
 */
angular
  .module('app.identify.inspect.congrats', modDepends);

