'use strict';

module.exports = headingDirective;

function headingDirective() {
  var directive;

  directive = {};
  directive.restrict = 'E';
  directive.controller = 'IdentifyController';
  directive.controllerAs = 'identify';
  directive.transclude = true;
  directive.scope = {};
  directive.scope.backref = '@';
  directive.templateUrl = 'app/modules/components/identify/heading.directive.html';

  return directive;
}

