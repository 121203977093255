// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::match.service][match.service]]
'use strict';

var angular = require('angular');
var matchService = require('./matchService');
var serviceDepends = [];

serviceDepends.push('$resource');
angular
  .module('app.identify.match')
  .service('matchService', matchService);

matchService.$inject = serviceDepends;
// match.service ends here
