// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::inventory.service][inventory.service]]
'use strict';

var angular = require('angular');
var inventoryService = require('./inventoryService');

angular
  .module('app.identify.inventory')
  .service('inventoryService', inventoryService);
// inventory.service ends here
