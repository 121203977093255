'use strict';
var states;
var sorry;

states = [];
sorry = {};
sorry.config = {};
sorry.config.url = '^/sorry';

// configure three multiple views for the question, header, and collected data
sorry.config.views = {};

// configure the view for the sorry question
sorry.config.views['@app.dashboard'] = {};
sorry.config.views['@app.dashboard'].templateUrl = './app/modules/components/dashboard/' +
                                      'sorry/sorry.view.html';
sorry.config.views['@app.dashboard'].controller = 'SorryController as sorry';

// configure the header title during the sorry question
sorry.config.views['heading@app.dashboard'] = {};
sorry.config.views['heading@app.dashboard'].templateUrl = './app/modules/components/dashboard/' +
                                                  'sorry/sorryHeading.view.html';
sorry.config.views['heading@app.dashboard'].controller = 'SorryHeadingController as heading';
module.exports = sorryRun;

function sorryRun(routerHelper, SORRY_STATE) {
  // set the size question as a child of the dashboard state
  sorry.state = SORRY_STATE;
  states.push(sorry);
  routerHelper.configureStates(states);
}
