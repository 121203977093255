// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::homeHeading.controller][homeHeading.controller]]
'use strict';
var angular;
var HomeHeadingController;
var controllerDepends;

angular = require('angular');
HomeHeadingController = require('./HomeHeadingController');
controllerDepends = [];
controllerDepends.push('HOME_STATE');
angular
  .module('app.dashboard.home')
  .controller('HomeHeadingController', HomeHeadingController);

HomeHeadingController.$inject = controllerDepends;
// homeHeading.controller ends here
