'use strict';

/**
 * @function when-assistantServiceDecorator
 * @description add 'when' state data for use in the steps display and navigator
 * @memberOf Decorators
 */
module.exports = assistantServiceDecorator;

function assistantServiceDecorator($delegate, WHEN_STATE) {
  var properties;

  properties = {};
  properties.params = {};
  properties.params.date = '';
  properties.prompt = 'When did you see the bird?';
  properties.restoreDefault = restore;
  $delegate.step(WHEN_STATE, properties);

  return $delegate;

  function restore() {
    /* jshint ignore:start */        
    this.params.date = '';
    this.display = '';
    /* jshint ignore:end */
      
  }

}
