// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::routerHelper.provider][routerHelper.provider]]
'use strict';
var angular;
var routerHelperProvider;
var providerDepends;

angular = require('angular');
routerHelperProvider = require('./routerHelper');
providerDepends = [];
providerDepends.push('$locationProvider');
providerDepends.push('$stateProvider');
providerDepends.push('$urlRouterProvider');
angular
  .module('blocks.routerHelper')
  .provider('routerHelper', routerHelperProvider);

routerHelperProvider.$inject = providerDepends;
// routerHelper.provider ends here
