'use strict';

var angular;
var zfTransitionFactory;
var zfTransitionFactoryDepends;

angular = require('angular');
zfTransitionFactory = require('./zfTransitionFactory');
zfTransitionFactoryDepends = [];
zfTransitionFactoryDepends.push('$q');
zfTransitionFactoryDepends.push('$timeout');
zfTransitionFactoryDepends.push('$rootScope');
angular
  .module('blocks.zf.transition')
  .factory('$transition', zfTransitionFactory);

zfTransitionFactory.$inject = zfTransitionFactoryDepends;
