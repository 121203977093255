// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::behaviorRun][behaviorRun]]
'use strict';
var states;
var behavior;
var config;

states = [];
behavior = {};
config = {};
config.url = '^/behavior';
// configure three multiple views for the question, header, and collected data
config.views = {};
// configure the view for the behavior question
config.views['@app.identify'] = {};
config.views['@app.identify'].templateUrl = './app/modules/components/identify/' +
                                      'behavior/behavior.view.html';
config.views['@app.identify'].controller = 'BehaviorController as behavior';
// configure the header title during the behavior question
config.views['heading@app.identify'] = {};
config.views['heading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                  'behavior/behaviorHeading.view.html';
config.views['heading@app.identify'].controller = 'BehaviorHeadingController as heading';
// configure the view for displaying the collected observation data
config.views['subheading@app.identify'] = {};
config.views['subheading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                       'behavior/behaviorSubheading.view.html';
config.views['subheading@app.identify'].controller = 'BehaviorSubheadingController as subheading';
behavior.config = config;
module.exports = behaviorRun;

function behaviorRun(routerHelper, BEHAVIOR_STATE) {
  // set the size question as a child of the identify state
  behavior.state = BEHAVIOR_STATE;
  states.push(behavior);
  routerHelper.configureStates(states);
}
// behaviorRun ends here
