'use strict';

var angular;
var matchMediaFactory;
var mediaQueriesFactory;
var matchMediaFactoryDepends;
var mediaQueriesFactoryDepends;

angular = require('angular');
matchMediaFactory = require('./matchMediaFactory');
matchMediaFactoryDepends = [];
matchMediaFactoryDepends.push('$document');
matchMediaFactoryDepends.push('$window');
mediaQueriesFactory = require('./mediaQueriesFactory');
mediaQueriesFactoryDepends = [];
mediaQueriesFactoryDepends.push('$document');
mediaQueriesFactoryDepends.push('matchMedia');

angular
  .module('blocks.zf.mediaQueries')
  .factory('matchMedia', matchMediaFactory)
  .factory('mediaQueries', mediaQueriesFactory);

matchMediaFactory.$inject = matchMediaFactoryDepends;
mediaQueriesFactory.$inject = mediaQueriesFactoryDepends;
