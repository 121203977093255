// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::colors.run][colors.run]]
'use strict';
var angular;
var colorsRun;
var runDepends;

angular = require('angular');
colorsRun = require('./colorsRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('COLORS_STATE');
angular
  .module('app.identify.colors')
  .run(colorsRun);

colorsRun.$inject = runDepends;
// colors.run ends here
