// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::sliderMenu.directive.js][sliderMenu.directive.js]]
'use strict';

module.exports = function () {
  var directive = {
      restrict: "CEA",
      transclude: true,
      scope: {
        visible: "=",
        alignment: "@"
      },
      templateUrl: 'app/modules/shared/sliderMenu/sliderMenu.directive.html',
  };

  return directive;
};
// sliderMenu.directive.js ends here
