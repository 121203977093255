// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::home.index][home.index]]
require('../../../shared/blocks/routerHelper'); // helper functions for configuring the ui-router state
//require('../../../shared/blocks/slidingDrawer');
require('../../identify');
require('../../identify/place');
require('../../dashboard');

require('./home.module');
require('./home.constant');
require('./home.run');
//require('./home.decorator');
require('./home.controller');
require('./homeHeading.controller');
// home.index ends here
