// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::congratsHeading.controller][congratsHeading.controller]]
'use strict';
var angular;
var CongratsHeadingController;
var controllerDepends;

angular = require('angular');
CongratsHeadingController = require('./CongratsHeadingController');
controllerDepends = [];
controllerDepends.push('assistantService');
controllerDepends.push('$state');
controllerDepends.push('$stateParams');
angular
  .module('app.identify.inspect.congrats')
  .controller('CongratsHeadingController', CongratsHeadingController);

CongratsHeadingController.$inject = controllerDepends;
// congratsHeading.controller ends here
