'use strict';

/**
 * @class BehaviorController
 * @description enter the observed birds behavior
 * @memberOf Controllers
 */
module.exports = BehaviorController;

function BehaviorController(appServerService,
                            sessionService,
                            assistantService,
                            BEHAVIOR_STATE,
                            INSPECT_STATE,
                            SPINNER_STATE,
                            SIGNUP_STATE,
                            SORRY_STATE,
                            $state) {
  var vm;
  var next;

  vm = this;
  vm.selectedBehavior = assistantService.step(BEHAVIOR_STATE).params.behavior || '';
  vm.prompt = assistantService.step(BEHAVIOR_STATE).prompt;
  vm.controls = assistantService.step(BEHAVIOR_STATE).controls;
  vm.submitBehavior = submitBehavior;
  next = '#';

  function submitBehavior() {
    var flightParams;
    var landingStates;
    var hubEvent;
    var tracking;
    var missingParams;

    if (vm.selectedBehavior) {

      // record with Hubspot that the user requested birds
      hubEvent = {};
      hubEvent.id = 'Merlin-web Request for Merlin Birds';
      tracking = ['trackEvent', hubEvent];
      try {
        _hsq.push(tracking); // jshint ignore:line
      } catch(error) {
        if(error.message === '_hsq is not defined') console.info('Hubspot tracking code is currently blocked');
      }

      // add the selected behavior to the assistant params
      assistantService
        .step(BEHAVIOR_STATE).params.behavior = vm.selectedBehavior;

      // add the human-readable description of the selected behavior to the
      // assistant
      vm.controls.forEach(writeSelectedDisplay);

      // the total params collection is a combination of params from the
      // assistant and the 'mybird' param with value of empty string
      flightParams = JSON.parse(JSON.stringify(assistantService.params()));
      //flightParams.mybird = '';

      // Go back to any questions that are missing input values.
      // if (assistantService.steps.nodeWith(PLACE_STATE).params.lat === 0 &&
      //     assistantService.steps.nodeWith(PLACE_STATE).params.lng === 0)
      //   $state.go(PLACE_STATE);
      // if (assistantService.steps.nodeWith(WHEN_STATE).params.date === '')
      //   $state.go(WHEN_STATE);
      // if (assistantService.steps.nodeWith(SIZE_STATE).params.size === '')
      //   $state.go(SIZE_STATE);
      // if (assistantService.steps.nodeWith(COLORS_STATE).params.colors === '')
      //   $state.go(COLORS_STATE);
      // if (assistantService.steps.nodeWith(BEHAVIOR_STATE).params.behavior === '')
      //   $state.go(BEHAVIOR_STATE);

      assistantService.steps.traverse(checkStep);
      if (!missingParams) requestMerlinMatches();

      function checkStep(step) {
        // go to the step if the value has not been set
        if (Object.keys(step.params).reduce(paramNotSet, false)) {
          missingParams = true;
          $state.go(step.value);
          return false;
        } else {
          return true;
        }

        function paramNotSet(memo, param, index, params) {
          // check if both lat and lng are unset
          if (index === 1) {
            return step.params[params[0]] === 0 && step.params[param] === 0;
          } else {

            // otherwise check if param is unset
            return step.params[param] === '';
          }

        }

      }

      function requestMerlinMatches() {
        // define which states to navigate to while waiting on Merlin, after a
        // successful request, and after an exception
        landingStates = {};
        landingStates.waiting = {};
        landingStates.waiting.state = SPINNER_STATE;
        landingStates.success = {};
        landingStates.success.state = INSPECT_STATE;
        landingStates.problem = {};
        landingStates.problem.signup = {};
        landingStates.problem.signup.state = SIGNUP_STATE;
        landingStates.problem.expired = {};
        landingStates.problem.expired.state = SIGNUP_STATE;
        landingStates.problem.internal = {};
        landingStates.problem.internal.state = SORRY_STATE;

        // request the flight of birds from Merlin
        appServerService.getMerlinFlight(flightParams,
                                         sessionService.storage,
                                         landingStates,
                                         assistantService.flight);
      }

    }

    function writeSelectedDisplay(control) {
      if (vm.selectedBehavior === control.value)
        assistantService.step(BEHAVIOR_STATE).display = control.label;

      return;
    }

  }

}
