'use strict';
var angular;
var congratsRun;
var runDepends;

angular = require('angular');
congratsRun = require('./congratsRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('CONGRATS_STATE');
angular
  .module('app.identify.inspect.congrats')
  .run(congratsRun);

congratsRun.$inject = runDepends;

