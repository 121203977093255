// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::expired.controller][expired.controller]]
'use strict';
var angular;
var ExpiredController;
var controllerDepends;

angular = require('angular');
ExpiredController = require('./ExpiredController');
controllerDepends = [];
controllerDepends.push('HOME_STATE');
angular
  .module('app.dashboard.expired')
  .controller('ExpiredController', ExpiredController);

ExpiredController.$inject = controllerDepends;
// expired.controller ends here
