// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::modal.module][modal.module]]
'use strict';

var angular = require('angular');
var modDepends = [];

/**
 * @module app/blocks/modal
 * @description API for stepping through bird identification
 * @requires {@link module:app/blocks/dataStructures}
 * @see module:app
 * @see module:blocks
 */
angular
  .module('blocks.modal', modDepends);
// modal.module ends here
