'use strict';

/**
 * @class DashboardController
 * @description provides access to the highest level navigation in the app
 * @param $resource - angular service for interacting via RESTful API
 * @memberOf Controllers
 */
module.exports = DashboardController;

function DashboardController() {
  var vm;

  vm = this;   // jshint ignore:line
  //vm.menu = slidingDrawerService.menu;
  vm.hasAuth = false;
  // vm.hasAuth = sessionService.storage.status.email.isConfirmed ||
  //              ( !!sessionService.storage.status.email.grace &&
  //                sessionService.storage.status.email.grace.isActive ) ||
  //              sessionService.storage.status.trial.isActive;
}
