// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::bestMatch.module][bestMatch.module]]
'use strict';

var angular = require('angular');
var modDepends = [];

modDepends.push('app.identify.bestMatch.detail');
modDepends.push('ui.router');
modDepends.push('blocks.routerHelper');
/**
 * @module app/identify/bestMatch
 * @description display possible matches for the observed bird
 * @requires {@link module:angular-ui-router}
 * @see module:app
 * @see module:identify
 */
angular
  .module('app.identify.bestMatch', modDepends);
// bestMatch.module ends here
