'use strict';

module.exports = lazyImgDirective;

function lazyImgDirective($window, $document, $timeout) {
  var directive;
  var handlers;
  var alreadyLoaded;

  // register the handlers for lazy-loading
  handlers = [];
  angular.element(window).on('DOMContentLoaded load resize scroll drag', lazyListener);

  // configure the directive
  directive = {};
  directive.restrict = 'A';
  directive.link = lazyLink;

  return directive;

  function triggerInitialLoad() {
    $timeout(triggerLoad, 0, false);

    function triggerLoad() {
      angular.element(window).triggerHandler('load');
    }

  }

  function isInViewport(elem) {
    var boundingClient;

    boundingClient = elem.getBoundingClientRect();
    return boundingClient.top >= 0 &&
           boundingClient.left >= 0 &&
           boundingClient.bottom <= ($window.innerHeight || $document.documentElement.clientHeight); // &&
           // boundingClient.right <= 1.33 * ($window.innerWidth || $document.documentElement.clientWidth);
  }

  function lazyListener() {
    angular.forEach(handlers, runLazy);

    function runLazy(item) {
      item[1](isInViewport(item[0]));
    }

  }

  function register(element, handler) {
    handlers.push([element, handler]);
  }

  function unregister(element) {
    handlers = handlers.filter(filterHandlers);

    function filterHandlers(item) {
      return item[0] !== element;
    }

  }

  function lazyLink(scope, iElem, attrs) {
    var lazyAttrs;

    // parse the lazy attributes
    lazyAttrs = attrs.lazyLoad.split(',').reduce(removeWhiteSpace, []);

    // register the handlers
    register(iElem[0], updateContent);

    // load content for elements in viewport after all directives linked
    $timeout(loadAfterViewLoaded, 10);

    // unregister handlers when element is removed
    scope.$on('$destroy', removeHandlers);

    function loadAfterViewLoaded() {
      // only trigger lazy-loading for elements in viewport after load
      if (isInViewport(iElem[0]))
        scope.$watch('$viewContentLoaded', triggerInitialLoad);
    }

    function removeWhiteSpace(memo, item) {
      memo.push(item.replace(/^[ ]+|[ ]+$/g,''));
      return memo;
    }

    function removeHandlers() {
      unregister(iElem[0]);
    }

    function updateContent(isVisible){
      scope.$apply(lazyLoad);

      function lazyLoad() {
        if (isVisible) {
          lazyAttrs.forEach(swapLazyAttrs);
          iElem[0].removeAttribute('lazy-load');
        }

        function swapLazyAttrs(item) {
          var attNames;

          // parse the lazy attribute to be replaced and the substitute attribute  
          attNames = item.split(' ');

          // set the new attribute to the value of the lazy attribute
          iElem[0].setAttribute(attNames[1],
                                attrs[attNames[0]
                                      .replace(/-([a-z])/g, makeUppercase)]);

          // remove the lazy attribute
          iElem[0].removeAttribute(attNames[0]);

          function makeUppercase(u) {
            return u[1].toUpperCase();
          }

        }

      }

    }

  }

}

