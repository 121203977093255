'use strict';
var angular;

angular = require('angular');

 module.exports = locationPredictions;

function locationPredictions() {
  var directive;
  var map;

  map = {};
  map.results = '=';
  directive = {};
  directive.restrict = 'E';
  directive.scope = map;
  directive.templateUrl = 'app/modules/components/identify/place/locator/locationPredictions.html';
  directive.link = linkPredictions;

  return directive;

  function linkPredictions(scope, iElement) {
    var googleMapsService;
    var el;

    // Setup Google Auto-complete Service
    googleMapsService = new google.maps.places.AutocompleteService();   // jshint ignore:line
    el = angular.element(iElement.find('input'));

    // focus on the input box
    el.focus();  
      
    // Update the search results array when input value changes.
    el.on('input', update);

    function update() {
      var query;

      query = el.val();
      //Do not search until at least 3 characters are entered.
      if (query && query.length >= 3) findPredictions(query);
        else scope.$apply(noResponse);

      function noResponse() {
        scope.results = [];
      }

    }

    //Find Google Maps predictions for search term.
    function findPredictions(query) {
      var input;

      input = {};
      input.input = query;
      googleMapsService.getPlacePredictions(input, fetchCallback);
    }

    //Store the predictions in the results array.
     function fetchCallback(predictions, status) {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {    // jshint ignore:line
        scope.$apply(errPredictions);
        return;
      } else {
        scope.$apply(resPredictions);
      }

      function errPredictions() {
          scope.results = [];
      }

      function resPredictions() {
          scope.results = predictions;
      }

    }

  }

}

