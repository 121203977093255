// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::placeRun][placeRun]]
'use strict';
var states;
var place;
var config;

states = [];
place = {};
config = {};
config.url = '^/place';
// configure three multiple views for the question, header, and collected data
config.views = {};
// configure the view for the place question
config.views['@app.identify'] = {};
config.views['@app.identify'].templateUrl = './app/modules/components/identify/' +
                                      'place/place.view.html';
config.views['@app.identify'].controller = 'PlaceController as place';
// configure the header title during the place question
config.views['heading@app.identify'] = {};
config.views['heading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                  'place/placeHeading.view.html';
config.views['heading@app.identify'].controller = 'PlaceHeadingController as heading';
// configure the view for displaying the collected observation data
config.views['subheading@app.identify'] = {};
config.views['subheading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                       'place/placeSubheading.view.html';
config.views['subheading@app.identify'].controller = 'PlaceSubheadingController as subheading';
place.config = config;
module.exports = placeRun;

function placeRun(routerHelper, PLACE_STATE) {
  // set the place question as a child of the identify state
  place.state = PLACE_STATE;
  states.push(place);
  routerHelper.configureStates(states);
}
// placeRun ends here
