// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::DetailsHeadingController][DetailsHeadingController]]
'use strict';

/**
 * @class DetailsHeadingController
 * @description bind the details question title to a separate view
 * @memberOf Controllers
 */
module.exports = DetailsHeadingController;

function DetailsHeadingController(DETAILS_STATE, $stateParams, assistantService) {
  var vm;

  vm = this;
  vm.state = DETAILS_STATE;
  vm.prior = '^';
  vm.plumage = $stateParams.plumage;
  vm.commonName = assistantService.result[$stateParams.plumage].commonName;
}
// DetailsHeadingController ends here
