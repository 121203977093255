// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::modalDirective.js][modalDirective.js]]
'use strict';

// NOTE: The file modal.directive.html must have the comments inserted by org-mode
// removed otherwise angular will interpret them as another root element
// and throw an exception
module.exports = directive;

function directive() {
  var directive;

  directive = {};
  directive.restrict = 'E';
  directive.scope = {};
  directive.scope.show = '=';
  directive.replace = true;
  directive.transclude = true;
  directive.link = modalLink;
  directive.templateUrl = 'app/modules/shared/blocks/modal/modal.directive.html';

  return directive;

  function modalLink(scope, element, attrs) {
    scope.dialogStyle = {};
    if (attrs.width) scope.dialogStyle.width = attrs.width;
    if (attrs.height) scope.dialogStyle.height = attrs.height;
    scope.hideModal = hideModal;

    function hideModal() {
      scope.show = false;
    }

  }

}
// modalDirective.js ends here
