// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::sizeRun][sizeRun]]
'use strict';
var states;
var size;
var config;

states = [];
size = {};
config = {};
config.url = '^/size';
// configure three multiple views for the question, header, and collected data
config.views = {};
// configure the view for the size question
config.views['@app.identify'] = {};
config.views['@app.identify'].templateUrl = './app/modules/components/identify/' +
                                      'size/size.view.html';
config.views['@app.identify'].controller = 'SizeController as size';
// configure the header title during the size question
config.views['heading@app.identify'] = {};
config.views['heading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                  'size/sizeHeading.view.html';
config.views['heading@app.identify'].controller = 'SizeHeadingController as heading';
// configure the view for displaying the collected observation data
config.views['subheading@app.identify'] = {};
config.views['subheading@app.identify'].templateUrl = './app/modules/components/identify/' +
                                                       'size/sizeSubheading.view.html';
config.views['subheading@app.identify'].controller = 'SizeSubheadingController as subheading';
size.config = config;
module.exports = sizeRun;

function sizeRun(routerHelper, SIZE_STATE) {
  // set the size question as a child of the identify state
  size.state = SIZE_STATE;
  states.push(size);
  routerHelper.configureStates(states);
}
// sizeRun ends here
