// [[file:~/Documents/GTD/PROJECTS/merlin-web-app/doc.org::size.run][size.run]]
'use strict';
var angular;
var sizeRun;
var runDepends;

angular = require('angular');
sizeRun = require('./sizeRun');
runDepends = [];
runDepends.push('routerHelper');
runDepends.push('SIZE_STATE');
angular
  .module('app.identify.size')
  .run(sizeRun);

sizeRun.$inject = runDepends;
// size.run ends here
